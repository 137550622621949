const unescapeQuery = (query: {[key: string]: any}) => {
  const unescaped: {[key: string]: any} = {};
  Object.keys(query).forEach(k => {
    if (typeof query[k] === "string") {
      unescaped[k] = unescape(query[k]);
    }
    else {
      unescaped[k] = query[k]
    }
  });
  return unescaped;
}



export default unescapeQuery;