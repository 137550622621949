import { CompanyMember } from "../../models/CompanyMember";

export const MEMBERS_FETCHED = "MEMBERS_FETCHED";
export const MEMBER_UPDATED = "MEMBER_UPDATED";
export const MEMBER_DELETED = "MEMBER_DELETED";


export type CompanyMembers = {[id: string]: CompanyMember};


export type MembersFetchAction = {
  type: typeof MEMBERS_FETCHED,
  payload: CompanyMember[]
}


export type MemberUpdateAction = {
  type: typeof MEMBER_UPDATED,
  payload: CompanyMember
}


export type MemberDeleteAction = {
  type: typeof MEMBER_DELETED,
  payload: string
}


export type MemberListState = {
  members: CompanyMembers | undefined,
}


export type MemberListAction = (
  MembersFetchAction
  | MemberUpdateAction
  | MemberDeleteAction
)