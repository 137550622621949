import { Client } from "../../models";

export const CLIENTS_FETCHED = "CLIENTS_FETCHED";
export const CLIENT_CREATED = "CLIENT_CREATED";
export const CLIENT_EDITED = "CLIENT_EDITED";
export const CLIENT_DELETED = "CLIENT_DELETED";


export type Clients = {[key: string]: Client};


export type ClientsFetchAction = {
  type: typeof CLIENTS_FETCHED,
  payload: {clients: Client[], count: number}
}


export type ClientCreateAction = {
  type: typeof CLIENT_CREATED,
  payload: Client
}


export type ClientUpdateAction = {
  type: typeof CLIENT_EDITED,
  payload: Client
}


export type ClientDeleteAction = {
  type: typeof CLIENT_DELETED,
  payload: string // id is enough
}


export type ClientListState = {
  clients: Clients | undefined,
  count: number | undefined,
}


export type ClientsAction = (
  ClientsFetchAction
  | ClientCreateAction
  | ClientUpdateAction
  | ClientDeleteAction)
