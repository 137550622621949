import {
  CLIENT_CREATED, CLIENT_DELETED, CLIENT_EDITED, ClientListState, Clients, CLIENTS_FETCHED, ClientsAction
} from "./types";
import { Client } from "../../models";


export const clientListReducer = (state: ClientListState = {clients: undefined, count: undefined}, action: ClientsAction) => {

  let clients: Clients = state.clients ? {...state.clients} : {};

  switch (action.type) {
    case CLIENTS_FETCHED:
      clients = {};
      action.payload.clients.forEach((client: Client) => {
        clients[client.id] = client;
      });
      return {
        ...state,
        clients: clients,
        count: action.payload.count
      };

    case CLIENT_CREATED:
      clients[action.payload.id] = action.payload;
      return {
        ...state,
        clients: {...clients}
      };

    case CLIENT_EDITED:
      clients[action.payload.id] = action.payload;
      return {
        ...state,
        clients: {...clients}
      };

    case CLIENT_DELETED:
      delete clients[action.payload];
      return {
        ...state,
        clients: {...clients}
      };

    default:
      return state;

  }
};