import { createMuiTheme } from "@material-ui/core";


export const ipcrmTheme = createMuiTheme({
  palette: {
    primary: {
      light: "#4791db",
      main: "#1976d2",
      dark: "#115293",
    },
  },
  typography: {
    fontFamily: "Nunito, sans-serif",
    fontSize: 12
  },
  overrides: {
    MuiButton: {
      root: {
        textTransform: "none",
        boxShadow: "none",
      },
      contained: {
        boxShadow: "none",
        "&:hover": {
          boxShadow: "none",
        },
        "&:active": {
          boxShadow: "none",
        },
        "&:disabled": {
          boxShadow: "none",
        },
      },
    },
  }
})