import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import React from "react";
import {
  Avatar, Dialog, DialogContent, DialogTitle, IconButton,
  List,
  ListItem,
  ListItemAvatar, ListItemSecondaryAction,
  ListItemText,
  Table, TableBody, TableRow, Tooltip,
} from "@material-ui/core";
import { Todo } from "../../models";
import Pagination from "../Filters/Pagination";
import moment from "moment";
import DoneIcon from "@material-ui/icons/DoneOutlined";
import UndoIcon from "@material-ui/icons/UndoOutlined";
import DeleteIcon from "@material-ui/icons/DeleteOutlined";
import EditIcon from "@material-ui/icons/EditOutlined";
import TodoDetail from "./TodoDetail";
import TodoForm from "./TodoForm";
import { AppState } from "../../store";
import { ThunkDispatch } from "redux-thunk";
import { deleteTodo, updateTodo } from "../../store/Todos/actions";
import { connect } from "react-redux";
import { TodoSerivce } from "../../store/Todos/services";
import { Session } from "../../store/Auth/types";


type TodoListProps = {
  todos: Todo[],
  count: number,
  session: Session | undefined,
  updateTodo: (payload: Todo) => void,
  deleteTodo: (payload: string) => void,
  onSelect?: (todo: Todo) => void
}


const useStyles = makeStyles(theme => ({
  root: {},
  listItem: {
    "&:hover": {
      backgroundColor: "#fafafa",
    }
  },
  selected: {
    backgroundColor: "#f2f2f2",
  },
  listItemText: {
    display: "block",
    textOverflow: "ellipsis",
    overflow: "hidden",
    whitespace: "nowrap"
  },
  action: {
    marginLeft: theme.spacing(1)
  },
  new: {
    backgroundColor: theme.palette.primary.dark
  },
  inProgress: {
    backgroundColor: theme.palette.primary.light
  },
  finished: {
    backgroundColor: theme.palette.success.main
  },
  delayed: {
    backgroundColor: theme.palette.warning.main
  }
}));


const TodoList: React.FC<TodoListProps> = (props: TodoListProps) => {

  const classes = useStyles();
  const service = props.session ? new TodoSerivce(props.session.company.id) : undefined;
  const [selected, setSelected] = React.useState<Todo | undefined>(props.todos[0]);
  const [editing, setEditing] = React.useState<Todo | undefined>(undefined);

  const setAsFinished = (id: string) => {
    if (service) {
      service.update(id, {status: "finished"}).then(response => {
        const todo = new Todo(response);
        props.updateTodo(todo);
        setSelected(todo);
      })
    }
  };

  const setAsNotFinished = (id: string) => {
    if (service) {
      service.update(id, {status: "new"}).then(response => {
        const todo = new Todo(response);
        props.updateTodo(todo);
        setSelected(todo);
      })
    }
  };

  const handleSelect = (selected: Todo) => {
    setSelected(selected);
    if (props.onSelect) {
      props.onSelect(selected);
    }
  };

  const removeTodo = (id: string) => {
    if (service) {
      service.delete(id).then(() => {
        props.deleteTodo(id);
      })
    }
  };

  const editDialog = (): React.ReactNode => {
    return (
      <Dialog open={editing !== undefined} onClose={() => setEditing(undefined)} disableBackdropClick>
        <DialogTitle>Edytuj zadanie: {editing?.title}</DialogTitle>
        <DialogContent dividers>
          <TodoForm
            instance={editing}
            onSuccess={(todo: Todo) => {
              props.updateTodo(todo);
              setEditing(undefined)
            }}
            onCancel={() => setEditing(undefined)}
          />
        </DialogContent>
      </Dialog>
    )
  };


  React.useEffect(() => {
    if (props.todos.length > 0 && selected === undefined) {
      const todo = props.todos[0];
      setSelected(todo);
      if (props.onSelect) {
        props.onSelect(todo)
      }
    }
  }, [selected, props]);

  return (
    <>
      <List className={classes.root}>
        {
          props.todos.map(todo => (
            <div key={`list-item-wrapper-${todo.id}`}>
            <ListItem
              alignItems={"flex-start"}
              key={`list-item-${todo.id}`}
              className={clsx(classes.listItem, selected?.id === todo.id && classes.selected)}
              onClick={() => handleSelect(todo)}
            >
              <ListItemAvatar>
                <Avatar
                  className={clsx(
                    todo.status === "new" && classes.new,
                    todo.status === "in_progress" && classes.inProgress,
                    todo.status === "finished" && classes.finished,
                    todo.due_date && todo.due_date < moment() && todo.status !== "finished" && classes.delayed
                  )}>
                  {
                    todo.assignee ? `${todo.assignee.user?.first_name[0]}${todo.assignee.user?.last_name[0]}` || "?": "?"
                  }
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                className={classes.listItemText}
                primary={todo.title}
                secondary={`Termin wykonania do ${todo.due_date?.format("YYYY-MM-DD")}`}
              />
              <ListItemSecondaryAction>
                <Tooltip title={"Edytuj"}>
                  <IconButton onClick={() => setEditing(todo)} className={classes.action}>
                    <EditIcon/>
                  </IconButton>
                </Tooltip>
                {
                  todo.status !== "finished" ? (
                    <Tooltip title={"Oznacz jako wykonane"}>
                      <IconButton onClick={() => setAsFinished(todo.id)} className={classes.action}>
                        <DoneIcon/>
                      </IconButton>
                    </Tooltip>
                  ) : (
                    <Tooltip title={"Oznacz jako niewykonane"}>
                      <IconButton onClick={() => setAsNotFinished(todo.id)} className={classes.action}>
                        <UndoIcon/>
                      </IconButton>
                    </Tooltip>
                  )
                }
                {
                  props.session?.company.role === "owner" || props.session?.company.role === "admin" ? (
                    <Tooltip title={"Usuń"}>
                      <IconButton onClick={() => removeTodo(todo.id)}>
                        <DeleteIcon/>
                      </IconButton>
                    </Tooltip>
                  ) : null
                }
              </ListItemSecondaryAction>
            </ListItem>
              {
                selected?.id === todo.id ? (
                  <TodoDetail todo={todo}/>
                ) : null
              }
            </div>
          ))
        }
      </List>
      <Table>
        <TableBody>
          <TableRow>
            <Pagination count={props.count} />
          </TableRow>
        </TableBody>
      </Table>
      {editDialog()}
    </>
  )
};


const mapStateToProps = (state: AppState) => ({
  session: state.auth.session,
});


const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>) => ({
  updateTodo: (payload: Todo) => dispatch(updateTodo(payload)),
  deleteTodo: (payload: string) => dispatch(deleteTodo(payload)),
});


export default connect(mapStateToProps, mapDispatchToProps)(TodoList);