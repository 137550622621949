import moment, { Moment } from "moment";
import {
  Client,
  Company,
  InsuranceAttachment,
  InsurancePremium
} from "./index";
import InsuranceCommission from "./InsuranceCommission";

export default class Insurance {
  id: string;
  company: Company;
  premiums: InsurancePremium[];
  commissions: InsuranceCommission[];
  attachments: InsuranceAttachment[];
  number: string;
  insurer: string;
  agreement_date: Moment | null;
  start_date: Moment | null;
  end_date: Moment | null;
  insurance_type: string;
  insurance_variants: string[];
  comment: string;
  client: Client;
  status: string;
  termination_date: Moment | null;
  paidCommission: boolean;
  commissionsAmount: string;

  constructor(input: {[key: string]: any}) {
    this.id = input.id;
    this.company = new Company(input.company);
    this.premiums = input.premiums.map((i: {[key: string]: any}) => {
      return new InsurancePremium(i);
    });
    this.commissions = input.commissions.map((i: {[key: string]: any}) => {
      return new InsurancePremium(i);
    });
    this.attachments = input.attachments.map((i: {[key: string]: any}) => {
      return new InsuranceAttachment(i);
    });
    this.number = input.number;
    this.insurer = input.insurer;
    this.agreement_date = input.agreement_date ? moment(input.agreement_date) : null;
    this.start_date = input.start_date ? moment(input.start_date) : null;
    this.end_date = input.end_date ? moment(input.end_date) : null;
    this.insurance_type = input.insurance_type;
    this.insurance_variants = input.insurance_variants;
    this.comment = input.comment;
    this.client = new Client(input.client);
    this.status = input.status;
    this.termination_date = input.termination_date ? moment(input.termination_date) : null;
    this.paidCommission = input.paid_commission;
    this.commissionsAmount = input.commissions_amount;
  }
}