import { CompanyMember } from "../../models/CompanyMember";
import { MEMBER_DELETED, MEMBER_UPDATED, MEMBERS_FETCHED } from "./types";


export const fetchMembers = (payload: CompanyMember[]) => {
  return {
    type: MEMBERS_FETCHED,
    payload: payload
  }
};


export const updateMember = (payload: CompanyMember) => {
  return {
    type: MEMBER_UPDATED,
    payload: payload
  }
};


export const deleteMember = (payload: string) => {
  return {
    type: MEMBER_DELETED,
    payload: payload
  }
};
