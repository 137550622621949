import React from "react";
import { Session } from "../../store/Auth/types";
import { AppState } from "../../store";
import { connect } from "react-redux";
import {
  AppBar,
  Breadcrumbs,
  Divider,
  Grid,
  Link,
  Paper,
  Tab,
  Tabs,
  Typography
} from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import {
  MemberList,
  PasswordChangeForm,
  PersonalInfoChangeForm
} from "../../components/Accounts";
import { makeStyles } from "@material-ui/core/styles";
import { TabContext, TabPanel } from "@material-ui/lab";


type AccountSettingsViewProps = {
  session: Session | undefined
}


const useStyles = makeStyles(theme => ({
  tabsBar: {
    boxShadow: "rgba(53, 64, 82, 0.05) 0px 0px 14px 0px"
  },
  tab: {
    textTransform: "none"
  }
}));


const AccountSettingsView: React.FC<AccountSettingsViewProps> = (props: AccountSettingsViewProps) => {

  const classes = useStyles();
  const [activeTab, setActiveTab] = React.useState("personal-data");

  const [tabsWidth, setTabsWidth] = React.useState<number | string>(0);
  const [role, setRole] = React.useState(props.session ? props.session.company.role : "");

  const tabsParentRef = React.createRef<HTMLDivElement>();

  const handleTabChange = (e: any, newValue: any) => {
    setActiveTab(newValue)
  };

  const tabProps = (tabId: string) => {
    return {
      id: `tab-${tabId}`,
      value: tabId,
      "aria-controls": `tabpanel-${tabId}`,
      classes: {root: classes.tab}
    }
  };

  const tabPanelProps = (tabId: string) => {
    return {
      id: `tabpanel-${tabId}`,
      value: tabId,
      "aria-labelledby": `tab-${tabId}`
    }
  };

  React.useLayoutEffect(() => {
    if (tabsParentRef.current) {
      const width = tabsParentRef.current.clientWidth;
      setTabsWidth(width)
    }
  }, [tabsParentRef]);

  React.useEffect(() => {
    setRole(props.session?.company.role || "");
  }, [props.session]);

  return (
    <>
      <div className={"header"}>
        <Grid container>
          <Grid item className={"header-content"}>
            <Typography variant={"h3"}>
              Ustawienia konta
            </Typography>
            <Breadcrumbs>
              <Link component={RouterLink} to={"/dashboard/"}>Pulpit</Link>
              <Typography color={"textSecondary"}>Ustawienia konta</Typography>
            </Breadcrumbs>
          </Grid>
        </Grid>
        <Divider />
      </div>
      <Grid container className={"content"}>
        <Grid item xs={12}>
          <Paper className={"paper-content"} ref={tabsParentRef}>
            <TabContext value={activeTab}>
              <div style={{width: tabsWidth}}>
                <AppBar position={"static"} color={"default"} className={classes.tabsBar}>
                  <Tabs
                    value={activeTab}
                    indicatorColor={"primary"}
                    textColor={"primary"}
                    onChange={handleTabChange}
                    variant={"scrollable"}
                    scrollButtons={"auto"}
                  >
                    <Tab label={"Dane personalne"} {...tabProps("personal-data")}/>
                    <Tab label={"Hasła"} {...tabProps("passwords") }/>
                    {
                      role === "owner" || role === "admin" ? (
                        <Tab label={"Pracownicy"} {...tabProps("members") }/>
                      ) : null
                    }
                  </Tabs>
                </AppBar>
                <TabPanel {...tabPanelProps("personal-data")}>
                  <PersonalInfoChangeForm />
                </TabPanel>
                <TabPanel {...tabPanelProps("passwords")}>
                  <PasswordChangeForm />
                </TabPanel>
                {
                  role === "owner" || role === "admin" ? (
                    <TabPanel {...tabPanelProps("members")}>
                      <MemberList />
                    </TabPanel>
                  ) : null
                }
              </div>
            </TabContext>
          </Paper>
        </Grid>
      </Grid>
    </>
  )
};


const mapStateToProps = (state: AppState) => ({
  session: state.auth.session
});


export default connect(mapStateToProps)(AccountSettingsView);