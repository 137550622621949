import React from "react";
import { Button, Grid, TextField } from "@material-ui/core";
import { APIClient } from "../../api/APIClient";
import { notify } from "../../utils";


const PasswordChangeForm: React.FC = () => {

  const [currentPassword, setCurrentPassword] = React.useState("");
  const [newPassword, setNewPassword] = React.useState("");
  const [passwordConfirmation, setPasswordConfirmation] = React.useState("");
  const [errors, setErrors] = React.useState<{[key: string]: any} | undefined>(undefined);

  const handleSubmit = () => {
    const payload = {
      current_password: currentPassword,
      new_password: newPassword,
      password_confirmation: passwordConfirmation
    };
    APIClient.post("/accounts/change-password/", payload).then(() => {
      notify("Pomyślnie zmieniono hasło.", "success");
      setErrors(undefined);
      setCurrentPassword("");
      setNewPassword("");
      setPasswordConfirmation("");
    }).catch(error => {
      const errors = error.response.data;
      if (errors) {
        setErrors(errors);
      }
    })
  };

  return (
    <form autoComplete={"off"}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextField
            required
            fullWidth
            variant={"outlined"}
            label={"Obecne hasło"}
            value={currentPassword}
            onChange={(e: any) => setCurrentPassword(e.target.value)}
            inputProps={{type: "password"}}
            error={errors && Boolean(errors.current_password)}
            helperText={
              errors && Boolean(errors.current_password)
                ? errors.current_password
                : "W celach bezpieczeństwa podaj obecne hasło."
            }
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            fullWidth
            variant={"outlined"}
            label={"Nowe hasło"}
            value={newPassword}
            onChange={(e: any) => setNewPassword(e.target.value)}
            inputProps={{type: "password"}}
            error={errors && Boolean(errors.new_password)}
            helperText={
              errors && Boolean(errors.new_password)
                ? errors.new_password
                : "Minimum 8 znaków"
            }
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            fullWidth
            variant={"outlined"}
            label={"Powtórz nowe hasło"}
            value={passwordConfirmation}
            onChange={(e: any) => setPasswordConfirmation(e.target.value)}
            inputProps={{type: "password"}}
            error={errors && Boolean(errors.password_confirmation)}
            helperText={
              errors && Boolean(errors.password_confirmation)
                ? errors.password_confirmation
                : "Aby zapobiec błędom powtórz jeszcze raz nowe hasło."
            }
          />
        </Grid>
        <Grid item xs={12} className={"form-actions"}>
          <Button color={"primary"} variant={"contained"} onClick={handleSubmit}>
            Zmień hasło
          </Button>
        </Grid>
      </Grid>
    </form>
  )
};


export default PasswordChangeForm;
