import * as queryString from "querystring";
import { APIClient, IParams, IPayload } from "../../api/APIClient";
import { Client } from "../../models";
import { notify } from "../../utils";


export class ClientService {

  companyId: string;

  constructor(companyId: string) {
    this.companyId = companyId;
  }

  getCSV = (params: IParams = {}) => {
    const url = `/${this.companyId}/clients/`;
    params.format = "csv";
    params.limit = 1000;
    return APIClient.get(
      url,
      params,
      undefined,
      undefined,
      {responseType: "blob"}
    ).then((response) => {
      return response;
    })
  };

  fetch = (params?: IParams) => {
    const url = `/${this.companyId}/clients/`;
    return APIClient.get(url, params).then(response => {
      const clients = response.data.results.map((item: any) => {
        return new Client(item);
      });
      return {clients: clients, count: response.data.count}
    }).catch((error) => {
      notify(`${error}`, 'error');
      throw error.response;
    })
  };

  create = (payload: IPayload) => {
    const url = `/${this.companyId}/clients/`;
    return APIClient.post(url, payload).then((response) => {
      notify(`Pomyślnie dodano nowego klienta.`, 'success');
      return response.data;
    }).catch((error) => {
      notify(`${error}`, 'error');
      throw error.response;
    })
  };

  update = (id: string, payload: IPayload) => {
    const url = `/${this.companyId}/clients/${id}/`;
    return APIClient.patch(url, payload).then((response) => {
      notify(`Pomyślnie edytowano klienta.`, 'success');
      return response.data;
    }).catch((error) => {
      notify(`${error}`, 'error');
      throw error.response;
    })
  };

  delete = (id: string) => {
    const url = `/${this.companyId}/clients/${id}/`;
    return APIClient.delete(url).then((response) => {
      notify(`Pomyślnie usunięto klienta.`, 'success');
      return response.data;
    }).catch((error) => {
      notify(`${error}`, 'error');
      throw error.response;
    })
  };

  get = (id: string) => {
    const url = `/${this.companyId}/clients/${id}/`;
    return APIClient.get(url).then((response) => {
      return response.data;
    }).catch((error) => {
      notify(`${error}`, 'error');
      throw error.response;
    })
  };

  getDocumentUrl = (path: string, fileUrl: string) => {
    const url = `/${this.companyId}/media/`;
    return APIClient.get(
      url,
      {path: path},
    ).then((response) => {
      const qs = queryString.stringify({
        md5: response.data.md5,
        expires: response.data.expires
      });
      return `${fileUrl}?${qs}`
    });
  };

  uploadDocument = (clientId: string, payload: IPayload) => {
    const url = `/${this.companyId}/client-documents/${clientId}/`;
    const data = APIClient.formData(payload);
    return APIClient.post(
      url,
      data,
      undefined,
    ).then((response) => {
      notify("Pomyślnie dodano dokument.", "success");
      return response.data;
    }).catch((error) => {
      notify(`${error}`, 'error');
      throw error.response;
    })
  };

  deleteDocument = (clientId: string, id: string) => {
    const url = `/${this.companyId}/client-documents/${clientId}/${id}/`;
    return APIClient.delete(url).then((response) => {
      notify("Pomyślnie usunięto dokument.", "success");
      return response.data;
    }).catch((error) => {
      notify(`${error}`, 'error');
      throw error.response;
    })
  }


}