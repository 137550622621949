import ClientDocument from "./ClientDocument";
import { Address, Company } from "./index";


export default class Client {
  id: string;
  id_number: string;
  id_type: "NIP" | "PESEL";
  full_name: string;
  emails: string[];
  phone_numbers: string[];
  address: Address;
  documents: ClientDocument[];
  gdpr_ack: boolean;
  company?: Company;

  constructor(input: {[key: string]: any}) {
    this.id = input.id;
    this.id_number = input.id_number;
    this.id_type = input.id_type;
    this.full_name = input.full_name;
    this.emails = input.emails;
    this.phone_numbers = input.phone_numbers;
    this.address = new Address(input.address);
    this.documents = input.documents ? input.documents.map((i: {[key: string]: any}) => {
      return new ClientDocument(i);
    }) : [];
    this.gdpr_ack = input.gdpr_ack;
    if (input.company) {
      this.company = new Company(input.company);
    }
  }

};