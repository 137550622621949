import {
  CircularProgress,
  FormControl, FormHelperText,
  Grid, IconButton,
  InputLabel, MenuItem,
  Select,
  TextField, Tooltip, Typography
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { CompanyMember } from "../../models/CompanyMember";
import CancelIcon from '@material-ui/icons/CancelOutlined';
import SendIcon from '@material-ui/icons/SendOutlined';
import EditIcon from "@material-ui/icons/EditOutlined";
import SaveIcon from '@material-ui/icons/SaveOutlined';
import { AppState } from "../../store";
import { deleteMember, updateMember } from "../../store/Accounts/actions";
import { MembersService } from "../../store/Accounts/services";
import { Session } from "../../store/Auth/types";
import { notify } from "../../utils";


type MemberFormProps = {
  session: Session | undefined,
  updateMember: (payload: CompanyMember) => void,
  deleteMember: (payload: string) => void,
  instance?: CompanyMember,
}


const useStyles = makeStyles(theme => ({
  selectLabel: {
    background: "#fff",
    padding: "0 5px"
  },
  actionButton: {
    marginTop: theme.spacing(0.5),
    marginLeft: theme.spacing(1)
  },
  member: {
    padding: "18.5px 14px"
  }
}));


const MemberForm: React.FC<MemberFormProps> = (props: MemberFormProps) => {

  const classes = useStyles();
  const service = props.session ? new MembersService(props.session.company.id) : null;
  const [disabled, setDisabled] = React.useState(props.instance !== undefined);
  const [errors, setErrors] = React.useState<{[key: string]: any} | undefined>(undefined);
  const [loading, setLoading] = React.useState(false);

  const [email, setEmail] = React.useState<string>(
    props.instance
      ? props.instance.user.email
      : ""
  );
  const [role, setRole] = React.useState<string>(
    props.instance
      ? props.instance.role
      : ""
  );


  const invite = () => {
    if (service && !loading) {
      setLoading(true);
      service.invite(email, role).then(() => {
        notify(`Wysłano zaproszenie na adres email: ${email}.`, "success");
        setLoading(false);
      }).catch((exception) => {
        setErrors(exception.data);
        setLoading(false);
      })
    }
  };

  const update = () => {
    if (service && !loading && props.instance) {
      setLoading(true);
      service.update(props.instance.id, {role: role}).then(member => {
        props.updateMember(member);
        notify(`Pomyślnie edytowano pracownika.`, 'success');
        setLoading(false);
      }).catch((exception) => {
        setErrors(exception.data);
        setLoading(false)
      })
    }
  };

  const remove = () => {
    if (service && !loading && props.instance) {
      const id: string = props.instance.id;
      service.delete(id).then(() => {
        props.deleteMember(id);
        notify(`Pomyślnie usunięto pracownika.`, 'success');
      })
    }
  };

  return (
    <form autoComplete={"off"}>
      <Grid container spacing={3}>
        <Grid item xs={5}>
          {
            props.instance !== undefined ? (
              <Typography variant={"body2"} className={classes.member}>
                {props.instance.user.first_name || ""} {props.instance.user.last_name || ""} {props.instance.user.email}
              </Typography>
            ) : (
              <TextField
                required
                fullWidth
                disabled={disabled}
                variant={"outlined"}
                label={"Email"}
                value={email}
                onChange={(e: any) => setEmail(e.target.value)}
                error={errors && Boolean(errors.email)}
                helperText={
                  errors && Boolean(errors.email)
                    ? errors.email
                    : props.instance ? null : "Na podany adres email wyślemy zaproszenie."
                }
              />
            )
          }
        </Grid>
        <Grid item md={5} xs={4}>
          <FormControl
            required
            fullWidth
            disabled={disabled}
            variant={"outlined"}
            error={errors && errors.role}
          >
            <InputLabel id="role-label" className={classes.selectLabel}>Uprawnienia</InputLabel>
            <Select
              labelId="role-label"
              id="role-select"
              value={role}
              onChange={(e: any) => setRole(e.target.value)}
            >
              <MenuItem value={""}><em>Wybierz uprawnienia</em></MenuItem>
              <MenuItem value={"owner"}>Właściciel</MenuItem>
              <MenuItem value={"admin"}>Administrator</MenuItem>
              <MenuItem value={"member"}>Pracownik</MenuItem>
            </Select>
            {
              errors && errors.role
                ? <FormHelperText>{ errors.role }</FormHelperText>
                : ""
            }
          </FormControl>
        </Grid>
        <Grid item md={2} xs={3} className={"form-actions"}>
          {
            props.instance ? (
              <>
                {
                  props.instance.role === "owner" ? (
                    <IconButton
                      disabled={true}
                      className={classes.actionButton}
                    >
                      <CancelIcon/>
                    </IconButton>
                  ) : (
                    <Tooltip title="Usuń">
                      <IconButton
                        onClick={() => remove()}
                        className={classes.actionButton}
                      >
                        <CancelIcon/>
                      </IconButton>
                    </Tooltip>
                  )
                }
                {
                  disabled ? (
                    <Tooltip title="Edytuj">
                      <IconButton
                        onClick={() => setDisabled(false)}
                        className={classes.actionButton}
                      >
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                  ) : (
                    <Tooltip title="Zapisz">
                      <IconButton
                        onClick={() => {
                          setDisabled(true);
                          update();
                        }}
                        className={classes.actionButton}
                      >
                        <SaveIcon />
                      </IconButton>
                    </Tooltip>
                  )
                }
              </>
            ) : loading ? (
                <CircularProgress />
              ) : (
              <Tooltip title="Wyślij zaproszenie">
                <IconButton
                  className={classes.actionButton}
                  onClick={invite}
                >
                  <SendIcon />
                </IconButton>
              </Tooltip>
            )
          }
        </Grid>
      </Grid>
    </form>
  )
};


const mapStateToProps = (state: AppState) => ({
  session: state.auth.session
});


const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>) => ({
  updateMember: (payload: CompanyMember) => dispatch(updateMember(payload)),
  deleteMember: (payload: string) => dispatch(deleteMember(payload)),
});


export default connect(mapStateToProps, mapDispatchToProps)(MemberForm);
