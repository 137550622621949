import moment, { Moment } from "moment";

export default class InsuranceCommission {
  id: string;
  amount: string;
  payment_date: Moment | null| undefined;

  constructor(input: {[key: string]: any}) {
    this.id = input.id;
    this.amount = input.amount;
    this.payment_date = input.due_date ? moment(input.payment_date) : null;
  }

}