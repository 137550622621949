import { Insurance } from "../../models";

export const INSURANCES_FETCHED = "INSURANCES_FETCHED";
export const INSURANCE_CREATED = "INSURANCE_CREATED";
export const INSURANCE_UPDATED = "INSURANCE_UPDATED";
export const INSURANCE_DELETED = "INSURANCE_DELETED";


export type Insurances = {[key: string]: Insurance};

export type InsurancesFetchAction = {
  type: typeof INSURANCES_FETCHED,
  payload: {insurances: Insurance[], count: number}
}


export type InsuranceCreateAction = {
  type: typeof INSURANCE_CREATED,
  payload: Insurance
}


export type InsuranceUpdateAction = {
  type: typeof INSURANCE_UPDATED,
  payload: Insurance
}


export type InsuranceDeleteAction = {
  type: typeof INSURANCE_DELETED,
  payload: string
}


export type InsuranceListState = {
  insurances: Insurances | undefined,
  count: number | undefined,
}


export type InsurancesAction = (
  InsurancesFetchAction
  | InsuranceCreateAction
  | InsuranceUpdateAction
  | InsuranceDeleteAction)
