import {
  USER_LOGGED_IN,
  Session,
  USER_LOGGED_OUT,
  USER_UPDATED
} from "./types";

export const login = (payload: Session) => {
  return {
    type: USER_LOGGED_IN,
    payload: payload
  }
};


export const logout = () => {
  return {
    type: USER_LOGGED_OUT,
  }
};


export const updateUser = (payload: {firstName: string, lastName: string}) => {
  return {
    type: USER_UPDATED,
    payload: payload
  }
};