import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar
} from "@material-ui/core";
import { AppState } from "../../../store";
import { connect } from "react-redux";
import { IColumn } from "../interfaces/Column";
import Pagination from "../../Filters/Pagination";


type DataTableProps = {
  location: any,
  columns: IColumn[],
  data: any[],
  count: number | undefined, // unknown results
  toolbarContent?: {
    header: React.ReactNode,
    tools: React.ReactNode,
  }
}


const useStyles = makeStyles(theme => ({
  toolbar: {
    padding: theme.spacing(2)
  },
  toolbarHeader: {
    flex: "1 1 100%",
    fontWeight: 400,
    marginRight: theme.spacing(4),
    maxWidth: "50%",
    minWidth: 200
  },
  toolbarTools: {
    marginLeft: "auto"
  }
}));


const DataTable: React.FC<DataTableProps> = (props: DataTableProps) => {
  const classes = useStyles();

  return (
    <TableContainer>
      {
        props.toolbarContent ? (
          <Toolbar classes={{root: classes.toolbar}}>
            <div className={classes.toolbarHeader}>
              {props.toolbarContent?.header}
            </div>
            <div className={classes.toolbarTools}>
              {props.toolbarContent?.tools}
            </div>
          </Toolbar>
        ) : null
      }
      <Table>
        <TableHead>
          <TableRow>
            {
              props.columns.map((column: IColumn) => {
                return <TableCell key={column.id}>{column.label}</TableCell>
              })
            }
          </TableRow>
        </TableHead>
        <TableBody>
          {
            props.data.map(item => {
              return (
                <TableRow key={`row-${item.id}`}>
                  {
                    props.columns.map((column: IColumn) => {
                      return <TableCell key={`${item.id}-${column.id}`}>{column.cellRenderer(item)}</TableCell>
                    })
                  }
                </TableRow>
              )
            })
          }
          <TableRow>
            <Pagination count={props.count}/>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  )
};


const mapStateToProps = (state: AppState) => ({
  location: state.router.location
});


export default connect(mapStateToProps)(DataTable);