import React from "react";
import Home from "../Home";
import { Switch } from "react-router";
import { ClientCreateView, ClientDetailView, ClientListView } from "../Clients";
import { PrivateRoute } from "../../components/PrivateRoute";
import {
  InsuranceCreateView,
  InsuranceDetailView,
  InsuranceListView
} from "../Insurances";
import { AccountSettingsView } from "../Accounts";
import { TodoDashboardView, TodoListView } from "../Todos";


export const Routes = (): JSX.Element => {
  return (
    <Switch>
      <PrivateRoute exact path={"/dashboard/"} component={Home} key={"dashboard"}/>
      <PrivateRoute exact path={"/dashboard/clients/"} component={ClientListView} key={"client-list"} />
      <PrivateRoute exact path={"/dashboard/clients/add/"} component={ClientCreateView} key={"client-add"} />
      <PrivateRoute exact path={"/dashboard/clients/client/:id/"} component={ClientDetailView} key={"client-detail"} />
      <PrivateRoute exact path={"/dashboard/insurances/"} component={InsuranceListView} key={"insurance-list"} />
      <PrivateRoute exact path={"/dashboard/insurances/add/"} component={InsuranceCreateView} key={"insurance-create"} />
      <PrivateRoute exact path={"/dashboard/insurances/insurance/:id/"} component={InsuranceDetailView} key={"insurance-detail"} />
      <PrivateRoute exact path={"/dashboard/tasks/"} component={TodoListView} key={"todo-list"} />
      <PrivateRoute exact path={"/dashboard/tasks/my-list/"} component={TodoDashboardView} key={"todo-dashboard"} />
      <PrivateRoute exact path={"/dashboard/account-settings/"} component={AccountSettingsView} key={"account-settings"} />
    </Switch>
  )
};