import { makeStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/DeleteOutlined";
import EditIcon from "@material-ui/icons/EditOutlined";
import DownloadIcon from '@material-ui/icons/GetAppOutlined';
import { Alert } from "@material-ui/lab";
import clsx from "clsx";
import moment from "moment";
import React from "react";
import {
  Breadcrumbs,
  Button, Dialog, DialogContent, DialogTitle, Divider,
  Grid, IconButton,
  Link, ListItemText, Paper, Tooltip,
  Typography
} from "@material-ui/core";
import { connect } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import { ThunkDispatch } from "redux-thunk";
import { DataTable } from "../../components/DataTable";
import InsuranceForm from "../../components/Insurances/InsuranceForm";
import InsuranceListFilters
  from "../../components/Insurances/InsuranceListFilters";
import { INSURANCE_TYPES } from "../../constants";
import { Insurance } from "../../models";
import { AppState } from "../../store";
import { Session } from "../../store/Auth/types";
import { Filter, Filters } from "../../store/Filters/types";
import {
  deleteInsurance,
  fetchInsurances, updateInsurance
} from "../../store/Insurances/actions";
import { InsuranceService } from "../../store/Insurances/services";
import { Insurances } from "../../store/Insurances/types";
import { fileDownload } from "../../utils";
import FilterListIcon from '@material-ui/icons/FilterListOutlined';
import SearchFilter from "../../components/Filters/SearchFilter";


type InsuranceListViewProps = {
  session: Session | undefined;
  insurances: Insurances | undefined;
  count: number | undefined;
  filters: Filters | undefined;
  fetchInsurances: (payload: {insurances: Insurance[], count: number}) => void;
  updateInsurance: (payload: Insurance) => void;
  deleteInsurance: (payload: string) => void;
}


const useStyles = makeStyles(theme => ({
  filtersContainer: {
    float:"right",
    marginLeft: "auto",
    width: 300,
    padding: theme.spacing(2),
    backgroundColor: "#f2f2f2",
    zIndex: 1000,
    [theme.breakpoints.down('md')]: {
      width: "100%",
      top: 0,
    },
  },
  dataContainer: {
    width: `100%`,
    [theme.breakpoints.up('lg')]: {
      width: `calc(100% - 300px - ${theme.spacing(2)}px)`,
    },
  },
  dataContainerExtended: {
    width: `100%`,
  },
  hidden: {
    visibility: "hidden",
    width: 0,
    height: 0,
    margin: 0,
    padding: 0,
  }
}));


const InsuranceListView: React.FC<InsuranceListViewProps> = (props: InsuranceListViewProps) => {

  const classes = useStyles();
  const service = props.session ? new InsuranceService(props.session.company.id) : undefined;
  const [editing, setEditing] = React.useState<Insurance | undefined>(undefined);
  const [deleting, setDeleting] = React.useState<Insurance | undefined>(undefined);
  const [filtersState, setFiltersState] = React.useState(props.filters);
  const [showFilters, setShowFilters] = React.useState(false);

  const {filters, fetchInsurances} = props;

  const getCSV = () => {
    if (service) {
      const filterParams: {[key: string]: any} = {};
      Object.values(filters || {}).forEach((filter: Filter) => {
        filterParams[filter.filterParam] = filter.value;
      });
      return service.getCSV(filterParams).then(response => {
        return fileDownload(response.data, "polisy.csv", response.data.type)
      })
    }
  };

  const getInsurances = () => {
    if (service && filters !== filtersState) {
      setFiltersState(filters);
      const filterParams: { [key: string]: any } = {};
      Object.values(filters || {}).forEach((filter: Filter) => {
        filterParams[filter.filterParam] = filter.value;
      });
      service.fetch(filterParams).then((payload) => {
        fetchInsurances(payload)
      }).catch(() => {
        fetchInsurances({insurances: [], count: 0})
      })
    }
  };

  React.useEffect(() => {
    getInsurances();
  });

  const editDialog = (insurance: Insurance | undefined): React.ReactNode => {
    if (insurance !== undefined) {
      return (
        <Dialog
          open={true}
          onClose={() => setEditing(undefined)}
          disableBackdropClick
          fullWidth
          maxWidth={"lg"}
        >
          <DialogTitle>Edytuj polisę nr {insurance.number}</DialogTitle>
          <DialogContent dividers>
            <InsuranceForm
              instance={insurance}
              onSuccess={(edited: Insurance) => {
                props.updateInsurance(edited);
                setEditing(undefined);
              }}
              onCancel={() => setEditing(undefined)}
            />
          </DialogContent>
        </Dialog>
      )
    }
  };

  const deleteDialog = (insurance: Insurance | undefined): React.ReactNode => {
    if (insurance !== undefined) {
      return (
        <Dialog open={true} onClose={() => setDeleting(undefined)} disableBackdropClick>
          <DialogTitle>Usuń polisę nr {insurance.number}</DialogTitle>
          <DialogContent dividers>
            <Alert severity={"warning"}>
              Uwaga! Ta akcja jest nieodwracalna.
              Usunięte zostaną również składki i załączniki.
            </Alert>
            <InsuranceForm
              instance={insurance}
              onSuccess={(deleted: Insurance) => {
                props.deleteInsurance(deleted.id);
                setDeleting(undefined);
              }}
              onCancel={() => setDeleting(undefined)}
              delete={true}
            />
          </DialogContent>
        </Dialog>
      )
    }
  };

  return (
    <>
      <div className={"header"}>
        <Grid container>
          <Grid item className={"header-content"}>
            <Breadcrumbs>
              <Link component={RouterLink} to={"/dashboard/"}>Pulpit</Link>
              <Typography color={"textSecondary"}>Polisy</Typography>
            </Breadcrumbs>
          </Grid>
          <Grid item className={"header-actions"}>
            <Button
              variant={"contained"}
              color={"primary"}
              size={"small"}
              component={RouterLink}
              to={"/dashboard/insurances/add/"}
            >
              <AddIcon fontSize={"small"}/>
              Dodaj
            </Button>
          </Grid>
        </Grid>
        <Divider />
      </div>
      <Grid container className={"content"} spacing={2}>
        <Grid item xs={12}>
          <div className={clsx(showFilters && classes.filtersContainer, !showFilters && classes.hidden)}>
            <InsuranceListFilters />
          </div>
          <div className={clsx(showFilters ? classes.dataContainer : classes.dataContainerExtended)}>
            <Paper className={"paper-content"}>
              { props.filters !== undefined ? (
                <DataTable
                  count={props.count}
                  toolbarContent={{
                    header: (
                      <SearchFilter key={"insurances-search"}/>
                    ),
                    tools: (
                      <>
                        <Tooltip title="Pobierz CSV">
                          <IconButton aria-label="Pobierz CSV" onClick={() => getCSV()}>
                            <DownloadIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Filtry">
                          <IconButton aria-label="Filtry" onClick={() => setShowFilters(!showFilters)}>
                            <FilterListIcon />
                          </IconButton>
                        </Tooltip>
                      </>
                    )
                  }}
                  columns={[
                    {
                      id: "number",
                      label: "Polisa",
                      cellRenderer: (item: Insurance) => {
                        return (
                          <ListItemText
                            primary={
                              <React.Fragment>
                                <Link component={RouterLink} to={`/dashboard/insurances/insurance/${item.id}/`}>
                                  {item.number}
                                </Link>
                              </React.Fragment>
                            }
                            secondary={
                              <React.Fragment>{item.insurer}</React.Fragment>
                            }
                          />
                        )
                      }
                    },
                    {
                      id: "insuranceType",
                      label: "Typ polisy",
                      cellRenderer: (item: Insurance) => {
                        return (
                          <ListItemText
                            primary={INSURANCE_TYPES[item.insurance_type]}
                            secondary={item.insurance_variants.join(", ")}
                          />
                        )
                      }
                    },
                    {
                      id: "agreementDate",
                      label: "Zawarcie umowy",
                      cellRenderer: (item: Insurance) => {
                        return <>{item.agreement_date?.format("YYYY-MM-DD")}</>
                      }
                    },
                    {
                      id: "dates",
                      label: "Okres ubezpieczenia",
                      cellRenderer: (item: Insurance) => {
                        return <>{item.start_date?.format("YYYY-MM-DD")} - {item.end_date?.format("YYYY-MM-DD")}</>
                      }
                    },
                    {
                      id: "status",
                      label: "Status",
                      cellRenderer: (item: Insurance) => {
                        const today = moment().startOf("day");
                        const endDate = item.end_date || today;
                        return (
                          endDate >= today ? (
                            <>{item.status === "active" ? "Aktywna" : "Przerwana"}</>
                          ) : (
                            <>{item.status === "terminated" ? "Przerwana" : "Zakończona"}</>
                          )
                        )
                      }
                    },
                    {
                      id: "client",
                      label: "Klient",
                      cellRenderer: (item: Insurance) => {
                        return (
                          <ListItemText
                            primary={
                              <React.Fragment>
                                <Link component={RouterLink} to={`/dashboard/clients/client/${item.client.id}/`}>
                                  {item.client.full_name}
                                </Link>
                              </React.Fragment>
                            }
                            secondary={
                              <React.Fragment>{item.client.id_type}: {item.client.id_number}</React.Fragment>
                            }
                          />
                        )
                      }
                    },
                    {
                      id: "commissions",
                      label: "Prowizje",
                      cellRenderer: (item: Insurance) => {
                        return (
                          <ListItemText
                            primary={
                              <React.Fragment>
                                {item.paidCommission ? "TAK" : "NIE"}
                              </React.Fragment>
                            }
                            secondary={
                              <React.Fragment>{item.commissionsAmount || 0} PLN</React.Fragment>
                            }
                          />
                        )
                      }
                    },
                    {
                      id: "actions",
                      label: "",
                      cellRenderer: (item: Insurance) => {
                        return (
                          <>
                            <Tooltip title={"Usuń"}>
                              <IconButton style={{float: "right"}} onClick={() => setDeleting(item)}>
                                <DeleteIcon />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title={"Edytuj"}>
                              <IconButton style={{float: "right"}} onClick={() => setEditing(item)}>
                                <EditIcon />
                              </IconButton>
                            </Tooltip>
                          </>
                        )
                      }
                    },
                  ]}
                  data={Object.values(props.insurances || {})}
                />
              ): null}
            </Paper>
          </div>
        </Grid>
      </Grid>
      {editDialog(editing)}
      {deleteDialog(deleting)}
    </>
  )
};


const mapStateToProps = (state: AppState) => ({
  session: state.auth.session,
  insurances: state.insuranceList.insurances,
  count: state.insuranceList.count,
  filters: state.filters.filters,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>) => ({
  fetchInsurances: (payload: {insurances: Insurance[], count: number}) => dispatch(fetchInsurances(payload)),
  updateInsurance: (payload: Insurance) => dispatch(updateInsurance(payload)),
  deleteInsurance: (payload: string) => dispatch(deleteInsurance(payload)),
});


export default connect(mapStateToProps, mapDispatchToProps)(InsuranceListView)