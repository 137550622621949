import {
  Button, Card, CardContent, CardHeader, Checkbox,
  FormControl, FormControlLabel, FormHelperText,
  Grid,
  InputLabel, MenuItem, Select,
  TextField
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ChipInput from "material-ui-chip-input";
import React from "react";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { Client } from "../../models";
import { AppState } from "../../store";
import { Session } from "../../store/Auth/types";
import { ClientService } from "../../store/Clients/services";
import { NonFieldErrors } from "../Forms";
import { Alert } from "@material-ui/lab";


type ClientFormProps = {
  session: Session | undefined,
  onSuccess: (client: Client) => void,
  onCancel: () => void,
  disabled?: boolean,
  instance?: Client,
  delete?: boolean,
}


const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2)
  },
  selectLabel: {
    background: "#fff",
    padding: "0 5px"
  },
  addressFieldset: {
    padding: theme.spacing(2),
  }
}));


const ClientForm: React.FC<ClientFormProps> = (props: ClientFormProps) => {

  const classes = useStyles();
  const service = props.session ? new ClientService(props.session.company.id) : undefined;
  const [errors, setErrors] = React.useState<{[key: string]: any} | undefined>(undefined);
  const [instance, ] = React.useState<Client | undefined>(props.instance);
  const [disabled, setDisabled] = React.useState(Boolean(props.disabled));

  const [idNumber, setIdNumber] = React.useState<string>(
    instance
      ? instance.id_number
      : ""
  );
  const [idType, setIdType] = React.useState<string>(
    instance
      ? instance.id_type
      : "PESEL"
  );
  const [fullName, setFullName] = React.useState<string>(
    instance
      ? instance.full_name
      : ""
  );
  const [gdprAck, setGdprAck] = React.useState<boolean>(
    instance
      ? instance.gdpr_ack
      : false
  );

  const [emails, setEmails] = React.useState<string[]>(
    instance
      ? instance.emails
      : []
  );

  const [phoneNumbers, setPhoneNumbers] = React.useState<string[]>(
    instance
      ? instance.phone_numbers
      : []
  );

  const [streetName, setStreetName] = React.useState<string>(
    instance
      ? instance.address.street_name || ""
      : ""
  );

  const [routeNumber, setRouteNumber] = React.useState<string>(
    instance
      ? instance.address.route_number || ""
      : ""
  );

  const [postalCode, setPostalCode] = React.useState<string>(
    instance
      ? instance.address.postal_code || ""
      : ""
  );

  const [city, setCity] = React.useState<string>(
    instance
      ? instance.address.city || ""
      : ""
  );

  const handleSubmit = (e: any) => {
    e.preventDefault();
    const payload: {[key: string]: any} = {
      id_number: idNumber,
      id_type: idType,
      full_name: fullName,
      emails: emails,
      phone_numbers: phoneNumbers,
      gdpr_ack: gdprAck,
      address: {}
    };
    const address = {
      street_name: streetName,
      route_number: routeNumber,
      postal_code: postalCode,
      city: city
    };
    if (!Object.values(address).every(attr => attr === undefined || attr === "")) {
      payload["address"] = {...address}
    }
    if (service) {
      if (instance === undefined) {
        service.create(payload).then(response => {
          const client: Client = new Client(response);
          props.onSuccess(client);
        }).catch(exception => {
          setErrors(exception.data);
        })
      }
      else {
        // update
        service.update(instance.id, payload).then(response => {
          const client: Client = new Client(response);
          props.onSuccess(client);
        }).catch(exception => {
          setErrors(exception.data);
        })
      }
    }
  };

  const handleDelete = () => {
    if (service && instance && props.delete) {
      service.delete(instance.id).then(() => {
        props.onSuccess(instance);
      });
    }
  };

  if (instance && props.delete) {
    return (
      <div className={"form-actions"}>
        <Button color={"secondary"} variant={"contained"} onClick={handleDelete}>
          Usuń
        </Button>
        <Button color={"default"} variant={"contained"} onClick={
          () => {
            props.onCancel();
          }
        }>
          Anuluj
        </Button>
      </div>
    )
  }

  return (
    <form autoComplete={"off"} className={classes.root}>
      {
        errors !== undefined && errors.non_field_errors
          ? (
            <NonFieldErrors errors={errors.non_field_errors}/>
          )
          : null
      }
      <Grid container spacing={3}>
        <Grid item xs={4}>
          <FormControl
            required
            fullWidth
            disabled={disabled}
            variant={"outlined"}
            error={errors && errors.id_type}
          >
            <InputLabel id="idType-label" className={classes.selectLabel}>Typ</InputLabel>
            <Select
              labelId="idType-label"
              id="idType-select"
              value={idType}
              onChange={(e: any) => setIdType(e.target.value)}
            >
              <MenuItem value={"PESEL"}>PESEL</MenuItem>
              <MenuItem value={"NIP"}>NIP</MenuItem>
            </Select>
            {
              errors && errors.id_type
                ? <FormHelperText>{ errors.id_type }</FormHelperText>
                : null
            }
          </FormControl>
        </Grid>
        <Grid item xs={8}>
          <TextField
            required
            fullWidth
            disabled={disabled}
            variant={"outlined"}
            value={idNumber}
            label={`Numer ${idType}`}
            onChange={(e: any) => setIdNumber(e.target.value)}
            error={errors && Boolean(errors.id_number)}
            helperText={errors && errors.id_number ? errors.id_number : null}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            fullWidth
            disabled={disabled}
            variant={"outlined"}
            value={fullName}
            label={"Pełna nazwa"}
            onChange={(e: any) => setFullName(e.target.value)}
            error={errors && Boolean(errors.full_name)}
            helperText={errors && errors.full_name ? errors.full_name : null}
          />
        </Grid>
        <Grid item xs={12}>
          <ChipInput
            fullWidth
            disabled={disabled}
            variant={"outlined"}
            value={emails}
            label={"Adresy email"}
            onAdd={(email) => setEmails([...emails, email])}
            onDelete={
              (email, index) => setEmails(
                [...emails.slice(0, index), ...emails.slice(index+1, emails.length)]
              )
            }
            error={errors && Boolean(errors.emails)}
            helperText={errors && errors.emails ? Object.values(errors.emails) : null}
          />
        </Grid>
        <Grid item xs={12}>
          <ChipInput
            fullWidth
            disabled={disabled}
            variant={"outlined"}
            value={phoneNumbers}
            label={"Numery telefonów"}
            onAdd={(phoneNumber) => setPhoneNumbers([...phoneNumbers, phoneNumber])}
            onDelete={
              (phoneNumber, index) => setPhoneNumbers(
                [...phoneNumbers.slice(0, index), ...phoneNumbers.slice(index+1, phoneNumbers.length)]
              )
            }
            error={errors && Boolean(errors.phone_numbers)}
            helperText={errors && errors.phone_numbers ? Object.values(errors.phone_numbers) : null}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                disabled={disabled}
                checked={gdprAck}
                onChange={(e: any) => setGdprAck(e.target.checked)} name="gdpr_ack"
              />
            }
            label="Pouczony o RODO"
          />
        </Grid>
        <Grid item xs={12}>
          <Card variant={"outlined"}>
            <CardHeader
              title={"Adres korespondencyjny"}
              subheader={
                "Jeżeli chcesz dodać adres korespondencyjny wszystkie pola są wymagane, w przeciwnym wypadku zostaw puste."
              }
            />
            {
              errors && errors.address
                ? (
                  <Alert severity={"error"}>{errors.address}</Alert>
                )
                : null
            }
            <CardContent>
              <Grid container spacing={3}>
                <Grid item xs={8}>
                  <TextField
                    required
                    fullWidth
                    disabled={disabled}
                    variant={"outlined"}
                    value={streetName}
                    label={"Ulica"}
                    onChange={(e: any) => setStreetName(e.target.value)}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    required
                    fullWidth
                    disabled={disabled}
                    variant={"outlined"}
                    value={routeNumber}
                    label={"Numer"}
                    onChange={(e: any) => setRouteNumber(e.target.value)}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    required
                    fullWidth
                    disabled={disabled}
                    variant={"outlined"}
                    value={postalCode}
                    label={"Kod pocztowy"}
                    onChange={(e: any) => setPostalCode(e.target.value)}
                    helperText={"Format: 00-000"}
                  />
                </Grid>
                <Grid item xs={8}>
                  <TextField
                    required
                    fullWidth
                    disabled={disabled}
                    variant={"outlined"}
                    value={city}
                    label={"Miasto"}
                    onChange={(e: any) => setCity(e.target.value)}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} className={"form-actions"}>
          {
            disabled
              ? (
                <Button color={"primary"} variant={"contained"} onClick={() => setDisabled(false)}>
                  Edytuj
                </Button>
              )
              : (
                <>
                  <Button color={"primary"} variant={"contained"} onClick={handleSubmit}>
                    Zapisz
                  </Button>
                  <Button color={"default"} variant={"contained"} onClick={
                    () => {
                      props.onCancel();
                      if (props.disabled === true) {
                        // in case then disabled was initialised
                        setDisabled(true);
                      }
                    }
                  }>
                    Anuluj
                  </Button>
                </>
              )
          }
        </Grid>
      </Grid>
    </form>
  )
};


const mapStateToProps = (state: AppState) => ({
  session: state.auth.session
});


const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>) => ({});


export default connect(mapStateToProps, mapDispatchToProps)(ClientForm)
