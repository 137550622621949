import {
  Breadcrumbs, Button, Dialog, DialogContent, DialogTitle,
  Divider,
  Grid,
  Link,
  Typography
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import React from "react";
import { connect } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import { ThunkDispatch } from "redux-thunk";
import TodoDashboard from "../../components/Todos/TodoDashboard";
import TodoForm from "../../components/Todos/TodoForm";
import { Todo } from "../../models";
import { CompanyMember } from "../../models/CompanyMember";
import { AppState } from "../../store";
import { Session } from "../../store/Auth/types";
import { createTodo } from "../../store/Todos/actions";


type TodoDashboardViewProps = {
  session: Session | undefined,
  createTodo: (payload: Todo) => void,
}

const TodoDashboardView: React.FC<TodoDashboardViewProps> = (props: TodoDashboardViewProps) => {
  const [addDialogOpen, setAddDialogOpen] = React.useState(false);

  const addDialog = (): React.ReactNode => {
    const instance: Todo = new Todo({
      id: "",
      title: "",
      content: "",
      assignee: props.session && props.session.company.member_id ? new CompanyMember({
        id: props.session.company.member_id,
        role: props.session.company.role,
        user: {
          id: props.session.user.id,
          email: props.session.user.email,
          first_name: props.session.user.firstName,
          last_name: props.session.user.lastName,
        }
      }) : null,
      status: "new",
      related_obj: {related_to: ""}
    });
    return (
      <Dialog open={addDialogOpen} onClose={() => setAddDialogOpen(false)} disableBackdropClick>
        <DialogTitle>Dodaj zadanie</DialogTitle>
        <DialogContent dividers>
          <TodoForm
            instance={instance}
            onSuccess={(todo: Todo) => {
              props.createTodo(todo);
              setAddDialogOpen(false);
            }}
            onCancel={() => setAddDialogOpen(false)}
          />
        </DialogContent>
      </Dialog>
    )
  };

  return (
    <>
      <div className={"header"}>
        <Grid container>
          <Grid item className={"header-content"}>
            <Breadcrumbs>
              <Link component={RouterLink} to={"/dashboard/"}>Pulpit</Link>
              <Link component={RouterLink} to={"/dashboard/tasks/"}>Zadania</Link>
              <Typography color={"textSecondary"}>Moja lista</Typography>
            </Breadcrumbs>
          </Grid>
          <Grid item className={"header-actions"}>
            <Button
              variant={"contained"}
              color={"primary"}
              size={"small"}
              onClick={() => setAddDialogOpen(true)}
            >
              <AddIcon fontSize={"small"}/>
              Dodaj
            </Button>
          </Grid>
        </Grid>
        <Divider />
      </div>
      <Grid container className={"content"} spacing={2}>
        <Grid item xs={12}>
          <TodoDashboard />
        </Grid>
      </Grid>
      {addDialog()}
    </>
  );

};


const mapStateToProps = (state: AppState) => ({
  session: state.auth.session
});


const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>) => ({
  createTodo: (payload: Todo) => dispatch(createTodo(payload)),
});


export default connect(mapStateToProps, mapDispatchToProps)(TodoDashboardView);