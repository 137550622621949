import {
  AuthAction,
  AuthState,
  USER_LOGGED_IN,
  USER_LOGGED_OUT,
  USER_UPDATED
} from "./types";


export const authReducer = (
    state: AuthState = {
      session: undefined,
    }, action: AuthAction): AuthState => {
  switch (action.type) {
    case USER_LOGGED_IN:
      localStorage.setItem('SESSION', JSON.stringify(action.payload));
      return {
        ...state,
        session: action.payload
      };

    case USER_LOGGED_OUT:
      localStorage.removeItem("SESSION");
      return {
        ...state,
        session: undefined
      };

    case USER_UPDATED:
      if (state.session) {
        const session = state.session;
        const user = {...state.session.user, ...action.payload};
        session.user = user;
        localStorage.setItem('SESSION', JSON.stringify(session));
        return {
          ...state,
          session: {...session}
        }
      }
      return state;

    default:
      return state;

  }
};
