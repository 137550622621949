import { Client } from "../../models";
import { CLIENT_CREATED, CLIENT_DELETED, CLIENT_EDITED, CLIENTS_FETCHED } from "./types";


export const fetchClients = (payload: {clients: Client[], count: number}) => {
  return {
    type: CLIENTS_FETCHED,
    payload: payload
  }
};


export const createClient = (payload: Client) => {
  return {
    type: CLIENT_CREATED,
    payload: payload
  }
};


export const updateClient = (payload: Client) => {
  return {
    type: CLIENT_EDITED,
    payload: payload
  }
};


export const deleteClient = (payload: string) => {
  return {
    type: CLIENT_DELETED,
    payload: payload
  }
};
