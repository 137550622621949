import axios, { AxiosRequestConfig, AxiosResponse, CancelToken } from "axios";
import { deserializeStorage, fileDownload, notify } from "../utils";
import { Session } from "../store/Auth/types";


axios.interceptors.request.use((config: AxiosRequestConfig) => {
  config.baseURL = process.env.REACT_APP_API_URL;
  return config
});


axios.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (error.response && error.response.status === 401) {
      notify('Twoja sesja wygasła, zaloguj się ponownie.', 'error')
    }
    if (error.response && error.response.status === 403) {
      notify('Nie masz wystarczających uprawnień.', 'error')
    }
    throw error;
  }
);


export interface IParams {
  [Key: string]: any
}


export interface IPayload {
  [Key: string]: any
}


export interface IHeaders {
  [Key: string]: string
}


export class APIClient {
  static get = (
    endpoint: string,
    params?: IParams,
    headers?: IHeaders,
    cancelToken?: CancelToken,
    extraOptions?: any): Promise<AxiosResponse> => {

    return axios.get(
      endpoint,
      APIClient.httpOptions(params, headers, cancelToken, extraOptions)
    )
  };

  static post = (
    endpoint: string,
    payload?: IPayload | FormData,
    params?: IParams,
    headers?: IHeaders,
    cancelToken?: CancelToken,
    extraOptions?: any): Promise<AxiosResponse> => {
    return axios.post(
      endpoint,
      payload, //APIClient.formData(payload),
      APIClient.httpOptions(params, headers, cancelToken, extraOptions)
    );
  };

  static put = (
    endpoint: string,
    payload?: IPayload | FormData,
    params?: IParams,
    headers?: IHeaders,
    cancelToken?: CancelToken,
    extraOptions?: any): Promise<AxiosResponse> => {
    return axios.put(
      endpoint,
      payload, //APIClient.formData(payload),
      APIClient.httpOptions(params, headers, cancelToken, extraOptions)
    );
  };

  static patch = (
    endpoint: string,
    payload?: IPayload | FormData,
    params?: IParams,
    headers?: IHeaders,
    cancelToken?: CancelToken,
    extraOptions?: any): Promise<AxiosResponse> => {
    return axios.patch(
      endpoint,
      payload, //APIClient.formData(payload),
      APIClient.httpOptions(params, headers, cancelToken, extraOptions),
    );
  };

  static delete = (endpoint: string, headers?: IHeaders, cancelToken?: CancelToken) => {
    return axios.delete(endpoint, APIClient.httpOptions({}, headers, cancelToken));
  };

  static file = (path: string, params: IParams = {}) => {
    const fileName: string = path.split("/").pop() || path;
    return axios.get(
      path,
      APIClient.httpOptions(params, undefined, undefined, {responseType: "blob"})
    ).then(response => {
      return fileDownload(response.data, fileName, response.data.type)
    })
  };

  static formData = (payload?: IPayload): FormData => {
    const data = payload ? payload : {};
    const formData = new FormData();
    for (let key in data) {
      formData.set(key, data[key]);
    }
    return formData;
  };

  protected static httpOptions = (
    params?: IParams,
    headers?: IHeaders,
    cancelToken?: CancelToken, extraOptions?: any
  ) => {
    const session: Session | null = deserializeStorage('SESSION');
    let httpOptions: AxiosRequestConfig = {
      cancelToken: cancelToken,
      headers: headers || {}
    };
    if (session) {
      httpOptions['headers']['Authorization'] = 'JWT ' + session.token
    }
    if (params) {
      httpOptions.params = params;
    }
    httpOptions = {...httpOptions, ...extraOptions};
    return httpOptions;
  }

}