import { makeStyles } from "@material-ui/core/styles";
import FilterListIcon from "@material-ui/icons/FilterListOutlined";
import clsx from "clsx";
import React from "react";
import {
  Breadcrumbs,
  Button, Dialog, DialogContent, DialogTitle,
  Divider,
  Grid, IconButton,
  Link, ListItemText,
  Paper, Tooltip,
  Typography
} from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import AddIcon from "@material-ui/icons/Add";
import ClientListFilters from "../../components/Clients/ClientListFilters";
import { AppState } from "../../store";
import { connect } from "react-redux";
import { Clients } from "../../store/Clients/types";
import { Client } from "../../models";
import { ThunkDispatch } from "redux-thunk";
import { deleteClient, fetchClients, updateClient } from "../../store/Clients/actions";
import { ClientService } from "../../store/Clients/services";
import { Session } from "../../store/Auth/types";
import { DataTable } from "../../components/DataTable";
import DeleteIcon from "@material-ui/icons/DeleteOutlined";
import EditIcon from "@material-ui/icons/EditOutlined";
import ClientForm from "../../components/Clients/ClientForm";
import { Alert } from "@material-ui/lab";
import { Filter, Filters } from "../../store/Filters/types";
import SearchFilter from "../../components/Filters/SearchFilter";
import DownloadIcon from '@material-ui/icons/GetAppOutlined';
import { fileDownload } from "../../utils";


type ClientListViewProps = {
  session: Session | undefined,
  clients: Clients | undefined,
  count: number | undefined,
  filters: Filters | undefined;
  fetchClients: (payload: {clients: Client[], count: number}) => void,
  updateClient: (payload: Client) => void
  deleteClient: (payload: string) => void
}


const useStyles = makeStyles(theme => ({
  filtersContainer: {
    float:"right",
    marginLeft: "auto",
    width: 300,
    padding: theme.spacing(2),
    backgroundColor: "#f2f2f2",
    zIndex: 1000,
    [theme.breakpoints.down('md')]: {
      width: "100%",
      top: 0,
    },
  },
  dataContainer: {
    width: `100%`,
    [theme.breakpoints.up('lg')]: {
      width: `calc(100% - 300px - ${theme.spacing(2)}px)`,
    },
  },
  dataContainerExtended: {
    width: `100%`,
  },
  hidden: {
    visibility: "hidden",
    width: 0,
    height: 0,
    margin: 0,
    padding: 0,
  }
}));


const ClientListView: React.FC<ClientListViewProps>= (props: ClientListViewProps) => {

  const classes = useStyles();
  const service = props.session ? new ClientService(props.session.company.id) : undefined;
  const [editing, setEditing] = React.useState<Client | undefined>(undefined);
  const [deleting, setDeleting] = React.useState<Client | undefined>(undefined);
  const [filtersState, setFiltersState] = React.useState(props.filters);
  const [showFilters, setShowFilters] = React.useState(false);

  const {filters, fetchClients} = props;

  const getCSV = () => {
    if (service) {
      const filterParams: {[key: string]: any} = {};
      Object.values(filters || {}).forEach((filter: Filter) => {
        filterParams[filter.filterParam] = filter.value;
      });
      return service.getCSV(filterParams).then(response => {
        return fileDownload(response.data, "klienci.csv", response.data.type)
      })
    }
  };

  const getClients = () => {
    if (service && filters !== filtersState) {
      setFiltersState(filters);
      const filterParams: { [key: string]: any } = {};
      Object.values(filters || {}).forEach((filter: Filter) => {
        filterParams[filter.filterParam] = filter.value;
      });
      service.fetch(filterParams).then((payload) => {
        fetchClients(payload)
      }).catch(() => {
        fetchClients({clients: [], count: 0})
      })
    }
  };

  React.useEffect(() => {
    getClients();
  });

  const editDialog = (client: Client | undefined): React.ReactNode => {
    if (client !== undefined) {
      return (
        <Dialog open={client !== undefined} onClose={() => setEditing(undefined)} disableBackdropClick>
          <DialogTitle>Edytuj klienta {client.full_name}</DialogTitle>
          <DialogContent dividers>
            <ClientForm
              instance={client}
              onSuccess={(edited: Client) => {
                setEditing(undefined);
                props.updateClient(edited);
              }}
              onCancel={() => setEditing(undefined)}
            />
          </DialogContent>
        </Dialog>
      )
    }
  };

  const deleteDialog = (client: Client | undefined): React.ReactNode => {
    if (client !== undefined) {
      return (
        <Dialog open={client !== undefined} onClose={() => setDeleting(undefined)} disableBackdropClick>
          <DialogTitle>Usuń klienta {client.full_name}</DialogTitle>
          <DialogContent dividers>
            <Alert severity={"warning"}>
              Uwaga! Ta akcja jest nieodwracalna.
              Usunięte zostaną również polisy związane z tym klientem.
            </Alert>
            <ClientForm
              instance={client}
              onSuccess={(deleted: Client) => {
                props.deleteClient(deleted.id);
                setDeleting(undefined);
              }}
              onCancel={() => setDeleting(undefined)}
              delete={true}
            />
          </DialogContent>
        </Dialog>
      )
    }
  };

  return (
    <>
      <div className={"header"}>
        <Grid container>
          <Grid item className={"header-content"}>
            <Breadcrumbs>
              <Link component={RouterLink} to={"/dashboard/"}>Pulpit</Link>
              <Typography color={"textSecondary"}>Klienci</Typography>
            </Breadcrumbs>
          </Grid>
          <Grid item className={"header-actions"}>
            <Button
              variant={"contained"}
              color={"primary"}
              size={"small"}
              component={RouterLink}
              to={"/dashboard/clients/add/"}
            >
              <AddIcon fontSize={"small"}/>
              Dodaj
            </Button>
          </Grid>
        </Grid>
        <Divider />
      </div>
      <Grid container className={"content"}>
        <Grid item xs={12}>
          <div className={clsx(showFilters && classes.filtersContainer, !showFilters && classes.hidden)}>
            <ClientListFilters />
          </div>
          <div className={clsx(showFilters ? classes.dataContainer : classes.dataContainerExtended)}>
            <Paper className={"paper-content"}>
              <Grid container>
                <Grid item xs={12}>
                  <DataTable
                    count={props.count}
                    toolbarContent={{
                      header: <SearchFilter key={"clients-search"}/>,
                      tools: (
                        <>
                          <Tooltip title="Pobierz CSV">
                            <IconButton aria-label="Pobierz CSV" onClick={() => getCSV()}>
                              <DownloadIcon />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Filtry">
                            <IconButton aria-label="Filtry" onClick={() => setShowFilters(!showFilters)}>
                              <FilterListIcon />
                            </IconButton>
                          </Tooltip>
                        </>
                      )
                    }}
                    columns={[
                      {
                        id: "client",
                        label: "Klient",
                        cellRenderer: (item: Client) => {
                          return (
                            <ListItemText
                              primary={
                                <React.Fragment>
                                  <Link component={RouterLink} to={`/dashboard/clients/client/${item.id}/`}>
                                    {item.full_name}
                                  </Link>
                                </React.Fragment>
                              }
                              secondary={
                                <React.Fragment>{item.id_type}: {item.id_number}</React.Fragment>
                              }
                            />
                          )
                        }
                      },
                      {
                        id: "emails",
                        label: "Adresy email",
                        cellRenderer: (item: Client) => {
                          return (
                            <p>{item.emails.join(", ")}</p>
                          )
                        }
                      },
                      {
                        id: "phone_numbers",
                        label: "Numery telefonów",
                        cellRenderer: (item: Client) => {
                          return (
                            <>
                              <p>{item.phone_numbers.join(",")}</p>
                            </>
                          )
                        }
                      },
                      {
                        id: "address",
                        label: "Adres",
                        cellRenderer: (item: Client) => {
                          return (
                            <>
                              <ListItemText
                                primary={`${item.address.street_name || ""} ${item.address.route_number || ""}`}
                                secondary={`${item.address.postal_code || ""} ${item.address.city || ""}`}
                              />
                            </>
                          )
                        }
                      },
                      {
                        id: "gdpr_ack",
                        label: "RODO",
                        cellRenderer: (item: Client) => {
                         return <>{item.gdpr_ack ? "TAK" : "NIE"}</>
                        }
                      },
                      {
                        id: "actions",
                        label: "",
                        cellRenderer: (item: Client) => {
                          return (
                            <>
                              <Tooltip title={"Usuń"}>
                                <IconButton style={{float: "right"}} onClick={() => setDeleting(item)}>
                                  <DeleteIcon />
                                </IconButton>
                              </Tooltip>
                              <Tooltip title={"Edytuj"}>
                                <IconButton style={{float: "right"}} onClick={() => setEditing(item)}>
                                  <EditIcon />
                                </IconButton>
                              </Tooltip>
                            </>
                          )
                        }
                      },
                    ]}
                    data={Object.values(props.clients || {})}
                  />
                </Grid>
              </Grid>
            </Paper>
          </div>
        </Grid>
      </Grid>
      {editDialog(editing)}
      {deleteDialog(deleting)}
    </>
  )
};


const mapStateToProps = (state: AppState) => ({
  session: state.auth.session,
  clients: state.clientList.clients,
  count: state.clientList.count,
  filters: state.filters.filters,
});


const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>) => ({
  fetchClients: (payload: {clients: Client[], count: number}) => dispatch(fetchClients(payload)),
  updateClient: (payload: Client) => dispatch(updateClient(payload)),
  deleteClient: (payload: string) => dispatch(deleteClient(payload)),
});


export default connect(mapStateToProps, mapDispatchToProps)(ClientListView)
