export class CompanyMember {
  id: string;
  role: string;  // role (admin | member | owner)
  user: {id: string, email: string, first_name: string, last_name: string};

  constructor(input: any) {
    this.id = input.id;
    this.role = input.role;
    this.user = input.user;
  }

  getName = () => {
    return `${this.user.first_name} ${this.user.last_name}` || this.user.email
  }
}