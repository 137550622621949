import FilterListIcon from "@material-ui/icons/FilterListOutlined";
import React from "react";
import SearchFilter from "../../components/Filters/SearchFilter";
import { AppState } from "../../store";
import { ThunkDispatch } from "redux-thunk";
import { connect } from "react-redux";
import {
  Breadcrumbs,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid, IconButton,
  Link,
  Paper, Toolbar,
  Tooltip,
  Typography
} from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import AddIcon from "@material-ui/icons/Add";
import TodoForm from "../../components/Todos/TodoForm";
import { Todo } from "../../models";
import { Session } from "../../store/Auth/types";
import { Filter, Filters } from "../../store/Filters/types";
import { Todos } from "../../store/Todos/types";
import { createTodo, fetchTodos } from "../../store/Todos/actions";
import { TodoSerivce } from "../../store/Todos/services";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import TodoListFilters from "../../components/Todos/TodoListFilters";
import TodoList from "../../components/Todos/TodoList";


type TodoListViewProps = {
  session: Session | undefined;
  todos: Todos | undefined,
  count: number | undefined;
  filters: Filters | undefined;
  fetchTodos: (payload: {todos: Todo[], count: number}) => void;
  createTodo: (payload: Todo) => void;
}


const useStyles = makeStyles(theme => ({
  filtersContainer: {
    float:"right",
    marginLeft: "auto",
    width: 300,
    padding: theme.spacing(2),
    backgroundColor: "#f2f2f2",
    zIndex: 1000,
    [theme.breakpoints.down('md')]: {
      width: "100%",
      top: 0,
    },
  },
  dataContainer: {
    width: `100%`,
    [theme.breakpoints.up('lg')]: {
      width: `calc(100% - 300px - ${theme.spacing(2)}px)`,
    },
  },
  dataContainerExtended: {
    width: `100%`,
  },
  hidden: {
    visibility: "hidden",
    overflow: "hidden",
    width: 0,
    height: 0,
    margin: 0,
    padding: 0,
  },
  toolbar: {
    padding: theme.spacing(2)
  },
  toolbarHeader: {
    flex: "1 1 100%",
    fontWeight: 400,
    marginRight: theme.spacing(4),
    maxWidth: "50%",
    minWidth: 200
  },
  toolbarTools: {
    marginLeft: "auto"
  }
}));


const TodoListView: React.FC<TodoListViewProps> = (props: TodoListViewProps) => {

  const classes = useStyles();
  const service = props.session ? new TodoSerivce(props.session.company.id) : undefined;
  const [addDialogOpen, setAddDialogOpen] = React.useState(false);
  const [filtersState, setFiltersState] = React.useState(props.filters);
  const [showFilters, setShowFilters] = React.useState(false);

  const {filters, fetchTodos} = props;

  const getTodos = () => {
    if (service && filters !== filtersState) {
      setFiltersState(filters);
      const filterParams: { [key: string]: any } = {};
      Object.values(filters || {}).forEach((filter: Filter) => {
        filterParams[filter.filterParam] = filter.value;
      });
      service.fetch(filterParams).then((payload) => {
        fetchTodos(payload)
      }).catch(() => {
        fetchTodos({todos: [], count: 0})
      })
    }
  };

  React.useEffect(() => {
    getTodos();
  });

  const addDialog = (): React.ReactNode => {
    return (
      <Dialog open={addDialogOpen} onClose={() => setAddDialogOpen(false)} disableBackdropClick>
        <DialogTitle>Dodaj zadanie</DialogTitle>
        <DialogContent dividers>
          <TodoForm
            onSuccess={(todo: Todo) => {
              props.createTodo(todo);
              setAddDialogOpen(false);
            }}
            onCancel={() => setAddDialogOpen(false)}
            />
        </DialogContent>
      </Dialog>
    )
  };

  return (
    <>
      <div className={"header"}>
        <Grid container>
          <Grid item className={"header-content"}>
            <Breadcrumbs>
              <Link component={RouterLink} to={"/dashboard/"}>Pulpit</Link>
              <Typography color={"textSecondary"}>Zadania</Typography>
            </Breadcrumbs>
          </Grid>
          <Grid item className={"header-actions"}>
            <Button
              variant={"contained"}
              color={"primary"}
              size={"small"}
              onClick={() => setAddDialogOpen(true)}
            >
              <AddIcon fontSize={"small"}/>
              Dodaj
            </Button>
          </Grid>
        </Grid>
        <Divider />
      </div>
      <Grid container className={"content"} spacing={2}>
        <Grid item xs={12}>
          <div className={clsx(showFilters && classes.filtersContainer, !showFilters && classes.hidden)}>
            <TodoListFilters />
          </div>
          <div className={clsx(showFilters ? classes.dataContainer : classes.dataContainerExtended)}>
            <Paper className={"paper-content"}>
              { props.filters !== undefined ? (
                <>
                  <Toolbar classes={{root: classes.toolbar}}>
                    <div className={classes.toolbarHeader} >
                      <SearchFilter key={"todo-search"}/>
                    </div>
                    <div className={classes.toolbarTools}>
                      <Tooltip title="Filtry">
                        <IconButton aria-label="Filtry" onClick={() => setShowFilters(!showFilters)}>
                          <FilterListIcon />
                        </IconButton>
                      </Tooltip>
                    </div>
                  </Toolbar>
                  <TodoList
                    todos={Object.values(props.todos || {})}
                    count={props.count || 0}
                  />
                </>
                ) : null }
            </Paper>
          </div>
        </Grid>
      </Grid>
      {addDialog()}
    </>
  )
};


const mapStateToProps = (state: AppState) => ({
  session: state.auth.session,
  todos: state.todoList.todos,
  count: state.todoList.count,
  filters: state.filters.filters,
});


const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>) => ({
  fetchTodos: (payload: {todos: Todo[], count: number}) => dispatch(fetchTodos(payload)),
  createTodo: (payload: Todo) => dispatch(createTodo(payload)),
});


export default connect(mapStateToProps, mapDispatchToProps)(TodoListView);
