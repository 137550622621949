import React from "react";
import { AppState } from "../../store";
import { ThunkDispatch } from "redux-thunk";
import { connect } from "react-redux";
import { Session } from "../../store/Auth/types";
import { Client, Insurance, Todo } from "../../models";
import { Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from "@material-ui/core";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { Moment } from "moment";
import { Autocomplete } from "@material-ui/lab";
import { CompanyMember } from "../../models/CompanyMember";
import { debounce } from "../../utils";
import { TodoSerivce } from "../../store/Todos/services";
import { NonFieldErrors } from "../Forms";
import { ClientService } from "../../store/Clients/services";
import { InsuranceService } from "../../store/Insurances/services";
import { makeStyles } from "@material-ui/core/styles";


type TodoFormProps = {
  session: Session | undefined,
  onSuccess: (todo: Todo) => void,
  onCancel: () => void,
  disabled?: boolean,
  instance?: Todo,
  delete?: boolean,
}


const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2)
  },
  selectLabel: {
    background: "#fff",
    padding: "0 5px"
  },
}));

const TodoForm: React.FC<TodoFormProps> = (props: TodoFormProps) => {

  const classes = useStyles();
  const service = props.session ? new TodoSerivce(props.session.company.id) : undefined;
  const clientService = props.session ? new ClientService(props.session.company.id) : undefined;
  const insuranceService = props.session ? new InsuranceService(props.session.company.id) : undefined;
  const [instance, ] = React.useState<Todo | undefined>(props.instance);
  const [errors, setErrors] = React.useState<{[key: string]: any} | undefined>(undefined);
  const [disabled, setDisabled] = React.useState<boolean>(Boolean(props.disabled));
  const [assigneeList, setAssigneeList] = React.useState<CompanyMember[] | undefined>(undefined);
  const [relatedClients, setRelatedClients] = React.useState<Client[] | null>(null);
  const [relatedInsurances, setRelatedInsurances] = React.useState<Insurance[] | null>(null);

  const getAssigneeList = (searchPhrase?: string) => {
    if (service) {
      const params: {[key: string]: any} = {};
      if (searchPhrase) {
        params.search = searchPhrase
      }
      service.getAssigneeList(params).then(assigneeList => {
        setAssigneeList(assigneeList);
      });
    }
  };

  const handleAssigneeInputChange = debounce((value: string) => {
    getAssigneeList(value)
  }, 1000);

  const handleRelatedObjectTypeChange = (value: "client" | "insurance" | "") => {
    setRelatedObjectType(value);
    if (value === "client") {
      setRelatedInsurance(null)
    }
    else if (value === "insurance") {
      setRelatedClient(null)
    }
    else {
      setRelatedClient(null);
      setRelatedInsurance(null);
    }
  };

  const getRelatedClients = (searchPhrase?: string) => {
    if (clientService) {
      const params: {[key: string]: any} = {};
      if (searchPhrase) {
        params.search = searchPhrase
      }
      clientService.fetch(params).then(result => {
        const {clients} = result;
        setRelatedClients(clients);
      });
    }
  };

  const handleRelatedClientInputChange = debounce((value: string) => {
    getRelatedClients(value)
  }, 1000);

  const getRelatedInsurances = (searchPhrase?: string) => {
    if (insuranceService) {
      const params: {[key: string]: any} = {};
      if (searchPhrase) {
        params.search = searchPhrase
      }
      insuranceService.fetch(params).then(result => {
        const {insurances} = result;
        setRelatedInsurances(insurances);
      });
    }
  };

  const handleRelatedInsuranceInputChange = debounce((value: string) => {
    getRelatedInsurances(value)
  }, 1000);

  const [title, setTitle] = React.useState<string>(
    instance
      ? instance.title
      : ""
  );
  const [content, setContent] = React.useState<string>(
    instance
      ? instance.content
      : ""
  );
  const [dueDate, setDueDate] = React.useState<Moment | null>(
    instance
      ? instance.due_date
      : null
  );
  const [assignee, setAssignee] = React.useState<CompanyMember | null>(
    instance
      ? instance.assignee
      : null
  );
  const [status, setStatus] = React.useState<string>(
    instance
      ? instance.status
      : "new"
  );
  const [relatedObjectType, setRelatedObjectType] = React.useState<"client" | "insurance" | "">(
    instance
      ? instance.related_obj.related_to
      : ""
  );
  const [relatedClient, setRelatedClient] = React.useState<Client | null>(
    instance && instance.related_obj.object as Client
      ? instance.related_obj.object as Client
      : null
  );
  const [relatedInsurance, setRelatedInsurance] = React.useState<Insurance | null>(
    instance && instance.related_obj.object as Insurance
      ? instance.related_obj.object as Insurance
      : null
  );

  const handleSubmit = (e: any) => {
    e.preventDefault();
    const payload: {[key: string]: any} = {
      title: title,
      content: content,
      due_date: dueDate ? dueDate.format("YYYY-MM-DD") : null,
      status: status,
      related_to: "",
      object_id: null,
    };
    if (assignee) {
      payload.assignee = assignee.id
    }
    if (relatedObjectType) {
      payload.related_to = relatedObjectType;
      if (relatedObjectType === "client") {
        payload.object_id = relatedClient?.id || null;
      }
      else if (relatedObjectType === "insurance") {
        payload.object_id = relatedInsurance?.id || null;
      }
      else payload.object_id = null
    }
    if (service) {
      if (instance === undefined || !Boolean(instance.id)) {
        service.create(payload).then(response => {
          const todo = new Todo(response);
          props.onSuccess(todo);
          setDisabled(true);
        }).catch(exception => {
          setErrors(exception.data);
        })
      }
      else {
        service.update(instance.id, payload).then(response => {
          const todo = new Todo(response);
          props.onSuccess(todo);
          setDisabled(true);
        }).catch(exception => {
          setErrors(exception.data);
        })
      }
    }
  };

  React.useEffect(() => {
    if (assigneeList === undefined) {
      getAssigneeList();
    }
  });

  return (
    <form autoComplete={"off"} className={classes.root}>
      {
        errors !== undefined && errors.non_field_errors
          ? (
            <NonFieldErrors errors={errors.non_field_errors}/>
          )
          : null
      }
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            required
            disabled={disabled}
            id={"title"}
            label={"Tytuł"}
            variant={"outlined"}
            value={title}
            onChange={(e: any) => setTitle(e.target.value)}
            inputProps={{maxLength: 100}}
            error={errors && Boolean(errors.title)}
            helperText={errors && errors.title ? errors.title : null}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            multiline
            disabled={disabled}
            rowsMax={4}
            rows={4}
            id={"content"}
            label={"Opis"}
            variant={"outlined"}
            value={content}
            onChange={(e: any) => setContent(e.target.value)}
            inputProps={{maxLength: 100}}
            error={errors && Boolean(errors.content)}
            helperText={errors && errors.content ? errors.content : null}
          />
        </Grid>
        <Grid item xs={12}>
          <Autocomplete
            disabled={disabled}
            value={assignee}
            options={(assigneeList || [])}
            onChange={(e: any, val: CompanyMember | null) => setAssignee(val)}
            getOptionLabel={(assignee: CompanyMember) => `${assignee.user.first_name} ${assignee.user.last_name} (${assignee.user.email})`}
            getOptionSelected={(assignee, value) => {return assignee.id === value.id}}
            renderInput={
              params => {
                return (
                  <TextField
                    {...params}
                    fullWidth
                    variant={"outlined"}
                    label={"Osoba odpowiedzialna"}
                    onChange={(e: any) => handleAssigneeInputChange(e.target.value)}
                    InputProps={{
                      ...params.InputProps,
                    }}
                    error={errors && Boolean(errors.assignee)}
                    helperText={errors && errors.assignee ? errors.assignee : null}
                  />
                )
              }
            }
          />
        </Grid>
        <Grid item xs={12}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <DatePicker
              fullWidth
              required
              disabled={disabled}
              variant={"inline"}
              inputVariant={"outlined"}
              format={"yyyy-MM-DD"}
              id={"due-date"}
              label={"Termin"}
              value={dueDate}
              onChange={setDueDate}
              error={errors && Boolean(errors.due_date)}
              helperText={errors && errors.due_date ? errors.due_date : null}
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item xs={12}>
          <FormControl
            fullWidth
            disabled={disabled}
            variant={"outlined"}
          >
            <InputLabel id="status-label" className={classes.selectLabel}>Status</InputLabel>
            <Select
              labelId="status-label"
              id="status-select"
              value={status}
              onChange={(e: any) => setStatus(e.target.value)}
            >
              <MenuItem value={"new"}>Nowe</MenuItem>
              <MenuItem value={"in_progress"}>W toku</MenuItem>
              <MenuItem value={"finished"}>Ukończone</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl
            fullWidth
            disabled={disabled}
            variant={"outlined"}
          >
            <InputLabel id="relatedObjectType-label" className={classes.selectLabel}>Powiąż z</InputLabel>
            <Select
              labelId="relatedObjectType-label"
              id="relatedObjectType-select"
              value={relatedObjectType}
              onChange={(e: any) => handleRelatedObjectTypeChange(e.target.value)}
            >
              <MenuItem value={""}>
                <em>Wybierz obiekt powiązany</em>
              </MenuItem>
              <MenuItem value={"client"}>Klient</MenuItem>
              <MenuItem value={"insurance"}>Polisa</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        {
          Boolean(relatedObjectType) ? (
            <Grid item xs={12}>
              { relatedObjectType === "client" ? (
                <Autocomplete
                  disabled={disabled}
                  value={relatedClient}
                  options={(relatedClients || [])}
                  onChange={(e: any, val: Client | null) => setRelatedClient(val)}
                  getOptionLabel={(o: Client) => `${o.full_name} (${o.id_number})`}
                  getOptionSelected={(o, value) => {return o.id === value.id}}
                  renderInput={
                    params => {
                      return (
                        <TextField
                          {...params}
                          fullWidth
                          variant={"outlined"}
                          label={"Klient"}
                          onChange={(e: any) => handleRelatedClientInputChange(e.target.value)}
                          error={errors && Boolean(errors.object_id)}
                          helperText={errors && errors.object_id ? errors.object_id : null}
                          InputProps={{
                            ...params.InputProps,
                          }}
                        />
                      )
                    }
                  }
                />
              ) : (
                <Autocomplete
                  disabled={disabled}
                  value={relatedInsurance}
                  options={(relatedInsurances || [])}
                  onChange={(e: any, val: Insurance | null) => setRelatedInsurance(val)}
                  getOptionLabel={(o: Insurance) => `${o.number} (${o.insurer})`}
                  getOptionSelected={(o, value) => {return o.id === value.id}}
                  renderInput={
                    params => {
                      return (
                        <TextField
                          {...params}
                          fullWidth
                          variant={"outlined"}
                          label={"Polisa"}
                          onChange={(e: any) => handleRelatedInsuranceInputChange(e.target.value)}
                          error={errors && Boolean(errors.object_id)}
                          helperText={errors && errors.object_id ? errors.object_id : null}
                          InputProps={{
                            ...params.InputProps,
                          }}
                        />
                      )
                    }
                  }
                />
              )}
            </Grid>
          ) : null
        }
        <Grid item xs={12} className={"form-actions"}>
          {
            disabled && instance
              ? (
                <Button color={"primary"} variant={"contained"} onClick={() => setDisabled(false)}>
                  Edytuj
                </Button>
              )
              : (
                <>
                  <Button color={"primary"} variant={"contained"} onClick={handleSubmit}>
                    Zapisz
                  </Button>
                  <Button color={"default"} variant={"contained"} onClick={
                    () => {
                      props.onCancel();
                      if (props.disabled === true) {
                        // in case then disabled was initialised
                        setDisabled(true);
                      }
                    }
                  }>
                    Anuluj
                  </Button>
                </>
              )
          }
        </Grid>
      </Grid>
    </form>
  )
};


const mapStateToProps = (state: AppState) => ({
  session: state.auth.session
});


const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>) => ({});


export default connect(mapStateToProps, mapDispatchToProps)(TodoForm);
