import {
  CompanyMembers, MEMBER_DELETED,
  MEMBER_UPDATED,
  MemberListAction,
  MemberListState,
  MEMBERS_FETCHED
} from "./types";


export const memberListReducer = (
  state: MemberListState = {members: undefined},
  action: MemberListAction): MemberListState => {

  let members: CompanyMembers = state.members ? {...state.members} : {};

  switch (action.type) {
    case MEMBERS_FETCHED:
      action.payload.forEach(member => {
        members[member.id] = member
      });
      return {
        ...state,
        members: {...members}
      };

    case MEMBER_UPDATED:
      members[action.payload.id] = action.payload;
      return {
        ...state,
        members: {...members}
      };

    case MEMBER_DELETED:
      delete members[action.payload];
      return {
        ...state,
        members: {...members}
      };

    default:
      return state;
  }

};