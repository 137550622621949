import React from "react";
import { AppState } from "../../store";
import { connect } from "react-redux";
import { InputAdornment } from "@material-ui/core";
import { Filter, Filters } from "../../store/Filters/types";
import { ThunkDispatch } from "redux-thunk";
import { applyFilter } from "../../store/Filters/actions";
import TextFilter from "./TextFilter";
import SearchIcon from '@material-ui/icons/Search';


type SearchFilterProps = {
  location: any,
  filters: Filters | undefined,
  applyFilter: (payload: Filter) => void,
}


const SearchFilter: React.FC<SearchFilterProps> = (props: SearchFilterProps) => {

  const [value, setValue] = React.useState<string | undefined>(undefined);

  React.useEffect(() => {
    setValue(props.location.query?.search || "")
  }, [props.location]);

  return value !== undefined ? (
    <TextFilter
      id={"search"}
      label={"Szukaj"}
      queryParam={"search"}
      filterParam={"search"}
      value={value}
      onChange={(filter: Filter) => {
        props.applyFilter(filter)
      }}
      inputProps={{
        endAdornment: <InputAdornment position="end"><SearchIcon/></InputAdornment>
      }}
    />
  ) : null;
};


const mapStateToProps = (state: AppState) => ({
  location: state.router.location,
  filters: state.filters.filters
});


const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>) => ({
  applyFilter: (payload: Filter) => dispatch(applyFilter(payload)),
});


export default connect(mapStateToProps, mapDispatchToProps)(SearchFilter);
