export const USER_LOGGED_IN = "USER_LOGGED_IN";
export const USER_LOGGED_OUT = "USER_LOGGED_OUT";
export const USER_UPDATED = "USER_UPDATED";


export type User = {
  id: string,
  email: string,
  firstName: string,
  lastName: string
}


export type CompanyMembership = {
  id: string,  // company ID
  member_id: string,
  name: string, // company Name
  role: string  // role (admin | member | owner)
}


export type JWTPayload = {
  token: string,
  user: User,
  companies: CompanyMembership[]
}


export type Session = {
  token: string,
  user: User,
  company: CompanyMembership
}


export type UserLoginAction = {
  type: typeof USER_LOGGED_IN,
  payload: Session
}


export type UserLogoutAction = {
  type: typeof USER_LOGGED_OUT,
}


export type UserUpdateAction = {
  type: typeof USER_UPDATED,
  payload: {firstName: string, lastName: string}
}


export type AuthState = {
  session: Session | undefined,
}


export type AuthAction = (
  UserLoginAction | UserLogoutAction | UserUpdateAction
)
