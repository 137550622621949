const debounce = (callback: (...args: any[]) => any, wait: number) => {
  /**
   * const func = debounce((value: any) => void)
   * onChange={(event: ChangeEvent<HTMLInputElement>) => func(event.target.value)}
   */
  let timeout: any = null;
  return (...args: any[]): any => {
    const next = () => callback(...args);
    clearTimeout(timeout);
    timeout = setTimeout(next, wait);
  };
};

export default debounce;