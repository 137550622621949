import {
  Button, Card, CardContent, CardHeader, Checkbox, Dialog, DialogContent, DialogTitle,
  FormControl, FormControlLabel, FormHelperText,
  Grid, IconButton,
  InputLabel, MenuItem, Select,
  TextField
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Autocomplete } from "@material-ui/lab";
import {
  DatePicker,
  MuiPickersUtilsProvider
} from "@material-ui/pickers";
import ChipInput from "material-ui-chip-input";
import { Moment } from "moment";
import React from "react";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import {
  Client,
  Insurance,
  InsurancePremium
} from "../../models";
import InsuranceCommission from "../../models/InsuranceCommission";
import { AppState } from "../../store";
import { Session } from "../../store/Auth/types";
import { ClientService } from "../../store/Clients/services";
import { debounce } from "../../utils";
import ClientForm from "../Clients/ClientForm";
import { NonFieldErrors } from "../Forms";
import MomentUtils from "@date-io/moment";
import { createClient } from "../../store/Clients/actions";
import AddIcon from "@material-ui/icons/AddOutlined";
import RemoveIcon from '@material-ui/icons/RemoveOutlined';
import { InsuranceService } from "../../store/Insurances/services";


type InsuranceFormProps = {
  session: Session | undefined;
  onSuccess: (insurance: Insurance, continueEditing?: boolean) => void;
  onCancel: () => void;
  disabled?: boolean;
  instance?: Insurance;
  delete?: boolean;
  createClient: (payload: Client) => void;
}


const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2)
  },
  selectLabel: {
    background: "#fff",
    padding: "0 5px"
  },
  clientAddButton: {
    padding: 4
  },
  formsetAddButton: {
    marginLeft: "auto"
  },
  fieldset: {
    marginBottom: theme.spacing(1),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    "&:nth-child(odd)": {
      backgroundColor: "#fafafa",
      borderRadius: theme.spacing(0.5)
    }
  }
}));


const InsuranceForm: React.FC<InsuranceFormProps> = (props: InsuranceFormProps) => {

  const classes = useStyles();
  const service = props.session ? new InsuranceService(props.session.company.id) : undefined;
  const clientService = props.session !== undefined ? new ClientService(props.session.company.id) : undefined;
  const [errors, setErrors] = React.useState<{[key: string]: any} | undefined>(undefined);
  const [instance, ] = React.useState<Insurance | undefined>(props.instance);
  const [disabled, setDisabled] = React.useState(Boolean(props.disabled));
  const [clients, setClients] = React.useState<Client[] | undefined>(undefined);
  const [clientAddDialogOpen, setClientAddDialogOpen]= React.useState(false);

  const getClients = (searchPhrase?: string) => {
    if (clientService) {
      const params: {[key: string]: any} = {
        limit: 100
      };
      if (searchPhrase) {
        params.search = searchPhrase
      }
      clientService.fetch(params).then(({clients, count}) => {
        setClients(clients);
      });
    }
  };

  const handleClientInputChange = debounce((value: string) => {
    getClients(value)
  }, 1000);

  const handlePremiumFormAdd = () => {
    const lastPremium: InsurancePremium = {...premiums[premiums.length]};
    setPremiums([...premiums, new InsurancePremium(lastPremium)]);
  };

  const handlePremiumFormRemove = (idx: number) => {
    setPremiums([...premiums.slice(0, idx), ...premiums.slice(idx + 1, premiums.length)]);
  };

  const handlePremiumChange = (idx: number, field: string, value: any) => {
    const premium: InsurancePremium = premiums[idx];
    const updated: {[key: string]: string} = {};
    updated[field] = value;
    setPremiums([...premiums.slice(0, idx),
        {...premium, ...updated},
        ...premiums.slice(idx + 1, premiums.length)]
    );
  };

  const handleCommissionFormAdd = () => {
    const lastCommission: InsuranceCommission = {...commissions[commissions.length]};
    setCommissions([...commissions, new InsuranceCommission(lastCommission)]);
  };

  const handleCommissionFormRemove = (idx: number) => {
    setCommissions([...commissions.slice(0, idx), ...commissions.slice(idx + 1, commissions.length)]);
  };

  const handleCommissionChange = (idx: number, field: string, value: any) => {
    const commission: InsuranceCommission = commissions[idx];
    const updated: {[key: string]: string} = {};
    updated[field] = value;
    setCommissions([...commissions.slice(0, idx),
      {...commission, ...updated},
      ...commissions.slice(idx + 1, commissions.length)]
    );
  };

  // get initial list of clients
  React.useEffect(() => {
    if (clients === undefined) {
      getClients();
    }
  });

  // form state
  const [insurer, setInsurer] = React.useState<string>(
    instance
      ? instance.insurer
      : ""
  );
  const [number, setNumber] = React.useState<string>(
    instance
      ? instance.number
      : ""
  );
  const [agreementDate, setAgreementDate] = React.useState<Moment | null>(
    instance
      ? instance.agreement_date
      : null
  );
  const [startDate, setStartDate] = React.useState<Moment | null>(
    instance
      ? instance.start_date
      : null
  );
  const [endDate, setEndDate] = React.useState<Moment | null>(
    instance
      ? instance.end_date
      : null
  );
  const [insuranceType, setInsuranceType] = React.useState<string>(
    instance
      ? instance.insurance_type
      : ""
  );
  const [insuranceVariants, setInsuranceVariants] = React.useState<string[]>(
    instance
      ? instance.insurance_variants
      : []
  );
  const [comment, setComment] = React.useState<string>(
    instance
      ? instance.comment
      : ""
  );
  const [client, setClient] = React.useState<Client | null>(
    instance
      ? instance.client
      : null
  );
  const [status, setStatus] = React.useState<string>(
    instance
      ? instance.status
      : "active"
  );
  const [terminationDate, setTerminationDate] = React.useState<Moment | null>(
    instance
      ? instance.termination_date
      : null
  );
  const [premiums, setPremiums] = React.useState<InsurancePremium[]>(
    instance
      ? instance.premiums
      : []
  );
  const [commissions, setCommissions] = React.useState<InsuranceCommission[]>(
    instance
      ? instance.commissions
      : []
  );

  const getPremiumsPayload = (premiums: InsurancePremium[]) => {
    return premiums.map((premium) => {
      const payload: {[key: string]: any} = {send_notification: premium.send_notification};
      if (premium.id) {
        payload.id = premium.id
      }
      if (premium.amount) {
        payload.amount = premium.amount
      }
      if (premium.due_date) {
        payload.due_date = premium.due_date.format("YYYY-MM-DD")
      }
      return payload;
    })
  };

  const getCommissionsPayload = (commissions: InsuranceCommission[]) => {
    return commissions.map((commission) => {
      const payload: {[key: string]: any} = {};
      if (commission.id) {
        payload.id = commission.id
      }
      if (commission.amount) {
        payload.amount = commission.amount
      }
      if (commission.payment_date) {
        payload.payment_date = commission.payment_date.format("YYYY-MM-DD")
      }
      return payload;
    })
  };

  const handleSubmit = (e: any, continueEditing: boolean = false) => {
    e.preventDefault();
    const payload: {[key: string]: any} = {
      insurer: insurer,
      number: number,
      agreement_date: agreementDate ? agreementDate.format("YYYY-MM-DD") : null,
      start_date: startDate ? startDate.format("YYYY-MM-DD") : null,
      end_date: endDate ? endDate.format("YYYY-MM-DD") : null,
      insurance_type: insuranceType,
      insurance_variants: insuranceVariants,
      comment: comment,
      status: status,
      termination_date: status === "terminated" && terminationDate !== null ? terminationDate.format("YYYY-MM-DD") : null,
      premiums: getPremiumsPayload(premiums),
      commissions: getCommissionsPayload(commissions),
    };
    if (client) {
      payload.client = client.id
    }
    if (service) {
      if (instance === undefined) {
        service.create(payload).then(response => {
          const insurance = new Insurance(response);
          props.onSuccess(insurance, continueEditing);
          setDisabled(true);
        }).catch(exception => {
          setErrors(exception.data);
        });
      }
      else {
        service.update(instance.id, payload).then(response => {
          const insurance = new Insurance(response);
          props.onSuccess(insurance);
          setDisabled(true);
        }).catch(exception => {
          setErrors(exception.data);
        });
      }
    }

  };

  const clientAddDialog = (): React.ReactNode => {
    return (
      <Dialog open={clientAddDialogOpen} onClose={() => setClientAddDialogOpen(false)} disableBackdropClick>
        <DialogTitle>Dodaj klienta</DialogTitle>
        <DialogContent dividers>
          <ClientForm
            onSuccess={(client: Client) => {
              setClients(clients ? [client, ...clients] : [client]);
              setClient(client);
              setClientAddDialogOpen(false);
              props.createClient(client)
            }}
            onCancel={() => setClientAddDialogOpen(false)}
          />
        </DialogContent>
      </Dialog>
    )
  };

  const handleDelete = () => {
    if (service && instance && props.delete) {
      service.delete(instance.id).then(() => {
        props.onSuccess(instance);
      });
    }
  };

  if (instance && props.delete) {
    return (
      <div className={"form-actions"}>
        <Button color={"secondary"} variant={"contained"} onClick={handleDelete}>
          Usuń
        </Button>
        <Button color={"default"} variant={"contained"} onClick={
          () => {
            props.onCancel();
          }
        }>
          Anuluj
        </Button>
      </div>
    )
  }

  return (
    <>
      <form autoComplete={"off"} className={classes.root}>
        {
          errors !== undefined && errors.non_field_errors
            ? (
              <NonFieldErrors errors={errors.non_field_errors}/>
            )
            : null
        }
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={2}>
            <TextField
              required
              fullWidth
              disabled={disabled}
              variant={"outlined"}
              value={insurer}
              label={`Ubezpieczyciel`}
              onChange={(e: any) => setInsurer(e.target.value)}
              error={errors && Boolean(errors.insurer)}
              helperText={errors && errors.insurer ? errors.insurer : null}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <TextField
              required
              fullWidth
              disabled={disabled}
              variant={"outlined"}
              value={number}
              label={`Nr ubezpieczenia`}
              onChange={(e: any) => setNumber(e.target.value)}
              error={errors && Boolean(errors.number)}
              helperText={errors && errors.number ? errors.number : null}
            />
          </Grid>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <Grid item xs={4} lg={2}>
              <DatePicker
                fullWidth
                disabled={disabled}
                variant={"inline"}
                inputVariant={"outlined"}
                format={"yyyy-MM-DD"}
                id={"agreement-date"}
                label={"Data zawarcia umowy"}
                value={agreementDate}
                onChange={setAgreementDate}
                error={errors && Boolean(errors.agreement_date)}
                helperText={errors && errors.agreement_date ? errors.agreement_date : null}
              />
            </Grid>
            <Grid item xs={4} lg={2}>
              <DatePicker
                fullWidth
                disabled={disabled}
                variant={"inline"}
                inputVariant={"outlined"}
                format={"yyyy-MM-DD"}
                id={"start-date"}
                label={"Początek ubezpieczenia"}
                value={startDate}
                onChange={setStartDate}
              />
            </Grid>
            <Grid item xs={4} lg={2}>
              <DatePicker
                fullWidth
                disabled={disabled}
                variant={"inline"}
                inputVariant={"outlined"}
                format={"yyyy-MM-DD"}
                id={"end-date"}
                label={"Koniec ubezpieczenia"}
                value={endDate}
                onChange={setEndDate}
              />
            </Grid>
          </MuiPickersUtilsProvider>
          <Grid item xs={12} lg={2}>
            <FormControl
              required
              fullWidth
              disabled={disabled}
              variant={"outlined"}
              error={errors && Boolean(errors.insurance_type)}
            >
              <InputLabel id="insuranceType-label" className={classes.selectLabel}>Typ</InputLabel>
              <Select
                labelId="insuranceType-label"
                id="insuranceType-select"
                value={insuranceType}
                onChange={(e: any) => setInsuranceType(e.target.value)}
              >
                <MenuItem value={""}>
                  <em>Wybierz typ ubezpieczenia</em>
                </MenuItem>
                <MenuItem value={"life"}>Na życie</MenuItem>
                <MenuItem value={"vehicle"}>Komunikacyjne</MenuItem>
                <MenuItem value={"real_estate"}>Nieruchomości</MenuItem>
                <MenuItem value={"travel"}>Turystyczne</MenuItem>
                <MenuItem value={"farm"}>Gospodarstwo rolne</MenuItem>
                <MenuItem value={"crops"}>Uprawy rolne</MenuItem>
              </Select>
              {
                errors && errors.insurance_type
                  ? <FormHelperText>{ errors.insurance_type }</FormHelperText>
                  : null
              }
            </FormControl>
          </Grid>
          <Grid item xs={12} lg={6}>
            <ChipInput
              fullWidth
              disabled={disabled}
              variant={"outlined"}
              value={insuranceVariants}
              label={"Warianty ubezpieczenia"}
              onAdd={(variant) => setInsuranceVariants([...insuranceVariants, variant])}
              onDelete={
                (variant, index) => setInsuranceVariants(
                  [...insuranceVariants.slice(0, index), ...insuranceVariants.slice(index+1, insuranceVariants.length)]
                )
              }
              error={errors && Boolean(errors.insurance_variants)}
              helperText={
                errors && errors.insurance_variants
                  ? Object.values(errors.phone_numbers)
                  : "Np. AC, OC, NW, wpisz wariant i wciśnij enter."
              }
            />
          </Grid>
          <Grid item xs={6} lg={2}>
            <FormControl
              required
              fullWidth
              disabled={disabled}
              variant={"outlined"}
              error={errors && Boolean(errors.status)}
            >
              <InputLabel id="status-label" className={classes.selectLabel}>Status polisy</InputLabel>
              <Select
                labelId="status-label"
                id="status-select"
                value={status}
                onChange={(e: any) => setStatus(e.target.value)}
              >
                <MenuItem value={"active"}>Aktywna</MenuItem>
                <MenuItem value={"terminated"}>Przerwana</MenuItem>
              </Select>
              {
                errors && errors.status
                  ? <FormHelperText>{ errors.status }</FormHelperText>
                  : null
              }
            </FormControl>
          </Grid>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <Grid item xs={6} lg={2}>
              <DatePicker
                fullWidth
                disabled={disabled || status !== "terminated"}
                variant={"inline"}
                inputVariant={"outlined"}
                format={"yyyy-MM-DD"}
                id={"termination-date"}
                label={"Data przerwania umowy"}
                value={terminationDate}
                onChange={setTerminationDate}
                error={errors && Boolean(errors.termination_date)}
                helperText={errors && errors.termination_date ? errors.termination_date : null}
              />
            </Grid>
          </MuiPickersUtilsProvider>
          <Grid item xs={12}>
            <TextField
              fullWidth
              multiline
              rows={4}
              disabled={disabled}
              variant={"outlined"}
              value={comment}
              label={`Komentarz`}
              onChange={(e: any) => setComment(e.target.value)}
              error={errors && Boolean(errors.comment)}
              helperText={errors && errors.comment ? errors.comment : null}
            />
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              disabled={disabled}
              value={client}
              options={(clients || [])}
              onChange={(e: any, val: Client | null) => setClient(val)}
              getOptionLabel={(client: Client) => `${client.full_name} ${client.id_type}: ${client.id_number}`}
              getOptionSelected={(client, value) => {return client.id === value.id}}
              renderInput={
                params => {
                  return (
                    <TextField
                      {...params}
                      required
                      fullWidth
                      variant={"outlined"}
                      label={"Klient"}
                      error={errors && Boolean(errors.client)}
                      helperText={errors && errors.client ? errors.client : null}
                      onChange={(e: any) => handleClientInputChange(e.target.value)}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            <IconButton
                              onClick={() => setClientAddDialogOpen(true)}
                              className={classes.clientAddButton}
                              disabled={disabled}
                            >
                              <AddIcon fontSize={"small"}/>
                            </IconButton>
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )
                }
              }
            />
          </Grid>
          <Grid item xs={12}>
            <Card variant={"outlined"}>
              <CardHeader
                title={"Składki"}
                subheader={"Dodaj jedną lub wiele składek klikając w przycisk +."}
              />
              <CardContent>
                {
                  premiums.map((premium: InsurancePremium) => {
                    const idx = premiums.indexOf(premium);
                    return (
                      <Grid container spacing={3} className={classes.fieldset} key={`premium-${idx}`}>
                        <Grid item xs={1}>
                          <IconButton onClick={() => handlePremiumFormRemove(idx)} disabled={disabled}>
                            <RemoveIcon />
                          </IconButton>
                        </Grid>
                        <Grid item xs={11}>
                          <Grid container spacing={3}>
                            <Grid item xs={12} md={4}>
                              <MuiPickersUtilsProvider utils={MomentUtils}>
                                <DatePicker
                                  required
                                  fullWidth
                                  disabled={disabled}
                                  variant={"inline"}
                                  inputVariant={"outlined"}
                                  format={"yyyy-MM-DD"}
                                  id={"due-date"}
                                  label={"Termin płatności"}
                                  value={premium.due_date}
                                  onChange={(val: Moment | null) => handlePremiumChange(idx, "due_date", val)}
                                  error={
                                    errors && Boolean(errors.premiums) && Boolean(errors.premiums[idx]) && Boolean(errors.premiums[idx].due_date)
                                  }
                                  helperText={
                                    errors && Boolean(errors.premiums) && Boolean(errors.premiums[idx]) && Boolean(errors.premiums[idx].due_date)
                                      ? errors.premiums[idx].due_date
                                      : null
                                  }
                                />
                              </MuiPickersUtilsProvider>
                            </Grid>
                            <Grid item xs={12} md={4}>
                              <TextField
                                required
                                fullWidth
                                disabled={disabled}
                                variant={"outlined"}
                                value={premium.amount || ""}
                                label={"Kwota"}
                                onChange={(e: any) => handlePremiumChange(idx, "amount", e.target.value)}
                                error={
                                  errors && Boolean(errors.premiums) && Boolean(errors.premiums[idx]) && Boolean(errors.premiums[idx].amount)
                                }
                                helperText={
                                  errors && Boolean(errors.premiums) && Boolean(errors.premiums[idx]) && Boolean(errors.premiums[idx].amount)
                                    ? errors.premiums[idx].amount
                                    : null
                                }
                              />
                            </Grid>
                            <Grid item xs={12} md={4}>
                              <FormControlLabel
                                value={premium.send_notification}
                                control={
                                  <Checkbox
                                    disabled={disabled}
                                    color="primary"
                                    onChange={(e: any) => premium.send_notification = e.target.checked}
                                  />}
                                label="Wyślij powiadomienie"
                                labelPlacement="end"
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    )
                  })
                }
                <IconButton className={classes.formsetAddButton} onClick={handlePremiumFormAdd} disabled={disabled}>
                  <AddIcon />
                </IconButton>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card variant={"outlined"}>
              <CardHeader
                title={"Prowizje"}
                subheader={"Dodaj jedną lub wiele prowizji klikając w przycisk +."}
              />
              <CardContent>
                {
                  commissions.map((commission: InsuranceCommission) => {
                    const idx = commissions.indexOf(commission);
                    return (
                      <Grid container spacing={3} className={classes.fieldset} key={`commission-${idx}`}>
                        <Grid item xs={1}>
                          <IconButton onClick={() => handleCommissionFormRemove(idx)} disabled={disabled}>
                            <RemoveIcon />
                          </IconButton>
                        </Grid>
                        <Grid item xs={11}>
                          <Grid container spacing={3}>
                            <Grid item xs={12} md={4}>
                              <MuiPickersUtilsProvider utils={MomentUtils}>
                                <DatePicker
                                  required
                                  fullWidth
                                  disabled={disabled}
                                  variant={"inline"}
                                  inputVariant={"outlined"}
                                  format={"yyyy-MM-DD"}
                                  id={"payment-date"}
                                  label={"Data zapłaty"}
                                  value={commission.payment_date}
                                  onChange={(val: Moment | null) => handleCommissionChange(idx, "payment_date", val)}
                                  error={
                                    errors && Boolean(errors.commissions) && Boolean(errors.commissions[idx]) && Boolean(errors.commissions[idx].payment_date)
                                  }
                                  helperText={
                                    errors && Boolean(errors.commissions) && Boolean(errors.commissions[idx]) && Boolean(errors.commissions[idx].payment_date)
                                      ? errors.commissions[idx].payment_date
                                      : null
                                  }
                                />
                              </MuiPickersUtilsProvider>
                            </Grid>
                            <Grid item xs={12} md={4}>
                              <TextField
                                required
                                fullWidth
                                disabled={disabled}
                                variant={"outlined"}
                                value={commission.amount || ""}
                                label={"Kwota"}
                                onChange={(e: any) => handleCommissionChange(idx, "amount", e.target.value)}
                                error={
                                  errors && Boolean(errors.commissions) && Boolean(errors.commissions[idx]) && Boolean(errors.commissions[idx].amount)
                                }
                                helperText={
                                  errors && Boolean(errors.commissions) && Boolean(errors.commissions[idx]) && Boolean(errors.commissions[idx].amount)
                                    ? errors.commissions[idx].amount
                                    : null
                                }
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    )
                  })
                }
                <IconButton className={classes.formsetAddButton} onClick={handleCommissionFormAdd} disabled={disabled}>
                  <AddIcon />
                </IconButton>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} className={"form-actions"}>
            {
              disabled && instance
                ? (
                  <Button color={"primary"} variant={"contained"} onClick={() => setDisabled(false)}>
                    Edytuj
                  </Button>
                )
                : (
                  <>
                    <Button color={"primary"} variant={"contained"} onClick={handleSubmit}>
                      Zapisz
                    </Button>
                    {
                      instance === undefined ? (
                        <Button color={"primary"} variant={"contained"} onClick={(e: any) => handleSubmit(e, true)}>
                          Zapisz i kontynuuj edycję
                        </Button>
                      ) : null
                    }
                    <Button color={"default"} variant={"contained"} onClick={
                      () => {
                        props.onCancel();
                        if (props.disabled === true) {
                          // in case then disabled was initialised
                          setDisabled(true);
                        }
                      }
                    }>
                      Anuluj
                    </Button>
                  </>
                )
            }
          </Grid>
        </Grid>
      </form>
      {clientAddDialog()}
    </>
  )
};


const mapStateToProps = (state: AppState) => ({
  session: state.auth.session
});


const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>) => ({
  createClient: (payload: Client) => dispatch(createClient(payload))
});


export default connect(mapStateToProps, mapDispatchToProps)(InsuranceForm);