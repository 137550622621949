import React from "react";
import { Session } from "../../store/Auth/types";
import { AppState } from "../../store";
import { connect } from "react-redux";
import { Breadcrumbs, Divider, Grid, Link, Paper, Typography } from "@material-ui/core";
import { Link as RouterLink, Redirect } from "react-router-dom";
import ClientForm from "../../components/Clients/ClientForm";
import { Client } from "../../models";
import { ThunkDispatch } from "redux-thunk";
import { createClient } from "../../store/Clients/actions";

type ClientCreateViewProps = {
  session: Session | undefined,
  createClient: (payload: Client) => void,
}

const ClientCreateView: React.FC<ClientCreateViewProps> = (props: ClientCreateViewProps) => {

  const [createdOrCancelled, setCreatedOrCancelled] = React.useState(false);

  return !createdOrCancelled ? (
    <>
      <div className={"header"}>
        <Grid container>
          <Grid item className={"header-content"}>
            <Breadcrumbs>
              <Link component={RouterLink} to={"/dashboard/"}>Pulpit</Link>
              <Link component={RouterLink} to={"/dashboard/clients/"}>Klienci</Link>
              <Typography color={"textSecondary"}>Dodaj klienta</Typography>
            </Breadcrumbs>
          </Grid>
        </Grid>
        <Divider />
      </div>
      <Grid container className={"content"}>
        <Grid item xs={12}>
          <Paper className={"paper-content"}>
            <Grid container>
              <Grid item xs={12}>
                <ClientForm onSuccess={(client: Client) => {
                  props.createClient(client);
                  setCreatedOrCancelled(true);
                }} onCancel={() => setCreatedOrCancelled(true)}/>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </>
  ) : <Redirect to={"/dashboard/clients/"} />
};


const mapStateToProps = (state: AppState) => ({
  session: state.auth.session,
});


const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>) => ({
  createClient: (payload: Client) => dispatch(createClient(payload)),
});


export default connect(mapStateToProps, mapDispatchToProps)(ClientCreateView);