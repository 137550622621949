import React from "react";
import { IFilter } from "../../interfaces/Filters";
import { AppState, history } from "../../store";
import { connect } from "react-redux";
import { MenuItem } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useDebouncedCallback } from "use-debounce";
import { getQueryString } from "./utils";


type SingleOptionFilterProps = {
  location: any,
  options: {label: string, value: any}[],
} & IFilter


const useStyles = makeStyles(theme => ({
  fieldset: {
    borderWidth: 1,
    borderRadius: 4,
    borderColor: "rgba(0, 0, 0, 0.23)",
    padding: theme.spacing(1.5),
    fontSize: "1rem"
  },
  label: {
    color: theme.palette.text.secondary,
  },
  optionList: {
    margin: 0,
    padding: `${theme.spacing(2)}px 0 0}`
  }
}));

const SingleOptionFilter: React.FC<SingleOptionFilterProps> = (props: SingleOptionFilterProps) => {
  const classes = useStyles();
  const [value, setValue] = React.useState<string>(props.options.some(o => o.value === props.value) ? props.value : "");

  const handleChange = useDebouncedCallback(
    (value: string) => {
      const search = getQueryString(value, props.queryParam, props.location);
      history.push({
        pathname: props.location.path,
        search: search
      });
      props.onChange({
        id: props.id,
        queryParam: props.queryParam,
        filterParam: props.filterParam,
        value: value
      });
    }, 500
  );

  return (
    <fieldset className={classes.fieldset}>
      <label className={classes.label}>{props.label}</label>
      <ul className={classes.optionList}>
      {props.options.map(option =>
        <MenuItem
          key={option.value}
          value={option.value}
          selected={value === option.value}
          onClick={() => {
            handleChange.callback(option.value);
            setValue(option.value);
          }}
        >
          {option.label}
        </MenuItem>
      )}
      </ul>
    </fieldset>
  )
};


const mapStateToProps = (state: AppState) => ({
  location: state.router.location
});


export default connect(mapStateToProps)(SingleOptionFilter);
