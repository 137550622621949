import React from "react";
import { Typography } from "@material-ui/core";
import { connect } from "react-redux";
import { AppState } from "../../store";
import { Session } from "../../store/Auth/types";

type BrandProps = {
  session: Session | undefined;
}


const Brand: React.FC<BrandProps> = (props: BrandProps) => {
  return (
    <Typography variant={"h5"} style={{marginRight: 24}}>
      {
        props.session
          ? props.session.company.name
          : ""
      }
    </Typography>
  );
};

const mapStateToProps = (state: AppState) => ({
  session: state.auth.session
});


export default connect(mapStateToProps)(Brand)
