import React from "react";
import { Divider, Grid, Typography } from "@material-ui/core";
import { connect } from "react-redux";
import { AppState } from "../../store";
import { Session } from "../../store/Auth/types";


type HomeProps = {
  session: Session | undefined
}

const Home: React.FC<HomeProps> = (props: HomeProps) => {
  return (
    <>
      <div className={"header"}>
        <Grid container>
          <Grid item className={"header-content"}>
            <Typography variant={"h3"}>
              Witaj{ props.session && props.session.user.firstName ? ` ${props.session.user.firstName}`: ""}!
            </Typography>
          </Grid>
        </Grid>
        <Divider />
      </div>
    </>
  )
};


const mapStateToProps = (state: AppState) => ({
  session: state.auth.session
});


export default connect(mapStateToProps)(Home);
