import {
  Filter,
  FILTER_APPLIED,
  Filters,
  FILTERS_APPLIED,
  FILTERS_INIT
} from "./types";


export const initFilters = (payload: Filters) => {
  return {
    type: FILTERS_INIT,
    payload: payload
  }
};


export const applyFilters = (payload: Filters) => {
  return {
    type: FILTERS_APPLIED,
    payload: payload
  }
};


export const applyFilter = (payload: Filter) => {
  return {
    type: FILTER_APPLIED,
    payload: payload
  }
};