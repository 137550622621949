import {
  Avatar,
  Button,
  CssBaseline,
  Grid, Link,
  Paper,
  TextField, Typography
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import LockIcon from "@material-ui/icons/LockOutlined";
import { Alert } from "@material-ui/lab";
import React from "react";
import { RouteComponentProps } from "react-router";
import { IPayload } from "../../api/APIClient";
import { NonFieldErrors } from "../../components/Forms";
import { AuthService } from "../../store/Auth/services";
import { Link as RouterLink } from "react-router-dom";


interface MatchParams {
  token: string
}


interface MemberSignUpProps extends RouteComponentProps<MatchParams> {}


const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
  },
  bg: {
    background: theme.palette.primary.main,
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    overflow: 'hidden'
  },
  formContainer: {
    width: "100%",
    marginTop: theme.spacing(3),
  },
  form: {
    width: '100%', // Fix IE 11 issue.
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
}));


const MemberSignUp: React.FC<MemberSignUpProps> = (props: MemberSignUpProps) => {
  const service = AuthService;
  const token = props.match.params.token;
  const classes = useStyles();
  const [password, setPassword] = React.useState("");
  const [passwordConfirmation, setPasswordConfirmation] = React.useState("");
  const [errors, setErrors] = React.useState<{[key: string]: any} | undefined>(undefined);
  const [company, setCompany] = React.useState<string | undefined>(undefined);
  const [userExists, setUserExists] = React.useState(false);
  const [tokenError, setTokenError] = React.useState<string | undefined>(undefined);
  const [success, setSuccess] = React.useState<boolean | undefined>(undefined);
  const [loading, setLoading] = React.useState(false);

  const signup = (payload: IPayload = {}) => {
    if (!loading) {
      setLoading(true);
      service.memberSignUp(token, payload).then(response => {
        setSuccess(true);
        setLoading(false);
      }).catch(exception => {
        setErrors(exception.data);
        setSuccess(false);
        setLoading(false);
      });
    }
  };

  const handleSubmit = () => {
    const payload = {
      password: password,
      password_confirmation: passwordConfirmation
    };
    signup(payload);
  };

  React.useEffect(() => {
    if (company === undefined && !loading && tokenError === undefined) {
      setLoading(true);
      service.validateMemberSignUpToken(token).then(response => {
        setCompany(response.company);
        setUserExists(response.user_exists);
        setLoading(false);
      }).catch(exception => {
        setSuccess(false);
        switch (exception?.status) {
          case 403:
            setTokenError(`Już jesteś pracownikiem agencji ${exception.data.company}.`);
            break;

          case 404:
            setTokenError("Nieprawidłowy adres rejestracji.");
            break;

          case 400:
            setTokenError("Nieprawidłowy adres rejestracji.");
            break;

          case 410:
            setTokenError("Adres resestracji stracił ważność.");
            break;

          default:
            setTokenError("Wystąpił nieznany błąd.");

          setLoading(false);
        }
      });
    }
  }, [
    loading,
    setLoading,
    service,
    company,
    success,
    token,
    tokenError,
    userExists
  ]);

  const form = (
    <form autoComplete={"off"} className={classes.form}>
      {
        errors && Boolean(errors.non_field_errors) ? (
          <NonFieldErrors errors={errors.non_field_errors}/>
        ) : null
      }
      {
        tokenError ? (
          <NonFieldErrors errors={[tokenError]}/>
        ) : userExists ? (
          <Grid container spacing={3}>
            <Grid item xs={12} style={{textAlign: "center"}}>
              <Button color={"primary"} variant={"contained"} onClick={() => signup()}>
                Dołącz do agencji {company}
              </Button>
            </Grid>
          </Grid>
        ) : (
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                variant={"outlined"}
                label={"Hasło"}
                value={password}
                onChange={(e: any) => setPassword(e.target.value)}
                inputProps={{type: "password"}}
                error={errors && Boolean(errors.password)}
                helperText={
                  errors && Boolean(errors.password)
                    ? errors.password
                    : "Minimum 8 znaków"
                }
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                variant={"outlined"}
                label={"Powtórz hasło"}
                value={passwordConfirmation}
                onChange={(e: any) => setPasswordConfirmation(e.target.value)}
                inputProps={{type: "password"}}
                error={errors && Boolean(errors.password_confirmation)}
                helperText={
                  errors && Boolean(errors.password_confirmation)
                    ? errors.password_confirmation
                    : "Aby zapobiec błędom powtórz jeszcze raz hasło."
                }
              />
            </Grid>
            <Grid item xs={12} className={"form-actions"}>
              <Button color={"primary"} variant={"contained"} onClick={handleSubmit}>
                Zarejestruj się
              </Button>
            </Grid>
          </Grid>
        )
      }
    </form>
  );

  return (
    <Grid container component={"main"} className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={7} className={classes.bg}/>
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Załóż konto
          </Typography>
          <div className={classes.formContainer}>
            {
              success ? (
                <Alert variant={"outlined"} severity={"success"}>
                  Mozesz sie zalogować w <Link component={RouterLink} to={"/dashboard/"}>panelu klienta</Link>.
                </Alert>
              ) : form
            }
          </div>
        </div>
      </Grid>
    </Grid>
  )
};


export default MemberSignUp;
