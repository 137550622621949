export const FILTERS_INIT = "FILTERS_INIT";
export const FILTERS_APPLIED = "FILTERS_APPLIED";
export const FILTER_APPLIED = "FILTER_APPLIED";


export type Filter = {
  id: string,
  queryParam: string,
  filterParam: string,
  value: any
}


export type Filters = {[key: string]: Filter}


export type FiltersInitAction = {
  type: typeof FILTERS_INIT,
  payload: Filters
}


export type FiltersAppliedAction = {
  type: typeof FILTERS_APPLIED,
  payload: Filters
}


export type FilterApplyAction = {
  type: typeof FILTER_APPLIED,
  payload: Filter
}


export type FiltersState = {
  filters: Filters | undefined
}


export type FiltersAction = (
  FilterApplyAction
  | FiltersInitAction
  | FiltersAppliedAction
)