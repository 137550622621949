import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import { Button, Menu, MenuItem } from "@material-ui/core";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { AppState, history } from "../../store";
import { logout } from "../../store/Auth/actions";
import { Session } from "../../store/Auth/types";
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import SettingsIcon from '@material-ui/icons/SettingsOutlined';


type UserMenuProps = {
  session: Session | undefined,
  logout: () => void
}


const useStyles = makeStyles((theme) => ({
  button: {
    color: theme.palette.text.disabled
  },
  menuPaper: {
    minWidth: 220,
    boxShadow: "0 0 14px 0 rgba(53,64,82,.05) !important"
  }
}));


const UserMenu: React.FC<UserMenuProps>= (props: UserMenuProps) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const redirect = (path: string) => {
    history.push(path)
  };

  return (
    <div>
      <Button
        aria-controls="user-menu"
        aria-haspopup="true"
        onClick={handleClick}
        className={classes.button}
      >
        {
          props.session
            ? `${props.session.user.firstName} ${props.session.user.lastName}`
            : ""
        }
        <AccountCircleIcon className={"button-icon-right"}/>
      </Button>
      <Menu
        id="user-menu"
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        classes={{
          paper: classes.menuPaper
        }}
      >
        <MenuItem onClick={
          () => {
            handleClose();
            redirect("/dashboard/account-settings/");
          }
        }>
          <SettingsIcon className={"button-icon-left"}/>
          Ustawienia konta
        </MenuItem>
        <MenuItem onClick={
          () => {
            handleClose();
            props.logout();
            window.location.reload();
          }
        }>
          <ExitToAppIcon className={"button-icon-left"}/>
          Wyloguj się
        </MenuItem>
      </Menu>
    </div>
  );
};

const mapStateToProps = (state: AppState) => ({
  session: state.auth.session
});


const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>) => ({
  logout: () => dispatch(logout())
});


export default connect(mapStateToProps, mapDispatchToProps)(UserMenu)
