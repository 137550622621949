import {
  INSURANCE_CREATED,
  INSURANCE_DELETED,
  INSURANCE_UPDATED,
  InsuranceListState,
  INSURANCES_FETCHED,
  InsurancesAction
} from "./types";
import { Insurance } from "../../models";


export const insuranceListReducer = (
  state: InsuranceListState = {insurances: undefined, count: undefined}, action: InsurancesAction) => {

  let insurances = state.insurances ? {...state.insurances} : {};

  switch (action.type) {
    case INSURANCES_FETCHED:
      insurances = {};
      action.payload.insurances.forEach((insurance: Insurance) => {
        insurances[insurance.id] = insurance;
      });
      return {
        ...state,
        insurances: insurances,
        count: action.payload.count,
      };

    case INSURANCE_CREATED:
      insurances[action.payload.id] = action.payload;
      return {
        ...state,
        insurances: {...insurances}
      };

    case INSURANCE_UPDATED:
      insurances[action.payload.id] = action.payload;
      return {
        ...state,
        insurances: {...insurances}
      };

    case INSURANCE_DELETED:
      delete insurances[action.payload];
      return {
        ...state,
        insurances: {...insurances}
      };

    default:
      return state

  }
};