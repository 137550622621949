import {
  Avatar,
  Card, CardActions,
  CardContent,
  CardHeader, Collapse, IconButton, Tooltip,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import moment from "moment";
import React from "react";
import {
  DraggableProvided,
  DraggableStateSnapshot
} from "react-beautiful-dnd";
import { Todo } from "../../models";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TodoDetail from "./TodoDetail";


type TodoDraggableCardProps = {
  todo: Todo,
  providedDraggable: DraggableProvided,
  snapshotDraggable: DraggableStateSnapshot,
  actionsComponent?: React.ReactNode,
}


const useStyles = makeStyles(theme => ({
  root: {},
  new: {
    backgroundColor: theme.palette.primary.dark
  },
  inProgress: {
    backgroundColor: theme.palette.primary.light
  },
  finished: {
    backgroundColor: theme.palette.success.main
  },
  delayed: {
    backgroundColor: theme.palette.warning.main
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto !important',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
}));


const TodoDraggableCard: React.FC<TodoDraggableCardProps> = (props: TodoDraggableCardProps) => {
  const classes = useStyles();
  const {todo, providedDraggable, snapshotDraggable} = props;
  const [expanded, setExpanded] = React.useState(false);

  const getItemStyle = (draggableStyle: any, isDragging: boolean):{} => ({
    userSelect: 'none',
    margin: `0 0 16px 0`,
    background: isDragging ? "#f0f0f0" : "#fff",
    ...draggableStyle
  });

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Card
      ref={providedDraggable.innerRef}
      {...providedDraggable.draggableProps}
      {...providedDraggable.dragHandleProps}
      style={getItemStyle(
        providedDraggable.draggableProps.style,
        snapshotDraggable.isDragging
      )}
    >
      <CardHeader
        avatar={
          <Avatar
            className={clsx(
              todo.status === "new" && classes.new,
              todo.status === "in_progress" && classes.inProgress,
              todo.status === "finished" && classes.finished,
              todo.due_date && todo.due_date < moment() && todo.status !== "finished" && classes.delayed
            )}>
            {
              todo.assignee ? `${todo.assignee.user?.first_name[0]}${todo.assignee.user?.last_name[0]}` || "?": "?"
            }
          </Avatar>
        }
        title={todo.title}
        subheader={`Termin wykonania do ${todo.due_date?.format("YYYY-MM-DD")}`}
      />
      <CardActions>
        {props.actionsComponent}
        <Tooltip title={"Pokaż więcej"}>
          <IconButton
            className={clsx(classes.expand, {
              [classes.expandOpen]: expanded,
            })}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="Pokaż więcej"
          >
            <ExpandMoreIcon />
          </IconButton>
        </Tooltip>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <TodoDetail todo={todo}/>
        </CardContent>
      </Collapse>
    </Card>
  )
};


export default TodoDraggableCard;