import { Button, Grid, TextField } from "@material-ui/core";
import React from "react";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { APIClient } from "../../api/APIClient";
import { AppState } from "../../store";
import { updateUser } from "../../store/Auth/actions";
import { Session } from "../../store/Auth/types";
import { notify } from "../../utils";


type PersonalInfoChangeFormProps = {
  session: Session | undefined,
  updateUser: (payload: {firstName: string, lastName: string}) => void
}


const PersonalInfoChangeForm: React.FC<PersonalInfoChangeFormProps> = (props: PersonalInfoChangeFormProps) => {
  const [disabled, setDisabled] = React.useState(true);
  const [firstName, setFirstName] = React.useState<string>("");
  const [lastName, setLastName] = React.useState<string>("");

  const handleSubmit = () => {
    const payload = {
      first_name: firstName,
      last_name: lastName
    };
    APIClient.post("/accounts/update-personal-info/", payload).then(response => {
      props.updateUser({firstName, lastName});
      notify("Pomyślnie zaktualizowano dane personalne.", "success");
      setDisabled(true)
    })
  };

  React.useEffect(() => {
    setFirstName(props.session?.user.firstName || "");
    setLastName(props.session?.user.lastName || "");
  }, [props.session]);

  return props.session ? (
    <form autoComplete={"off"}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextField
            required
            fullWidth
            label={"Imię"}
            id={"firstName"}
            variant={"outlined"}
            disabled={disabled}
            value={firstName}
            onChange={(e: any) => setFirstName(e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            fullWidth
            label={"Nazwisko"}
            id={"lastName"}
            variant={"outlined"}
            disabled={disabled}
            value={lastName}
            onChange={(e: any) => setLastName(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} className={"form-actions"}>
          { disabled ? (
            <Button color={"primary"} variant={"contained"} onClick={() => setDisabled(false)}>
              Edytuj
            </Button>
          ) : (
            <>
              <Button color={"primary"} variant={"contained"} onClick={handleSubmit}>
                Zapisz
              </Button>
              <Button color={"default"} variant={"contained"} onClick={() => setDisabled(true)}>
                Anuluj
              </Button>
            </>
          )}
        </Grid>
      </Grid>
    </form>
  ) : null
};


const mapStateToProps = (state: AppState) => ({
  session: state.auth.session
});


const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>) => ({
  updateUser: (payload: {firstName: string, lastName: string}) => dispatch(updateUser(payload))
});


export default connect(mapStateToProps, mapDispatchToProps)(PersonalInfoChangeForm);