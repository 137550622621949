import React from "react";
import {
  Avatar,
  Button, CssBaseline, Grid, MenuItem, Paper, Slide, TextField, Typography
} from "@material-ui/core";
import { Redirect } from "react-router";
import { login } from "../../store/Auth/actions";
import { AuthService } from "../../store/Auth/services";
import { NonFieldErrors } from "../../components/Forms";
import { makeStyles } from "@material-ui/core/styles";
import { AppState } from "../../store";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import {
  CompanyMembership,
  JWTPayload,
  Session
} from "../../store/Auth/types";
import LockIcon from '@material-ui/icons/LockOutlined';
import { deserializeStorage } from "../../utils";


type SignInProps = {
  location: any,
  login: (payload: Session) => void,
  session: Session | undefined
}


const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
  },
  bg: {
    background: theme.palette.primary.main,
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    overflow: 'hidden'
  },
  formContainer: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
  form: {
    width: '100%', // Fix IE 11 issue.
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  companySelect: {
    width: '100%',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    position: 'relative'
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
}));


const SignIn: React.FC<SignInProps> = (props: SignInProps) => {

  const classes = useStyles();
  const [email, setEmail] = React.useState<string>("");
  const [password, setPassword] = React.useState<string>("");
  const [errors, setErrors] = React.useState<{[Key: string]: any} | undefined>(undefined);
  const [jwtPayload, setJWTPayload] = React.useState<JWTPayload | undefined>(undefined);
  const [selectedCompany, setSelectedCompany] = React.useState<CompanyMembership | undefined>(undefined);
  const [session, setSession] = React.useState<Session | undefined>(
    props.session
      ? props.session
      : deserializeStorage("SESSION")
  );
  const [next] = React.useState<string>(
    props.location.state && props.location.state.next
      ? props.location.state.next.pathname
      : "/dashboard/"
  );

  const handleEmailChange = (e: any) => {
    setEmail(e.target.value);
  };

  const handleCompanyChange = (e: any) => {
    if (jwtPayload !== undefined) {
      const company: CompanyMembership | undefined = jwtPayload.companies.find((item: CompanyMembership) => {
         return item.id === e.target.value;
      });
      setSelectedCompany(company)
    }
  };

  const handlePasswordChange = (e: any) => {
    setPassword(e.target.value);
  };

  const handleErrors = (errors: any) => {
    if (errors.response && errors.response.status === 400) {
      setErrors(errors.response.data);
    }
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    AuthService.getToken(email, password, handleErrors).then(
      data => {
        setJWTPayload(data);
      }
    )
  };

  const handleLogin = () => {
    if (selectedCompany !== undefined && jwtPayload !== undefined) {
      const payload: Session = {
        token: jwtPayload.token,
        user: jwtPayload.user,
        company: selectedCompany
      };
      props.login(payload);
      setSession(payload);
    }
  };

  const companySelect: JSX.Element = (
    <div className={classes.companySelect}>
      <TextField
        select
        fullWidth
        variant={"outlined"}
        id={"company-select"}
        value={selectedCompany !== undefined ? selectedCompany.id : ""}
        onChange={handleCompanyChange}
        label="Zaloguj do firmy"
      >
        <MenuItem value={""}>Wybierz</MenuItem>
        {jwtPayload ? jwtPayload.companies.map((company: CompanyMembership) => {
          return <MenuItem key={company.id} value={company.id}>{company.name}</MenuItem>
        }) : null}
      </TextField>
      <Button
        type="submit"
        fullWidth
        variant="contained"
        color="primary"
        onClick={handleLogin}
        className={classes.submit}
        disabled={selectedCompany===undefined}
      >
        Zaloguj się
      </Button>
    </div>
  );

  const form: JSX.Element = (
    <form noValidate method={"post"} onSubmit={handleSubmit} className={classes.form}>
      {
        errors && errors.non_field_errors
          ? <NonFieldErrors errors={errors.non_field_errors}/>
          : null
      }
      <TextField
        required
        fullWidth
        variant={"outlined"}
        margin={"normal"}
        id={"email"}
        label={"Email"}
        name={"email"}
        autoComplete={"off"}
        value={email}
        onChange={handleEmailChange}
        error={errors !== undefined && errors.email !== undefined}
        helperText={errors && errors.email}
      />
      <TextField
        required
        fullWidth
        variant={"outlined"}
        margin={"normal"}
        id={"password"}
        label={"Hasło"}
        name={"password"}
        type={"password"}
        autoComplete={"off"}
        value={password}
        onChange={handlePasswordChange}
        error={errors !== undefined && errors.password !== undefined}
        helperText={errors && errors.password}
      />
      <Button
        type="submit"
        fullWidth
        variant="contained"
        color="primary"
        onClick={handleSubmit}
        className={classes.submit}
      >
        Wyślij
      </Button>
    </form>
  );

  return session ? (
    <Redirect to={next} />
  ) : (
    <Grid container component={"main"} className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={7} className={classes.bg}/>
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Zaloguj się
          </Typography>
          <div className={classes.formContainer}>
            {jwtPayload === undefined ? (
              form
            ) : null}
            <Slide direction={"left"} in={jwtPayload!==undefined} mountOnEnter unmountOnExit timeout={500}>
              {companySelect}
            </Slide>
          </div>
        </div>
      </Grid>
    </Grid>
  )
};


const mapStateToProps = (state: AppState) => ({
  session: state.auth.session
});


const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>) => ({
  login: (payload: Session) => dispatch(login(payload)),
});


export default connect(mapStateToProps, mapDispatchToProps)(SignIn)
