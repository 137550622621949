import React from "react";
import { TablePagination as Pagination } from "@material-ui/core";
import { ThunkDispatch } from "redux-thunk";
import { AppState, history } from "../../store";
import { connect } from "react-redux";
import queryString from "query-string";
import {
  applyFilter,
  applyFilters,
  initFilters
} from "../../store/Filters/actions";
import { Filter, Filters } from "../../store/Filters/types";


type TablePaginationProps = {
  location: any,
  count: number | undefined,
  filters: Filters | undefined,
  initFilters: (payload: Filters) => void,
  applyFilters: (payload: Filters) => void,
  applyFilter: (payload: Filter) => void,
};


const TablePagination: React.FC<TablePaginationProps> = (props: TablePaginationProps) => {

  const [limit, setLimit] = React.useState<number>(25);
  const [offset, setOffset] = React.useState<number>(0);
  const [page, setPage] = React.useState<number>(0);

  React.useEffect(() => {
    setLimit(parseInt(props.location.query?.limit || limit));
    setOffset(parseInt(props.location.query?.offset || offset));
    if (props.count || 0 > offset || 0) {
      setPage(offset / limit)
    } else setPage(0);
  }, [props.location, props.count, offset, limit]);

  const getLimitFilter = (limit: number): Filter => {
    return {
      id: "limit",
      queryParam: "limit",
      filterParam: "limit",
      value: limit
    }
  };

  const getOffsetFilter = (offset: number): Filter => {
    return {
      id: "offset",
      queryParam: "offset",
      filterParam: "offset",
      value: offset
    }
  };

  const handlePageChange = (e: any, value: number) => {
    const newOffset = value * limit;
    const query = props.location.query;
    query.offset = newOffset;
    const search = queryString.stringify(query);
    setPage(value);
    setOffset(newOffset);
    history.push({
      pathname: props.location.path,
      search: search
    });
    props.applyFilter(getOffsetFilter(newOffset))
  };

  const handleLimitChange = (e: any) => {
    const newLimit = e.target.value;
    const query = props.location.query;
    query.limit = newLimit;
    query.offset = 0;
    setLimit(newLimit);
    setOffset(0);
    setPage(0);
    const search = queryString.stringify(query);
    history.push({
      pathname: props.location.path,
      search: search
    });
    props.applyFilters({
      limit: getLimitFilter(newLimit),
      offset: getOffsetFilter(0)
    })
  };

  return (
    <>
      <Pagination
        count={props.count || 0}
        page={props.count || 0 > offset ? page : 0}
        rowsPerPage={limit}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handleLimitChange}
        labelRowsPerPage={"Pokaż"}
        labelDisplayedRows={({from, to, count, page}) => {
          return <>{from}-{to} z {count}</>
        }}
        rowsPerPageOptions={[10, 25, 50, 100]}
        style={{border: "none"}}
      />
    </>
  )
};


const mapStateToProps = (state: AppState) => ({
  location: state.router.location,
  filters: state.filters.filters,
});


const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>) => ({
  initFilters: (payload: Filters) => dispatch(initFilters(payload)),
  applyFilters: (payload: Filters) => dispatch(applyFilters(payload)),
  applyFilter: (payload: Filter) => dispatch(applyFilter(payload)),
});


export default connect(mapStateToProps, mapDispatchToProps)(TablePagination);