import React from "react";
import { Grid } from "@material-ui/core";
import { ThunkDispatch } from "redux-thunk";
import { INSURANCE_TYPES } from "../../constants";
import { AppState } from "../../store";
import { connect } from "react-redux";
import { applyFilter, initFilters } from "../../store/Filters/actions";
import { Filter, Filters } from "../../store/Filters/types";
import SelectFilter from "../Filters/SelectFilter";
import SingleOptionFilter from "../Filters/SingleOptionFilter";
import moment from "moment";

type InsuranceListFiltersProps = {
  location: any,
  filters: Filters | undefined,
  initFilters: (payload: Filters) => void,
  applyFilter: (payload: Filter) => void,
}


const InsuranceListFilters: React.FC<InsuranceListFiltersProps> = (props: InsuranceListFiltersProps) => {

  const [filtersState, setFiltersState] = React.useState<Filters | undefined>(undefined);
  const endDateRangeOptions = [
      {label: "Dowolny", value: ""},
      {label: "W ciągu tygodnia", value: `${moment().format("YYYY-MM-DD")},${moment().add(7, "days").format("YYYY-MM-DD")}`},
      {label: "W ciągu 2 tygodni", value: `${moment().format("YYYY-MM-DD")},${moment().add(14, "days").format("YYYY-MM-DD")}`},
      {label: "W ciągu miesiąca", value: `${moment().format("YYYY-MM-DD")},${moment().add(1, "months").format("YYYY-MM-DD")}`},
  ];
  const paidCommissionOptions = [
    {label: "Wszystkie", value: ""},
    {label: "TAK", value: "true"},
    {label: "NIE", value: "false"},
  ];
  const statusOptions = [
    {label: "Wszystkie", value: ""},
    {label: "Aktywne", value: "active"},
    {label: "Zakończone", value: "ended"},
    {label: "Przerwane", value: "terminated"},
  ];

  const filtersConfig: Filters = {
    search: {
      id: "search",
      queryParam: "search",
      filterParam: "search",
      value: props.location.query.search || "",
    },
    type: {
      id: "type",
      queryParam: "type",
      filterParam: "insurance_type",
      value: props.location.query.type || "",
    },
    end_date_range: {
      id: "end_date_range",
      queryParam: "end_date_range",
      filterParam: "end_date__range",
      value: endDateRangeOptions.some(o => o.value === unescape(props.location.query.end_date_range))
        ? unescape(props.location.query.end_date_range)
        : "",
    },
    premium_due_date_range: {
      id: "premium_due_date_range",
      queryParam: "premium_due_date_range",
      filterParam: "premiums__due_date__range",
      value: endDateRangeOptions.some(o => o.value === unescape(props.location.query.premium_due_date_range))
        ? unescape(props.location.query.premium_due_date_range)
        : "",
    },
    paid_commission: {
      id: "paid_commission",
      queryParam: "paid_commission",
      filterParam: "paid_commission",
      value: props.location.query.paid_commission || "",
    },
    status: {
      id: "status",
      queryParam: "status",
      filterParam: "actual_status",
      value: props.location.query.status || "",
    },
    limit: {
      id: "limit",
      queryParam: "limit",
      filterParam: "limit",
      value: parseInt(props.location.query.limit || 25)
    },
    offset: {
      id: "offset",
      queryParam: "offset",
      filterParam: "offset",
      value: parseInt(props.location.query.offset || 0)
    },
  };

  const {filters, initFilters} = props;

  React.useEffect(() => {
    if (filtersState === undefined) {
      initFilters(filtersConfig);
      setFiltersState(filtersConfig)
    }
  }, [filters, initFilters, filtersConfig, filtersState, setFiltersState]);

  return (
    <Grid container>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <SelectFilter
              id={filtersConfig.type.id}
              label={"Typ polisy"}
              queryParam={filtersConfig.type.queryParam}
              filterParam={filtersConfig.type.filterParam}
              value={filtersConfig.type.value}
              onChange={(filter: Filter) => {
                props.applyFilter(filter)
              }}
              options={Object.keys(INSURANCE_TYPES).map(k => {
                return {label: INSURANCE_TYPES[k], value: k}
              })}
            />
          </Grid>
          <Grid item xs={12}>
            <SingleOptionFilter
              id={filtersConfig.end_date_range.id}
              label={"Zbliżający się koniec polisy"}
              queryParam={filtersConfig.end_date_range.queryParam}
              filterParam={filtersConfig.end_date_range.filterParam}
              value={unescape(filtersConfig.end_date_range.value)}
              onChange={(filter: Filter) => {
                props.applyFilter(filter)
              }}
              options={endDateRangeOptions} />
          </Grid>
          <Grid item xs={12}>
            <SingleOptionFilter
              id={filtersConfig.premium_due_date_range.id}
              label={"Zbliżający się termin płatności"}
              queryParam={filtersConfig.premium_due_date_range.queryParam}
              filterParam={filtersConfig.premium_due_date_range.filterParam}
              value={unescape(filtersConfig.premium_due_date_range.value)}
              onChange={(filter: Filter) => {
                props.applyFilter(filter)
              }}
              options={endDateRangeOptions} />
          </Grid>
          <Grid item xs={12}>
            <SingleOptionFilter
              id={filtersConfig.paid_commission.id}
              label={"Zapłacono prowizję"}
              queryParam={filtersConfig.paid_commission.queryParam}
              filterParam={filtersConfig.paid_commission.filterParam}
              value={unescape(filtersConfig.paid_commission.value)}
              onChange={(filter: Filter) => {
                props.applyFilter(filter)
              }}
              options={paidCommissionOptions} />
          </Grid>
          <Grid item xs={12}>
            <SingleOptionFilter
              id={filtersConfig.status.id}
              label={"Status"}
              queryParam={filtersConfig.status.queryParam}
              filterParam={filtersConfig.status.filterParam}
              value={unescape(filtersConfig.status.value)}
              onChange={(filter: Filter) => {
                props.applyFilter(filter)
              }}
              options={statusOptions} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
};


const mapStateToProps = (state: AppState) => ({
  location: state.router.location,
  filters: state.filters.filters
});


const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>) => ({
  initFilters: (payload: Filters) => dispatch(initFilters(payload)),
  applyFilter: (payload: Filter) => dispatch(applyFilter(payload)),
});


export default connect(mapStateToProps, mapDispatchToProps)(InsuranceListFilters);