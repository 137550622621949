import { Todo } from "../../models";

export const TODOS_FETCHED = "TODOS_FETCHED";
export const TODO_CREATED = "TODO_CREATED";
export const TODO_UPDATED = "TODO_UPDATED";
export const TODO_DELETED = "TODO_DELETED";
export const DASHBOARD_TODOS_FETCHED = "DASHBOARD_TODOS_FETCHED";


export type Todos = {[id: string]: Todo};


export type TodosFetchAction = {
  type: typeof TODOS_FETCHED,
  payload: {todos: Todo[], count: number}
}


export type DashboardTodosFetchAction = {
  type: typeof DASHBOARD_TODOS_FETCHED,
  payload: Todo[]
}


export type TodoCreateAction = {
  type: typeof TODO_CREATED,
  payload: Todo
}


export type TodoUpdateAction = {
  type: typeof TODO_UPDATED,
  payload: Todo
}


export type TodoDeleteAction = {
  type: typeof TODO_DELETED,
  payload: string
}


export type TodoListState = {
  todos: Todos | undefined,
  count: number | undefined,
}


export type TodoDashboardState = {
  todos: Todos | undefined
}


export type TodosAction = (
  TodosFetchAction
  | TodoCreateAction
  | TodoUpdateAction
  | TodoDeleteAction
  | DashboardTodosFetchAction
)