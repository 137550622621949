import { createBrowserHistory } from "history";
import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import { memberListReducer } from "./Accounts/reducers";
import { authReducer } from "./Auth/reducers";
import { connectRouter, routerMiddleware } from "connected-react-router";
import thunk from "redux-thunk";
import { clientListReducer } from "./Clients/reducers";
import { filtersReducer } from "./Filters/reducers";
import { insuranceListReducer } from "./Insurances/reducers";
import { todoDashboardReducer, todoListReducer } from "./Todos/reducers";

export const history = createBrowserHistory();

export const appReducer = combineReducers({
  router: connectRouter(history),
  auth: authReducer,
  clientList: clientListReducer,
  insuranceList: insuranceListReducer,
  todoList: todoListReducer,
  todoDashboard: todoDashboardReducer,
  memberList: memberListReducer,
  filters: filtersReducer,
});


const middlewares = compose(applyMiddleware(routerMiddleware(history), thunk));

export type AppState = ReturnType<typeof appReducer>;
export const store = createStore(appReducer, middlewares);