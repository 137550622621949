import { APIClient, IPayload } from "../../api/APIClient";
import { notify } from "../../utils";


export class AuthService {

  static getToken = (email: string, password: string, onError?: (error: any) => any) => {
    return APIClient.post(
      '/auth/get-token/',
      {email: email, password: password}
    ).then(response => {
      return response.data
    }).catch((error) => {
      notify(`${error}`, 'error');
      if (onError) {
        onError(error)
      }
    });
  };

  static refreshToken(token: string | null) {
    return APIClient.post(
      '/auth/refresh-token/',
      {token: token}
    ).then(response => {
      return response.data
    });
  };

  static validateMemberSignUpToken = (token: string) => {
    const url = `/auth/signup/${token}/`;
    return APIClient.get(url).then(response => {
      return response.data;
    }).catch((error) => {
      throw error.response;
    })
  };

  static memberSignUp = (token: string, payload: IPayload) => {
    const url = `/auth/signup/${token}/`;
    return APIClient.post(url, payload).then(response => {
      return response.status;
    }).catch((error) => {
      notify(`${error}`, 'error');
      throw error.response;
    })

  }
}
