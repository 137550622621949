import {
  FILTER_APPLIED, Filters,
  FILTERS_APPLIED,
  FILTERS_INIT,
  FiltersAction,
  FiltersState
} from "./types";


export const filtersReducer = (state: FiltersState = {filters: undefined}, action: FiltersAction): FiltersState => {
  let filters = state.filters || {};

  switch (action.type) {
    case FILTERS_INIT:
      const newFilters: Filters = {};
      Object.values(action.payload).forEach(filter => {
        if (Boolean(filter.value)) {
          newFilters[filter.id] = filter;
        }
      });

      return {
        ...state,
        filters: {...newFilters}
      };

    case FILTERS_APPLIED:
      Object.values(action.payload).forEach(filter => {
        if (Boolean(filter.value)) {
          filters[filter.id] = filter;
        }
        else {
          delete filters[filter.id];
        }
      });

      return {
        ...state,
        filters: {...filters}
      };

    case FILTER_APPLIED:
      if (Boolean(action.payload.value)) {
        filters[action.payload.id] = action.payload;
      }
      else {
        delete filters[action.payload.id];
      }
      return {
        ...state,
        filters: {...filters}
      };

    default:
      return state;
  }
};