import * as queryString from "querystring";
import { APIClient, IParams, IPayload } from "../../api/APIClient";
import { notify } from "../../utils";
import { Insurance } from "../../models";


export class InsuranceService {

  companyId: string;

  constructor(companyId: string) {
    this.companyId = companyId;
  }

  getCSV = (params: IParams = {}) => {
    const url = `/${this.companyId}/products/insurances/`;
    params.format = "csv";
    params.limit = 1000;
    return APIClient.get(
      url,
      params,
      undefined,
      undefined,
      {responseType: "blob"}
      ).then((response) => {
        return response;
    })
  };

  getAttachmentUrl = (path: string, fileUrl: string) => {
    const url = `/${this.companyId}/media/`;
    return APIClient.get(
      url,
      {path: path},
    ).then((response) => {
      const qs = queryString.stringify({
        md5: response.data.md5,
        expires: response.data.expires
      });
      return `${fileUrl}?${qs}`
    });
  };

  fetch = (params: IParams = {}) => {
    const url = `/${this.companyId}/products/insurances/`;
    return APIClient.get(url, params).then((response) => {
      const insurances = response.data.results.map((item: any) => {
        return new Insurance(item);
      });
      return {insurances: insurances, count: response.data.count};
    }).catch((error) => {
      notify(`${error}`, 'error');
      throw error.response;
    })
  };

  create = (payload: IPayload) => {
    const url = `/${this.companyId}/products/insurances/`;
    return APIClient.post(url, payload).then((response) => {
      notify("Pomyślnie dodano nową polisę.", "success");
      return response.data;
    }).catch((error) => {
      notify(`${error}`, 'error');
      throw error.response;
    })
  };

  update = (id: string, payload: IPayload) => {
    const url = `/${this.companyId}/products/insurances/${id}/`;
    return APIClient.patch(url, payload).then((response) => {
      notify(`Pomyślnie edytowano polisę.`, 'success');
      return response.data;
    }).catch((error) => {
      notify(`${error}`, 'error');
      throw error.response;
    })
  };

  delete = (id: string) => {
    const url = `/${this.companyId}/products/insurances/${id}/`;
    return APIClient.delete(url).then((response) => {
      notify("Pomyślnie usunięto polisę.", "success");
      return response.data;
    }).catch((error) => {
      notify(`${error}`, 'error');
      throw error.response;
    })
  };

  get = (id: string) => {
    const url = `/${this.companyId}/products/insurances/${id}/`;
    return APIClient.get(url).then((response) => {
      return response.data;
    }).catch((error) => {
      notify(`${error}`, 'error');
      throw error.response;
    })
  };

  uploadAttachment = (insuranceId: string, payload: IPayload) => {
    const url = `/${this.companyId}/products/insurance-attachments/${insuranceId}/`;
    const data = APIClient.formData(payload);
    return APIClient.post(
      url,
      data,
      undefined,
      ).then((response) => {
      notify("Pomyślnie dodano załącznik do polisy", "success");
      return response.data;
    }).catch((error) => {
      notify(`${error}`, 'error');
      throw error.response;
    })
  };

  deleteAttachment = (insuranceId: string, id: string) => {
    const url = `/${this.companyId}/products/insurance-attachments/${insuranceId}/${id}/`;
    return APIClient.delete(url).then((response) => {
      notify("Pomyślnie usunięto załącznik polisy", "success");
      return response.data;
    }).catch((error) => {
      notify(`${error}`, 'error');
      throw error.response;
    })
  }

}