import { Button, Menu, MenuItem } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { Link as RouterLink } from "react-router-dom";
import DashboardIcon from "@material-ui/icons/DashboardOutlined";
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutlineOutlined';
import PolicyIcon from '@material-ui/icons/PolicyOutlined';
import AssignmentIcon from '@material-ui/icons/AssignmentTurnedInOutlined';
import clsx from "clsx";

interface IMainMenuProps extends RouteComponentProps {
  onItemSelected: (...args: any[]) => void,
}


type MainMenuProps = IMainMenuProps;


const useStyles = makeStyles((theme) => ({
  root: {},
  button: {
    color: theme.palette.text.disabled,
    fontSize: "0.9rem",
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  buttonIcon: {
    marginRight: theme.spacing(0.5),
  },
  active: {
    backgroundColor: "#f2f2f2",
  },
  link: {
    textDecoration: "none",
    fontWeight: 400,
    color: "#eee",
    "&:hover": {
      textDecoration: 'none',
    }
  },
  listItem: {
    "&:hover": {
      backgroundColor: "#18212c"
    }
  },
  listItemSelected: {
    backgroundColor: "#12181F !important",
  },
  listItemIcon: {
    color: "#ccc",
    minWidth: 32
  },
  menuPaper: {
    minWidth: 220,
    boxShadow: "0 0 14px 0 rgba(53,64,82,.05) !important"
  }
}));


const MainMenu: React.FC<MainMenuProps> = (props: MainMenuProps) => {
  const classes = useStyles();
  const [todoAnchorEl, setTodoAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleTodoMenuClick = (e: any) => {
    setTodoAnchorEl(e.currentTarget);
  };

  const handleTodoMenuItemClick = () => {
    setTodoAnchorEl(null);
  };

  return (
    <div className={classes.root}>
      <Button
        component={RouterLink}
        to={"/dashboard/"}
        className={clsx(classes.button, props.location.pathname.endsWith('/dashboard/') && classes.active)}
      >
        <DashboardIcon className={classes.buttonIcon} fontSize={"small"}/>
        Pulpit
      </Button>
      <Button
        component={RouterLink}
        to={"/dashboard/clients/"}
        className={clsx(classes.button, props.location.pathname.startsWith('/dashboard/clients/') && classes.active)}
      >
        <PeopleOutlineIcon className={classes.buttonIcon} fontSize={"small"}/>
        Klienci
      </Button>
      <Button
        component={RouterLink}
        to={"/dashboard/insurances/"}
        className={clsx(classes.button, props.location.pathname.startsWith('/dashboard/insurances/') && classes.active)}
      >
        <PolicyIcon className={classes.buttonIcon} fontSize={"small"}/>
        Polisy
      </Button>
      <Button
        aria-controls={"todo-menu"}
        className={clsx(classes.button, props.location.pathname.startsWith('/dashboard/tasks/') && classes.active)}
        onClick={handleTodoMenuClick}
      >
        <AssignmentIcon className={classes.buttonIcon} fontSize={"small"}/>
        Zadania
      </Button>
      <Menu
        id={"todo-menu"}
        anchorEl={todoAnchorEl}
        open={Boolean(todoAnchorEl)}
        keepMounted
        classes={{
          paper: classes.menuPaper
        }}
        onClose={() => setTodoAnchorEl(null)}
      >
        <MenuItem component={RouterLink} to={"/dashboard/tasks/"} onClick={() => handleTodoMenuItemClick()}>
          Lista zadań
        </MenuItem>
        <MenuItem component={RouterLink} to={"/dashboard/tasks/my-list/"} onClick={() => handleTodoMenuItemClick()}>
          Moje zadania
        </MenuItem>
      </Menu>
    </div>
  )
};


export default withRouter(MainMenu);