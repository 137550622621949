import React from "react";
import { AppBar, CssBaseline, Toolbar}  from "@material-ui/core";
import Brand from "../../components/Brand";
import UserMenu from "../../components/UserMenu";
import { Routes } from "./Routes";
import { makeStyles } from "@material-ui/core/styles";
import { AppState } from "../../store";
import { connect } from "react-redux";
import { Session } from "../../store/Auth/types";
import MainMenu from "../../components/MainMenu";


type DashboardProps = {
  session: Session | undefined
}


const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "100vh",
    background: "rgb(247, 249, 252)"
  },
  title: {
    flexGrow: 1,
  },
  appBar: {
    color: theme.palette.text.disabled,
    background: "#fff",
    boxShadow: "rgba(53, 64, 82, 0.05) 0px 0px 14px 0px"
  },
  dashboardContainer: {
    padding: theme.spacing(3),
    paddingBottom: 0,
  }
}));


const Dashboard: React.FC<DashboardProps> = (props: DashboardProps) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position={"static"} className={classes.appBar}>
        <Toolbar variant={"dense"}>
          <div>
            <Brand />
          </div>
          <div>
            <MainMenu onItemSelected={() => {}} />
          </div>
          <div style={{marginLeft: "auto"}}>
            <UserMenu/>
          </div>
        </Toolbar>
      </AppBar>
      <div className={classes.dashboardContainer}>
        <Routes />
      </div>
    </div>
  )
};


const mapStateToProps = (state: AppState) => ({
  session: state.auth.session
});


export default connect(mapStateToProps)(Dashboard);