import { Grid } from "@material-ui/core";
import React from "react";
import { ThunkDispatch } from "redux-thunk";
import { AppState } from "../../store";
import { connect } from "react-redux";
import { applyFilter, initFilters } from "../../store/Filters/actions";
import { Filter, Filters } from "../../store/Filters/types";
import SingleOptionFilter from "../Filters/SingleOptionFilter";


type ClientListFiltersProps = {
  location: any,
  filters: Filters | undefined,
  initFilters: (payload: Filters) => void,
  applyFilter: (payload: Filter) => void,
}


const ClientListFilters: React.FC<ClientListFiltersProps> = (props: ClientListFiltersProps) => {

  const [filtersState, setFiltersState] = React.useState<Filters | undefined>(undefined);
  const gdprOptions = [
    {label: "Wszystkie", value: ""},
    {label: "TAK", value: "true"},
    {label: "NIE", value: "false"},
  ];

  const filtersConfig: Filters = {
    search: {
      id: "search",
      queryParam: "search",
      filterParam: "search",
      value: props.location.query.search || ""
    },
    gdpr_ack: {
      id: "gdpr_ack",
      queryParam: "gdpr",
      filterParam: "gdpr_ack",
      value: props.location.query.search || ""
    },
    limit: {
      id: "limit",
      queryParam: "limit",
      filterParam: "limit",
      value: props.location.query.limit || 25
    },
    offset: {
      id: "offset",
      queryParam: "offset",
      filterParam: "offset",
      value: props.location.query.offset || 0
    },
  };

  const {filters, initFilters} = props;

  React.useEffect(() => {
    if (filtersState === undefined) {
      initFilters(filtersConfig);
      setFiltersState(filtersConfig)
    }
  }, [filters, initFilters, filtersConfig, filtersState, setFiltersState]);

  return (
    <Grid container>
      <Grid item xs={12}>
        <SingleOptionFilter
          id={filtersConfig.gdpr_ack.id}
          label={"RODO"}
          queryParam={filtersConfig.gdpr_ack.queryParam}
          filterParam={filtersConfig.gdpr_ack.filterParam}
          value={unescape(filtersConfig.gdpr_ack.value)}
          onChange={(filter: Filter) => {
            props.applyFilter(filter)
          }}
          options={gdprOptions} />
      </Grid>
    </Grid>
  )
;
};


const mapStateToProps = (state: AppState) => ({
  location: state.router.location,
  filters: state.filters.filters
});


const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>) => ({
  initFilters: (payload: Filters) => dispatch(initFilters(payload)),
  applyFilter: (payload: Filter) => dispatch(applyFilter(payload)),
});


export default connect(mapStateToProps, mapDispatchToProps)(ClientListFilters);