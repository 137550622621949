import {
  Breadcrumbs,
  Divider,
  Grid,
  Link, Paper,
  Typography
} from "@material-ui/core";
import React from "react";
import { connect } from "react-redux";
import { Link as RouterLink, Redirect } from "react-router-dom";
import { ThunkDispatch } from "redux-thunk";
import InsuranceForm from "../../components/Insurances/InsuranceForm";
import { Insurance } from "../../models";
import { AppState } from "../../store";
import { Session } from "../../store/Auth/types";
import { createInsurance } from "../../store/Insurances/actions";


type InsuranceCreateViewProps = {
  session: Session | undefined,
  createInsurance: (payload: Insurance) => void,
}


const InsuranceCreateView: React.FC<InsuranceCreateViewProps> = (props: InsuranceCreateViewProps) => {

  const [created, setCreated] = React.useState<string | undefined>(undefined);
  const [cancelled, setCancelled] = React.useState(false);
  const [continueEditing, setContinueEditing] = React.useState<boolean | undefined>(undefined);

  if (created !== undefined && continueEditing === true) {
    return (
      <Redirect to={`/dashboard/insurances/insurance/${created}/`}/>
    )
  }
  else if ((created !== undefined && continueEditing === false) || cancelled) {
    return (
      <Redirect to={"/dashboard/insurances/"}/>
    )
  }
  else {
    return (
      <>
        <div className={"header"}>
          <Grid container>
            <Grid item className={"header-content"}>
              <Breadcrumbs>
                <Link component={RouterLink} to={"/dashboard/"}>Pulpit</Link>
                <Link component={RouterLink} to={"/dashboard/insurances/"}>Polisy</Link>
                <Typography color={"textSecondary"}>Dodaj polisę</Typography>
              </Breadcrumbs>
            </Grid>
          </Grid>
          <Divider />
        </div>
        <Grid container className={"content"}>
          <Grid item xs={12}>
            <Paper className={"paper-content"}>
              <Grid container>
                <Grid item xs={12}>
                  <InsuranceForm
                    onSuccess={(insurance: Insurance, continueEditing?: boolean) => {
                      props.createInsurance(insurance);
                      setCreated(insurance.id);
                      setContinueEditing(Boolean(continueEditing));
                    }}
                    onCancel={() => {
                      setCancelled(true)
                    }}
                  />
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </>
    )
  }
};


const mapStateToProps = (state: AppState) => ({
  session: state.auth.session,
});


const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>) => ({
  createInsurance: (payload: Insurance) => dispatch(createInsurance(payload)),
});


export default connect(mapStateToProps, mapDispatchToProps)(InsuranceCreateView);
