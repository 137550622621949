import { makeStyles } from "@material-ui/core/styles";
import { Alert } from "@material-ui/lab";
import React from "react";


const useStyles = makeStyles((theme) => ({
  root: {
    margin: "0 0 24px 0",
  },
  errorlist: {
    listStyleType: "none",
    padding: 0,
    margin: 0,
  }
}));

const NonFieldErrors = (props: any): JSX.Element => {
  const classes = useStyles();
  const nonFieldErrors: string[] = props.errors;
  const errors = nonFieldErrors ? nonFieldErrors.map((error, index) => {
    return <li key={`non-field-errors-${index}`}>{error}</li>;
  }) : null;
  return errors ? (
    <Alert severity={"error"} className={classes.root}>
      <ul className={classes.errorlist}>{errors}</ul>
    </Alert>
  ) : <></>;
};


export default NonFieldErrors;
