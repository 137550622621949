const deserializeStorage = (key: string) => {
  const value = localStorage.getItem(key);
  if (value !== null) {
    try {
      return JSON.parse(value);
    }
    catch (e) {
      localStorage.removeItem(key)
    }
  }
  return undefined;
};


export default deserializeStorage;
