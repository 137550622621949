import { Todo } from "../../models";
import {
  DASHBOARD_TODOS_FETCHED,
  TODO_CREATED,
  TODO_DELETED,
  TODO_UPDATED,
  TodoDashboardState,
  TodoListState,
  TODOS_FETCHED,
  TodosAction
} from "./types";


export const todoListReducer = (
  state: TodoListState = {todos: undefined, count: undefined},
  action: TodosAction): TodoListState => {

  let todos = state.todos ? {...state.todos} : {};

  switch (action.type) {
    case TODOS_FETCHED:
      todos = {};
      action.payload.todos.forEach((todo: Todo) => {
        todos[todo.id] = todo
      });
      return {
        ...state,
        todos: todos,
        count: action.payload.count
      };

    case TODO_CREATED:
      todos[action.payload.id] = action.payload;
      return {
        ...state,
        todos: {...todos}
      };

    case TODO_UPDATED:
      todos[action.payload.id] = action.payload;
      return {
        ...state,
        todos: {...todos}
      };

    case TODO_DELETED:
      delete todos[action.payload];
      return {
        ...state,
        todos: {...todos}
      };

    default:
      return state;
  }
};


export const todoDashboardReducer = (
  state: TodoDashboardState = {todos: undefined},
  action: TodosAction): TodoDashboardState => {

  let todos = state.todos ? {...state.todos} : {};

  switch (action.type) {
    case DASHBOARD_TODOS_FETCHED:
      todos = {};
      action.payload.forEach((todo: Todo) => {
        todos[todo.id] = todo
      });
      return {
        ...state,
        todos: todos,
      };

    case TODO_CREATED:
      todos[action.payload.id] = action.payload;
      return {
        ...state,
        todos: {...todos}
      };

    case TODO_UPDATED:
      todos[action.payload.id] = action.payload;
      return {
        ...state,
        todos: {...todos}
      };

    case TODO_DELETED:
      delete todos[action.payload];
      return {
        ...state,
        todos: {...todos}
      };

    default:
      return state;
  }
};