export default class Address {
  street_name: string;
  route_number: string;
  postal_code: string;
  city: string;

  constructor(input: {[key: string]: string}) {
    this.street_name = input.street_name;
    this.route_number = input.route_number;
    this.postal_code = input.postal_code;
    this.city = input.city;
  }
}

