import React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { Session } from "../../store/Auth/types";
import { Insurance, InsuranceAttachment } from "../../models";
import {
  Breadcrumbs, Button, Card, CardActions, CardContent, CardHeader, CircularProgress, Divider, Grid, IconButton, Link,
  List, ListItem,
  ListItemSecondaryAction,
  ListItemText, Paper, TextField, Typography
} from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import InsuranceForm from "../../components/Insurances/InsuranceForm";
import { AppState } from "../../store";
import { ThunkDispatch } from "redux-thunk";
import { connect } from "react-redux";
import { InsuranceService } from "../../store/Insurances/services";
import { updateInsurance } from "../../store/Insurances/actions";
import Dropzone from "react-dropzone";
import { makeStyles } from "@material-ui/core/styles";
import AttachmentIcon from '@material-ui/icons/AttachmentOutlined';
import DeleteIcon from '@material-ui/icons/DeleteOutlined';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircleOutlineOutlined';
import { notify } from "../../utils";


interface MatchParams {
  id: string
}


interface InsuranceDetailViewProps extends RouteComponentProps<MatchParams> {
  session: Session | undefined,
  insurance: Insurance | undefined | null,
  updateInsurance: (payload: Insurance) => void
}


const useStyles = makeStyles(theme => ({
  "@keyframes striped": {
    "0%": {
      backgroundPosition: '0 0',
    },
    "100%": {
      backgroundPosition: '60px 30px',
    }
  },
  dropZoneContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  dropZone: {
    padding: theme.spacing(4),
    borderStyle: 'dashed',
    borderWidth: 1,
    borderColor: theme.palette.divider,
    textAlign: 'center',
    '&:hover': {
      backgroundSize: '30px 30px',
      backgroundImage: `linear-gradient(-45deg,#eff1f5 25%,transparent 0,transparent 50%,#eff1f5 0,#eff1f5 75%,transparent 0,transparent)`,
      animation: `$striped 2000ms linear infinite`,
    }
  },
  attachment: {
    padding: theme.spacing(1),
    marginBottom: theme.spacing(1),
    "&:nth-child(odd)": {
      backgroundColor: "#fafafa !important",
      borderRadius: theme.spacing(0.5),
    }
  },
  card: {
    marginBottom: theme.spacing(3)
  },
  cardActions: {
    padding: theme.spacing(2)
  },
  attachmentFilename: {
    maxWidth: 200,
  }
}));


const InsuranceDetailView: React.FC<InsuranceDetailViewProps> = (props: InsuranceDetailViewProps) => {

  const insuranceId = props.match.params.id;
  const service = props.session ? new InsuranceService(props.session.company.id) : undefined;
  const classes = useStyles();
  const [insurance, setInsurance] = React.useState<Insurance | undefined | null>(undefined);
  const [uploadList, setUploadList] = React.useState<{name: string, attachment: File}[]>([]);
  const [uploading, setUploading] = React.useState(false);

  React.useEffect(() => {
    if (insurance === undefined && service !== undefined) {
      service.get(insuranceId).then(response => {
        setInsurance(new Insurance(response));
      }).catch(response => {
        setInsurance(null)
      });
    }
  });

  const updateUploadItemName = (val: any, index: number) => {
    const items = [...uploadList];
    const item = items[index];
    item.name = val;
    items[index] = item;
    setUploadList(items);
  };

  const addFilesToUpload = (files: File[]) => {
    setUploadList([...uploadList, ...files.map(f => {
      return {name: f.name, attachment: f}
    })]);
  };

  const removeFileFromUpload = (index: number) => {
    const newList = [...uploadList];
    newList.splice(index, 1);
    setUploadList(newList);
  };

  const downloadAttachment = (attachment: InsuranceAttachment) => {
    return service?.getAttachmentUrl(attachment.path, attachment.attachment).then(url => {
      window.open(url, "_blank")
    }).catch(() => {
      notify("Wystąpił błąd podczas pobierania załącznika.", "error");
    });
  };

  const uploadAttachments = () => {
    const upload = (attachmentList: {name: string, attachment: File}[]) => {
      if (service !== undefined && attachmentList.length > 0) {
        const [payload, ...rest] = attachmentList;
        service.uploadAttachment(insuranceId, payload).then(response => {
          if (insurance) {
            const attachment = new InsuranceAttachment(response);
            setInsurance({...insurance, attachments: [...insurance.attachments, attachment]});
            insurance.attachments.push(attachment);
            setUploadList([...rest]);
          }
          upload(rest);
        }).catch(error => {
          setUploadList([...rest, payload]);
          setUploading(false);
        });
      }
      else {
        setUploading(false);
      }
    };
    setUploading(true);
    upload(uploadList);
  };

  const deleteAttachment = (id: string) => {
    if (service !== undefined) {
      service.deleteAttachment(insuranceId, id).then(() => {
        if (insurance) {
          setInsurance({...insurance, attachments: insurance.attachments.filter((attachment) => {
              return attachment.id !== id
            })
          });
        }
      })
    }
  };

  if (insurance === null) {
    return (
      <>Nie znaleziono polisy</>
    )
  }
  else if (insurance === undefined) {
    return <></>
  }
  else {
    return (
      <>
        <div className={"header"}>
          <Grid container>
            <Grid item className={"header-content"}>
              <Breadcrumbs>
                <Link component={RouterLink} to={"/dashboard/"}>Pulpit</Link>
                <Link component={RouterLink} to={"/dashboard/insurances/"}>Polisy</Link>
                <Typography color={"textSecondary"}>
                  { insurance.number }
                </Typography>
              </Breadcrumbs>
            </Grid>
          </Grid>
          <Divider />
        </div>
        <Grid container className={"content"}>
          <Grid item xs={12}>
            <Paper className={"paper-content"}>
              <Grid container>
                <Grid item xs={12}>
                  <InsuranceForm
                    disabled
                    instance={insurance}
                    onSuccess={(insurance: Insurance) => {
                      setInsurance(insurance);
                      props.updateInsurance(insurance);
                    }}
                    onCancel={() => {
                    }}
                  />
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
        <Grid container className={"content"}>
          <Grid item xs={12}>
            <Paper className={"paper-content"}>
              <Grid container>
                <Grid item xs={12}>
                  <div className={"padding-2"}>
                    <Card variant={"outlined"} className={classes.card}>
                      <CardHeader
                        title={"Załączniki"}
                        subheader={""}
                      />
                      <CardContent>
                        <List>
                        {
                          insurance.attachments.map((attachment: InsuranceAttachment) => {
                            return (
                              <ListItem key={attachment.path} classes={{container: classes.attachment}}>
                                <ListItemText primary={attachment.name}/>
                                <ListItemSecondaryAction>
                                  <IconButton onClick={() => downloadAttachment(attachment)}>
                                    <AttachmentIcon />
                                  </IconButton>
                                  <IconButton onClick={() => {deleteAttachment(attachment.id)}}>
                                    <DeleteIcon />
                                  </IconButton>
                                </ListItemSecondaryAction>
                              </ListItem>
                            )
                          })
                        }
                        </List>
                      </CardContent>
                    </Card>
                  </div>
                </Grid>
                {
                  uploadList.length > 0 ? (
                    <div className={"padding-2"}>
                      <Grid item xs={12}>
                        <Card variant={"outlined"}>
                          <CardHeader
                            title={"Dodaj nowe załączniki"}
                          />
                          <CardContent>
                            <List>
                            {
                              uploadList.map((item, index) => {
                                return (
                                  <ListItem key={index} classes={{container: classes.attachment}}>
                                    <ListItemText primary={
                                      <div style={{marginRight: 24}}>
                                        <TextField
                                          fullWidth
                                          disabled={uploading}
                                          label={"Nazwa"}
                                          variant={"outlined"}
                                          onChange={(e: any) => updateUploadItemName(e.target.value, index)}
                                          value={item.name}/>
                                      </div>
                                    }/>
                                    <ListItemText
                                      primary={item.attachment.name}
                                      secondary={`Rozmiar: ${item.attachment.size / 1000} KB`}
                                      className={classes.attachmentFilename}
                                    />
                                    <ListItemSecondaryAction>
                                      <IconButton disabled={uploading} onClick={() => removeFileFromUpload(index)}>
                                        <RemoveCircleIcon />
                                      </IconButton>
                                    </ListItemSecondaryAction>
                                  </ListItem>
                                )
                              })
                            }
                            </List>
                          </CardContent>
                          <CardActions className={classes.cardActions}>
                            <Button
                              variant={"contained"}
                              color={"primary"}
                              onClick={uploadAttachments}
                              disabled={uploading}
                              style={{float: "right", marginLeft: "auto"}}
                            >
                              { uploading ? <CircularProgress style={{width: 20, height: 20, marginRight: 16}}/> : null }
                              Zapisz wszystkie
                            </Button>
                          </CardActions>
                        </Card>
                      </Grid>
                    </div>
                  ) : null
                }

              </Grid>
              <Grid item xs={12}>
                <div className={"padding-2"}>
                  <div className={classes.dropZoneContainer}>
                    {
                      !uploading ? (
                        <Dropzone onDrop={addFilesToUpload} disabled={uploading}>
                        {({getRootProps, getInputProps}) => (
                          <section>
                            <div {...getRootProps()} className={classes.dropZone}>
                              <input {...getInputProps()}/>
                              <Typography component={"p"} color={"textSecondary"} variant={"body2"}>
                                Przeciągnij tu pliki lub kliknij aby dodać nowe.
                              </Typography>
                            </div>
                          </section>
                        )}
                        </Dropzone>
                      ) : null
                    }
                  </div>
                </div>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </>
    )
  }
};


const mapStateToProps = (state: AppState) => ({
  session: state.auth.session,
});


const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>) => ({
  updateInsurance: (payload: Insurance) => dispatch(updateInsurance(payload)),
});


export default connect(mapStateToProps, mapDispatchToProps)(withRouter(InsuranceDetailView))
