import { Todo } from "../../models";
import {
  DASHBOARD_TODOS_FETCHED,
  TODO_CREATED,
  TODO_DELETED,
  TODO_UPDATED,
  TODOS_FETCHED
} from "./types";

export const fetchTodos = (payload: {todos: Todo[], count: number}) => {
  return {
    type: TODOS_FETCHED,
    payload: payload
  }
};


export const fetchDashboardTodos = (payload: Todo[]) => {
  return {
    type: DASHBOARD_TODOS_FETCHED,
    payload: payload
  }
};


export const createTodo = (payload: Todo) => {
  return {
    type: TODO_CREATED,
    payload: payload
  }
};


export const updateTodo = (payload: Todo) => {
  return {
    type: TODO_UPDATED,
    payload: payload
  }
};


export const deleteTodo = (payload: string) => {
  return {
    type: TODO_DELETED,
    payload: payload
  }
};
