import { APIClient, IPayload } from "../../api/APIClient";
import { CompanyMember } from "../../models/CompanyMember";
import { notify } from "../../utils";


export class MembersService {
  companyId: string;

  constructor(companyId: string) {
    this.companyId = companyId;
  }

  fetch = () => {
    const url = `/${this.companyId}/members/`;
    return APIClient.get(url, {limit: 1000}).then((response) => {
      return response.data.results.map((item: any) => {
        return new CompanyMember(item);
      })
    })
  };

  update = (id: string, payload: IPayload) => {
    const url = `/${this.companyId}/members/${id}/`;
    return APIClient.patch(url, payload).then((response) => {
      return new CompanyMember(response.data);
    })
  };

  delete = (id: string) => {
    const url = `/${this.companyId}/members/${id}/`;
    return APIClient.delete(url).then((response) => {
      return response.data;
    }).catch((error) => {
      notify(`${error}`, 'error');
      throw error.response;
    });
  };

  invite = (email: string, role: string) => {
    const url = `/${this.companyId}/invite/`;
    return APIClient.post(url, {email: email, role: role}).then((response) => {
      return response;
    }).catch((error) => {
      notify(`${error}`, 'error');
      throw error.response;
    });
  }
}