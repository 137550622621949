import React from "react";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { CompanyMember } from "../../models/CompanyMember";
import { AppState } from "../../store";
import { fetchMembers } from "../../store/Accounts/actions";
import { MembersService } from "../../store/Accounts/services";
import { CompanyMembers } from "../../store/Accounts/types";
import { Session } from "../../store/Auth/types";
import { MemberForm } from "./index";


type MemberListProps = {
  session: Session | undefined,
  members: CompanyMembers | undefined,
  fetchMembers: (payload: CompanyMember[]) => void,
}


const MemberList: React.FC<MemberListProps> = (props: MemberListProps) => {

  const service = props.session ? new MembersService(props.session.company.id) : undefined;
  const [loading, setLoading] = React.useState(false);

  const getMembers = () => {
    if (service && !loading) {
      setLoading(true);
      service.fetch().then((results: CompanyMember[]) => {
        props.fetchMembers(results);
        setLoading(false)
      }).catch(() => setLoading(false))
    }
  };

  React.useState(() => {
    if (props.members === undefined) {
      getMembers();
    }
  });

  return (
    <>
      {
        props.members ? Object.values(props.members).map((member: CompanyMember) => <MemberForm instance={member} key={member.id} /> ) : null
      }
      <MemberForm />
    </>
  )
};


const mapStateToProps = (state: AppState) => ({
  session: state.auth.session,
  members: state.memberList.members
});


const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>) => ({
  fetchMembers: (payload: CompanyMember[]) => dispatch(fetchMembers(payload)),
});


export default connect(mapStateToProps, mapDispatchToProps)(MemberList);
