import { CompanyMember } from "./CompanyMember";
import moment, { Moment } from "moment";
import { Client, Insurance } from "./index";

class Todo {
  id: string;
  title: string;
  content: string;
  assignee: CompanyMember | null;
  status: string;
  due_date: Moment | null;
  finished: Moment | null;
  related_obj: {
    related_to: "client" | "insurance" | "",
    object: Insurance | Client | null
  };

  constructor(input: any) {
    this.id = input.id;
    this.title = input.title;
    this.content = input.content;
    this.assignee = input.assignee ? new CompanyMember(input.assignee) : null;
    this.status = input.status;
    this.due_date = moment(input.due_date);
    this.finished = input.finished ? moment(input.finished) : null;
    if (input.related_obj.related_to === "client") {
      this.related_obj = {related_to: input.related_obj.related_to, object: new Client(input.related_obj.object)};
    }
    else if (input.related_obj.related_to === "insurance") {
      this.related_obj = {related_to: input.related_obj.related_to, object: new Insurance(input.related_obj.object)};
    }
    else {
      this.related_obj = {related_to: "", object: null}
    }
  }

}

export default Todo;