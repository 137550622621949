import moment from "moment";
import React from 'react';
import './App.scss';
import { ToastContainer } from "react-toastify";
import { Redirect, Route } from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';
import SignIn from "./views/SignIn";
import Dashboard from "./views/Dashboard";
import { ConnectedRouter } from "connected-react-router";
import { history } from "./store";
import "moment/locale/pl";
import { ThemeProvider } from "@material-ui/core";
import { ipcrmTheme } from "./theme";
import { MemberSignUp } from "./views/SignUp";
moment.locale("pl");

function App() {
  return (
    <ThemeProvider theme={ipcrmTheme}>
    <div className="App">
      <ConnectedRouter history={history}>
        <Route exact path={"/auth/sign-in/"} component={SignIn} />
        <Route exact path={"/auth/sign-up/:token/"} component={MemberSignUp} />
        <Route exact path={"/"}><Redirect to={"/dashboard/"} /></Route>
        <Route path={"/dashboard"} component={Dashboard}/>
      </ConnectedRouter>
      <ToastContainer position={"bottom-left"}/>
    </div>
    </ThemeProvider>
  );
}

export default App;
