import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import { AppState, history } from "../../store";
import { connect } from "react-redux";
import {
  FormControl,
  InputLabel, MenuItem,
  Select,
} from "@material-ui/core";
import { IFilter } from "../../interfaces/Filters";
import { useDebouncedCallback } from "use-debounce";
import { getQueryString } from "./utils";


type SelectFilterProps = {
  location: any,
  options: {label: string, value: any}[],
} & IFilter


const useStyles = makeStyles(theme => ({
  label: {
    backgroundColor: "#f2f2f2",
    transform: "translate(14px, 20px) scale(1)"
  },
  labelShrinked: {
    transform: "translate(9px, -6px) scale(0.75)",
    padding: "0 5px",
    zIndex: 1000,
  }
}));

const SelectFilter: React.FC<SelectFilterProps> = (props: SelectFilterProps) => {
  const classes = useStyles();
  const [value, setValue] = React.useState<string>(props.value || "");

  const handleChange = useDebouncedCallback(
    (value: string) => {
      const search = getQueryString(value, props.queryParam, props.location);
      history.push({
        pathname: props.location.path,
        search: search
      });
      props.onChange({
        id: props.id,
        queryParam: props.queryParam,
        filterParam: props.filterParam,
        value: value
      });
    }, 500
  );

  return (
    <FormControl fullWidth>
      <InputLabel
        id={`${props.id}-label`}
        classes={{
          root: classes.label,
          shrink: classes.labelShrinked
        }}
        >{props.label}</InputLabel>
      <Select
        variant={"outlined"}
        labelId={`${props.id}-label`}
        id={props.id}
        value={value}
        onChange={(e: any) => {
          handleChange.callback(e.target.value);
          setValue(e.target.value)
        }}
      >
        <MenuItem value={""}><em>Wszystkie opcje</em></MenuItem>
        { props.options.map(o => {
          return <MenuItem key={o.value} value={o.value}>{o.label}</MenuItem>
        })}
      </Select>
    </FormControl>
  )
};


const mapStateToProps = (state: AppState) => ({
  location: state.router.location
});


export default connect(mapStateToProps)(SelectFilter);
