import { FormLabel, Grid } from "@material-ui/core";
import React from "react";


type DetailProps = {
  label: string,
  value: any
}


export const Detail: React.FC<DetailProps> = (props: DetailProps) => {
  return (
    <Grid container spacing={1} style={{margin: 16, width: "100%"}}>
      <Grid item xs={12}>
        <FormLabel>{props.label}</FormLabel>
      </Grid>
      <Grid item xs={12}>
        {props.value}
      </Grid>
    </Grid>
  )
};