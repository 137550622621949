import { Link } from "@material-ui/core";
import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { Client, Insurance, Todo } from "../../models";
import { Detail } from "../Detail";


type TodoDetailProps = {
  todo: Todo
}


const TodoDetail: React.FC<TodoDetailProps> = (props: TodoDetailProps) => {

  const relatedObjectDetail = (relatedObj: Client | Insurance) => {
    return (
      relatedObj instanceof Client ? (
        <Detail label={"Klient"} value={
          <Link component={RouterLink}
                to={`/dashboard/clients/client/${relatedObj.id}/`}>
            {relatedObj.full_name}
          </Link>
        }/>
      ) : (
        <Detail label={"Polisa"} value={
          <Link component={RouterLink} to={`/dashboard/insurances/insurance/${relatedObj.id}/`}>
            {relatedObj.number} ({relatedObj.insurer})
          </Link>
        }/>
      )
    )
  };

  return (
    <div>
      <Detail label={"Tytuł"} value={props.todo.title}/>
      <Detail label={"Opis"} value={props.todo.content}/>
      <Detail label={"Osoba odpowiedzialna"} value={props.todo.assignee?.getName()}/>
      <Detail label={"Termin"} value={props.todo.due_date?.format("YYYY-MM-DD")}/>
      {
        props.todo.related_obj?.object ? (
          relatedObjectDetail(props.todo.related_obj?.object)
        ) : null
      }
    </div>
  )
};


export default TodoDetail;