import React from "react";
import { AppState, history } from "../../store";
import { connect } from "react-redux";
import { TextField } from "@material-ui/core";
import { IFilter } from "../../interfaces/Filters";
import { useDebouncedCallback } from "use-debounce";
import { getQueryString } from "./utils";


type TextFilterProps = {
  location: any
  inputProps?: any,
} & IFilter


const TextFilter: React.FC<TextFilterProps> = (props: TextFilterProps) => {
  const {inputProps} = props;
  const [value, setValue] = React.useState<string>(props.value);

  const handleChange = useDebouncedCallback(
    (value: string) => {
      const search = getQueryString(value, props.queryParam, props.location);
      history.push({
        pathname: props.location.path,
        search: search
      });
      props.onChange({
        id: props.id,
        queryParam: props.queryParam,
        filterParam: props.filterParam,
        value: value
      });
    }, 1000
  );

  return (
    <TextField
      fullWidth
      key={props.id}
      variant={"outlined"}
      label={props.label}
      name={value}
      value={value}
      onChange={(e: any) => {
        handleChange.callback(e.target.value);
        setValue(e.target.value)
      }}
      InputProps={{...inputProps}}
    />
  )
};


const mapStateToProps = (state: AppState) => ({
  location: state.router.location
});


export default connect(mapStateToProps)(TextFilter);
