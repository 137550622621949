import React from "react";
import { Redirect, Route, RouteProps } from "react-router";
import { JWTPayload, Session } from "../../store/Auth/types";
import { AppState } from "../../store";
import { connect } from "react-redux";
import { deserializeStorage, notify } from "../../utils";
import { AuthService } from "../../store/Auth/services";
import { ThunkDispatch } from "redux-thunk";
import { login, logout } from "../../store/Auth/actions";
import { Skeleton } from "@material-ui/lab";


interface  IPrivateRouteProps extends RouteProps {
  session: Session | undefined,
  refreshToken: (payload: Session) => void,
  logout: () => void,
}


const PrivateRoute: React.FC<IPrivateRouteProps> = (props: IPrivateRouteProps) => {
  const { component, session, location, ...rest } = props;
  const Component: React.ComponentType = component as React.ComponentType;
  const [currentSession, setCurrentSession] = React.useState<Session | undefined>(session || deserializeStorage("SESSION"));
  const [sessionValid, setSessionValid] = React.useState<boolean | undefined>(undefined);

  React.useEffect(() => {
    if (currentSession) {
      if (sessionValid === undefined) {
        AuthService.refreshToken(currentSession.token).then((data: JWTPayload) => {
          setSessionValid(true);
          props.refreshToken({
            ...currentSession,
            token: data.token,
            user: data.user
          })
        }).catch(() => {
          props.logout();
          setSessionValid(false);
          setCurrentSession(undefined);
          notify("Sesja wygasła, prosimy o ponowne zalogowanie.", "error");
        });
      }
    }
    else {
      setSessionValid(false)
    }
  }, [session, currentSession, sessionValid, props]);

  return sessionValid === undefined ? (
    <>
      <Skeleton variant={"rect"} width={"100%"} height={"80px"} style={{marginBottom: 32}} />
      <Skeleton variant={"rect"} width={"100%"} height={"70vh"} />
    </>
  ) : (
    <Route {...rest} render={(props: any) => (
      currentSession !== undefined
        ? <Component {...props} />
        : <Redirect to={{
          pathname: "/auth/sign-in/",
          state: {next: location}
        }} />
    )} />
  )
};


const mapStateToProps = (state: AppState) => ({
  session: state.auth.session
});


const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>) => ({
  logout: () => dispatch(logout()),
  refreshToken: (payload: Session) => dispatch(login(payload))
});


export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoute);