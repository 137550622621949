export default class InsuranceAttachment {
  id: string;
  name: string;
  path: string; // file path
  file: string; // file url

  constructor(input: {[key: string]: any}) {
    this.id = input.id;
    this.name = input.name;
    this.path = input.path;
    this.file = input.file;
  }
}