import { makeStyles } from "@material-ui/core/styles";
import AttachmentIcon from "@material-ui/icons/AttachmentOutlined";
import DeleteIcon from "@material-ui/icons/DeleteOutlined";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircleOutlineOutlined";
import React from "react";
import Dropzone from "react-dropzone";
import { RouteComponentProps, withRouter } from "react-router";
import { Session } from "../../store/Auth/types";
import { Client, ClientDocument } from "../../models";
import {
  Breadcrumbs, Button,
  Card, CardActions, CardContent, CardHeader, CircularProgress,
  Divider,
  Grid, IconButton,
  Link, List, ListItem, ListItemSecondaryAction, ListItemText,
  Paper, TextField,
  Typography
} from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import ClientForm from "../../components/Clients/ClientForm";
import { AppState } from "../../store";
import { ThunkDispatch } from "redux-thunk";
import { connect } from "react-redux";
import { ClientService } from "../../store/Clients/services";
import { updateClient } from "../../store/Clients/actions";
import { notify } from "../../utils";


interface MatchParams {
  id: string
}


interface ClientDetailViewProps extends RouteComponentProps<MatchParams> {
  session: Session | undefined,
  client: Client | undefined | null,
  updateClient: (client: Client) => void
}


const useStyles = makeStyles(theme => ({
  "@keyframes striped": {
    "0%": {
      backgroundPosition: '0 0',
    },
    "100%": {
      backgroundPosition: '60px 30px',
    }
  },
  dropZoneContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  dropZone: {
    padding: theme.spacing(4),
    borderStyle: 'dashed',
    borderWidth: 1,
    borderColor: theme.palette.divider,
    textAlign: 'center',
    '&:hover': {
      backgroundSize: '30px 30px',
      backgroundImage: `linear-gradient(-45deg,#eff1f5 25%,transparent 0,transparent 50%,#eff1f5 0,#eff1f5 75%,transparent 0,transparent)`,
      animation: `$striped 2000ms linear infinite`,
    }
  },
  file: {
    padding: theme.spacing(1),
    marginBottom: theme.spacing(1),
    "&:nth-child(odd)": {
      backgroundColor: "#fafafa !important",
      borderRadius: theme.spacing(0.5),
    }
  },
  card: {
    marginBottom: theme.spacing(3)
  },
  cardActions: {
    padding: theme.spacing(2)
  },
  attachmentFilename: {
    maxWidth: 200,
  }
}));


const ClientDetailView: React.FC<ClientDetailViewProps> = (props: ClientDetailViewProps) => {

  const clientId = props.match.params.id;
  const classes = useStyles();
  const service = props.session ? new ClientService(props.session.company.id) : undefined;
  const [formDisabled, setFormDisabled] = React.useState(true);
  const [client, setClient] = React.useState<Client | undefined | null>(undefined);
  const [uploadList, setUploadList] = React.useState<{name: string, file: File}[]>([]);
  const [uploading, setUploading] = React.useState(false);

  React.useEffect(() => {
    if (client === undefined && service !== undefined) {
      service.get(clientId).then(response => {
        setClient(new Client(response));
      }).catch(response => {
        setClient(null)
      });
    }
  });

  const updateUploadItemName = (val: any, index: number) => {
    const items = [...uploadList];
    const item = items[index];
    item.name = val;
    items[index] = item;
    setUploadList(items);
  };

  const addFilesToUpload = (files: File[]) => {
    setUploadList([...uploadList, ...files.map(f => {
      return {name: f.name, file: f}
    })]);
  };

  const removeFileFromUpload = (index: number) => {
    const newList = [...uploadList];
    newList.splice(index, 1);
    setUploadList(newList);
  };

  const downloadDocument = (document: ClientDocument) => {
    return service?.getDocumentUrl(document.path, document.file).then(url => {
      window.open(url, "_blank")
    }).catch(() => {
      notify("Wystąpił błąd podczas pobierania pliku.", "error");
    });
  };

  const uploadDocuments = () => {
    const upload = (documentList: {name: string, file: File}[]) => {
      if (service !== undefined && documentList.length > 0) {
        const [payload, ...rest] = documentList;
        service.uploadDocument(clientId, payload).then(response => {
          if (client) {
            const document = new ClientDocument(response);
            setClient({...client, documents: [...client.documents, document]});
            client.documents.push(document);
            setUploadList([...rest]);
          }
          upload(rest);
        }).catch(error => {
          setUploadList([...rest, payload]);
          setUploading(false);
        });
      }
      else {
        setUploading(false);
      }
    };
    setUploading(true);
    upload(uploadList);
  };

  const deleteDocument = (id: string) => {
    if (service !== undefined) {
      service.deleteDocument(clientId, id).then(() => {
        if (client) {
          setClient({...client, documents: client.documents.filter((document) => {
              return document.id !== id
            })
          });
        }
      })
    }
  };

  if (client === null) {
    return <>Nie znaleziono klienta</>;
  }
  else if (client === undefined) {
    return <></>;
  }
  else {
    return (
      <>
        <div className={"header"}>
          <Grid container>
            <Grid item className={"header-content"}>
              <Breadcrumbs>
                <Link component={RouterLink} to={"/dashboard/"}>Pulpit</Link>
                <Link component={RouterLink}
                      to={"/dashboard/clients/"}>Klienci</Link>
                <Typography color={"textSecondary"}>
                  {client ? client.full_name : ""}
                </Typography>
              </Breadcrumbs>
            </Grid>
          </Grid>
          <Divider/>
        </div>
        <Grid container className={"content"}>
          <Grid item xs={12}>
            <Paper className={"paper-content"}>
              <Grid container>
                <Grid item xs={12}>
                  <ClientForm
                    disabled={formDisabled}
                    instance={client}
                    onSuccess={(client: Client) => {
                      setFormDisabled(true);
                      setClient(client);
                      props.updateClient(client);
                    }}
                    onCancel={() => {
                      setFormDisabled(true);
                    }}
                  />
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
        <Grid container className={"content"}>
          <Grid item xs={12}>
            <Paper className={"paper-content"}>
              <Grid container>
                <Grid item xs={12}>
                  <div className={"padding-2"}>
                    <Card variant={"outlined"} className={classes.card}>
                      <CardHeader
                        title={"Dokumenty"}
                        subheader={""}
                      />
                      <CardContent>
                        <List>
                          {
                            client.documents.map((document: ClientDocument) => {
                              return (
                                <ListItem key={document.path}
                                          classes={{container: classes.file}}>
                                  <ListItemText primary={document.name}/>
                                  <ListItemSecondaryAction>
                                    <IconButton
                                      onClick={() => downloadDocument(document)}>
                                      <AttachmentIcon/>
                                    </IconButton>
                                    <IconButton onClick={() => {
                                      deleteDocument(document.id)
                                    }}>
                                      <DeleteIcon/>
                                    </IconButton>
                                  </ListItemSecondaryAction>
                                </ListItem>
                              )
                            })
                          }
                        </List>
                      </CardContent>
                    </Card>
                  </div>
                </Grid>
                {
                  uploadList.length > 0 ? (
                    <div className={"padding-2"}>
                      <Grid item xs={12}>
                        <Card variant={"outlined"}>
                          <CardHeader
                            title={"Dodaj nowe dokumenty"}
                          />
                          <CardContent>
                            <List>
                              {
                                uploadList.map((item, index) => {
                                  return (
                                    <ListItem key={index}
                                              classes={{container: classes.file}}>
                                      <ListItemText primary={
                                        <div style={{marginRight: 24}}>
                                          <TextField
                                            fullWidth
                                            disabled={uploading}
                                            label={"Nazwa"}
                                            variant={"outlined"}
                                            onChange={(e: any) => updateUploadItemName(e.target.value, index)}
                                            value={item.name}/>
                                        </div>
                                      }/>
                                      <ListItemText
                                        primary={item.file.name}
                                        secondary={`Rozmiar: ${item.file.size / 1000} KB`}
                                        className={classes.attachmentFilename}
                                      />
                                      <ListItemSecondaryAction>
                                        <IconButton disabled={uploading}
                                                    onClick={() => removeFileFromUpload(index)}>
                                          <RemoveCircleIcon/>
                                        </IconButton>
                                      </ListItemSecondaryAction>
                                    </ListItem>
                                  )
                                })
                              }
                            </List>
                          </CardContent>
                          <CardActions className={classes.cardActions}>
                            <Button
                              variant={"contained"}
                              color={"primary"}
                              onClick={uploadDocuments}
                              disabled={uploading}
                              style={{float: "right", marginLeft: "auto"}}
                            >
                              {uploading ? <CircularProgress style={{
                                width: 20,
                                height: 20,
                                marginRight: 16
                              }}/> : null}
                              Zapisz wszystkie
                            </Button>
                          </CardActions>
                        </Card>
                      </Grid>
                    </div>
                  ) : null
                }

              </Grid>
              <Grid item xs={12}>
                <div className={"padding-2"}>
                  <div className={classes.dropZoneContainer}>
                    {
                      !uploading ? (
                        <Dropzone onDrop={addFilesToUpload}
                                  disabled={uploading}>
                          {({getRootProps, getInputProps}) => (
                            <section>
                              <div {...getRootProps()}
                                   className={classes.dropZone}>
                                <input {...getInputProps()}/>
                                <Typography component={"p"}
                                            color={"textSecondary"}
                                            variant={"body2"}>
                                  Przeciągnij tu pliki lub kliknij aby dodać
                                  nowe.
                                </Typography>
                              </div>
                            </section>
                          )}
                        </Dropzone>
                      ) : null
                    }
                  </div>
                </div>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </>
    )
  }
};


const mapStateToProps = (state: AppState) => ({
  session: state.auth.session,
});


const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>) => ({
  updateClient: (payload: Client) => dispatch(updateClient(payload)),
});


export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ClientDetailView))
