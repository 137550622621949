import {
  Dialog, DialogContent, DialogTitle,
  Grid, IconButton, Tooltip,
  Typography
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { Todo } from "../../models";
import {
  DragDropContext,
  Droppable,
  Draggable,
  DroppableProvided,
  DroppableStateSnapshot,
  DraggableProvided,
  DraggableStateSnapshot
} from 'react-beautiful-dnd';
import { AppState } from "../../store";
import { Session } from "../../store/Auth/types";
import {
  deleteTodo,
  fetchDashboardTodos,
  updateTodo
} from "../../store/Todos/actions";
import { TodoSerivce } from "../../store/Todos/services";
import { Todos } from "../../store/Todos/types";
import TodoDraggableCard from "./TodoDraggableCard";
import EditIcon from "@material-ui/icons/EditOutlined";
import TodoForm from "./TodoForm";


type TodoDashboardProps = {
  session: Session | undefined,
  todos: Todos | undefined,
  fetchTodos: (payload: Todo[]) => void,
  updateTodo: (payload: Todo) => void,
  deleteTodo: (payload: string) => void,
};


const useStyles = makeStyles(theme => ({
  root: {},
  statusHeader: {
    marginBottom: theme.spacing(2)
  },
  droppableContainer: {
    padding: theme.spacing(2),
    minHeight: theme.spacing(10),
  },
}));


const TodoDashboard: React.FC<TodoDashboardProps> = (props: TodoDashboardProps) => {
  const classes = useStyles();
  const service = props.session ? new TodoSerivce(props.session.company.id) : undefined;
  const [loading, setLoading] = React.useState<boolean>(false);
  const [editing, setEditing] = React.useState<Todo | undefined>(undefined);

  const updateStatus = (todo: Todo, newStatus: string, previousStatus: string) => {
    if (service) {
      service.update(todo.id, {status: newStatus}).then((response) => {
        props.updateTodo(new Todo(response));
      }).catch((exception) => {
        props.updateTodo(todo);
      })
    }
  };

  const onDragEnd = (result: any) => {
    const { draggableId, source, destination } = result;

    if (!destination) {
      return;
    }

    if (source.droppableId === destination.droppableId) {
      return;
    }

    const draggedTodo: Todo | undefined = props.todos ? props.todos[draggableId] : undefined;
    if (draggedTodo) {
      draggedTodo.status = destination.droppableId;
      props.updateTodo(draggedTodo);
      updateStatus(draggedTodo, destination.droppableId, source.droppableId);
    }
  };

  const getListStyle = (isDraggingOver: boolean): {} => ({
    background: isDraggingOver ? 'lightblue' : "#f2f2f2",
    padding: "grid",
    minHeight: 200
  });

  const getTodos = () => {
    if (service && !loading) {
      setLoading(true);
      service.fetch({
        assignee__user__email: props.session?.user.email,
        current_week: true
      }).then(response => {
        props.fetchTodos(response.todos);
        setLoading(false);
      }).catch(() => {
        setLoading(false);
      })
    }
  };

  React.useEffect(() => {
    if (props.todos === undefined) {
      getTodos();
    }
  });

  const editDialog = (): React.ReactNode => {
    return (
      <Dialog open={editing !== undefined} onClose={() => setEditing(undefined)} disableBackdropClick>
        <DialogTitle>Edytuj zadanie: {editing?.title}</DialogTitle>
        <DialogContent dividers>
          <TodoForm
            instance={editing}
            onSuccess={(todo: Todo) => {
              props.updateTodo(todo);
              setEditing(undefined)
            }}
            onCancel={() => setEditing(undefined)}
          />
        </DialogContent>
      </Dialog>
    )
  };

  return (
    <>
      <DragDropContext onDragEnd={onDragEnd}>
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <Droppable droppableId={"new"}>
              {(provided: DroppableProvided, snapshot: DroppableStateSnapshot) => (
                <div
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  className={classes.droppableContainer}
                  style={getListStyle(snapshot.isDraggingOver)}
                >
                  <Typography variant={"h5"} className={classes.statusHeader}>Nowe</Typography>
                  {
                    props.todos ? Object.values(props.todos).filter(t => t.status === "new").map((todo: Todo, index: number) => (
                      <Draggable key={todo.id} draggableId={todo.id.toString()} index={index}>
                        {(providedDraggable: DraggableProvided, snapshotDraggable: DraggableStateSnapshot) => (
                          <div>
                            <TodoDraggableCard
                              todo={todo}
                              providedDraggable={providedDraggable}
                              snapshotDraggable={snapshotDraggable}
                              actionsComponent={
                                <>
                                  <Tooltip title={"Edytuj"}>
                                    <IconButton onClick={() => setEditing(todo)}>
                                      <EditIcon />
                                    </IconButton>
                                  </Tooltip>
                                </>
                              }
                            />
                          </div>
                        )}
                      </Draggable>
                    )) : null
                  }
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </Grid>
          <Grid item xs={4}>
            <Droppable droppableId={"in_progress"}>
              {(provided: DroppableProvided, snapshot: DroppableStateSnapshot) => (
                <div
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  className={classes.droppableContainer}
                  style={getListStyle(snapshot.isDraggingOver)}
                >
                  <Typography variant={"h5"} className={classes.statusHeader}>W toku</Typography>
                  {
                    props.todos ? Object.values(props.todos).filter(t => t.status === "in_progress").map((todo: Todo, index: number) => (
                      <Draggable key={todo.id} draggableId={todo.id.toString()} index={index}>
                        {(providedDraggable: DraggableProvided, snapshotDraggable: DraggableStateSnapshot) => (
                          <div>
                            <TodoDraggableCard
                              key={todo.id}
                              todo={todo}
                              providedDraggable={providedDraggable}
                              snapshotDraggable={snapshotDraggable}
                              actionsComponent={
                                <>
                                  <Tooltip title={"Edytuj"}>
                                    <IconButton onClick={() => setEditing(todo)}>
                                      <EditIcon />
                                    </IconButton>
                                  </Tooltip>
                                </>
                              }
                            />
                          </div>
                        )}
                      </Draggable>
                    )) : null
                  }
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </Grid>
          <Grid item xs={4}>
            <Droppable droppableId={"finished"}>
              {(provided: DroppableProvided, snapshot: DroppableStateSnapshot) => (
                <div
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  className={classes.droppableContainer}
                  style={getListStyle(snapshot.isDraggingOver)}
                >
                  <Typography variant={"h5"} className={classes.statusHeader}>Zakończone</Typography>
                  {
                    props.todos ? Object.values(props.todos).filter(t => t.status === "finished").map((todo: Todo, index: number) => (
                      <Draggable key={todo.id} draggableId={todo.id.toString()} index={index}>
                        {(providedDraggable: DraggableProvided, snapshotDraggable: DraggableStateSnapshot) => (
                          <div>
                            <TodoDraggableCard
                              todo={todo}
                              providedDraggable={providedDraggable}
                              snapshotDraggable={snapshotDraggable}
                              actionsComponent={
                                <>
                                  <Tooltip title={"Edytuj"}>
                                    <IconButton onClick={() => setEditing(todo)}>
                                      <EditIcon />
                                    </IconButton>
                                  </Tooltip>
                                </>
                              }
                            />
                          </div>
                        )}
                      </Draggable>
                    )) : null
                  }
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </Grid>
        </Grid>
      </DragDropContext>
      {editDialog()}
    </>
  )
};


const mapStateToProps = (state: AppState) => ({
  session: state.auth.session,
  todos: state.todoDashboard.todos,
});


const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>) => ({
  fetchTodos: (payload: Todo[]) => dispatch(fetchDashboardTodos(payload)),
  updateTodo: (payload: Todo) => dispatch(updateTodo(payload)),
  deleteTodo: (payload: string) => dispatch(deleteTodo(payload)),
});


export default connect(mapStateToProps, mapDispatchToProps)(TodoDashboard);