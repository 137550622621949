import { Insurance } from "../../models";
import {
  INSURANCE_CREATED,
  INSURANCE_DELETED,
  INSURANCE_UPDATED,
  INSURANCES_FETCHED
} from "./types";


export const fetchInsurances = (payload: {insurances: Insurance[], count: number}) => {
  return {
    type: INSURANCES_FETCHED,
    payload: payload
  }
};


export const createInsurance = (payload: Insurance) => {
  return {
    type: INSURANCE_CREATED,
    payload: payload
  }
};


export const updateInsurance = (payload: Insurance) => {
  return {
    type: INSURANCE_UPDATED,
    payload: payload
  }
};


export const deleteInsurance = (payload: string) => {
  return {
    type: INSURANCE_DELETED,
    payload: payload
  }
};
