import moment, { Moment } from "moment";

export default class InsurancePremium {
  id: string;
  amount: string;
  due_date: Moment | null| undefined;
  send_notification: boolean;

  constructor(input: {[key: string]: any}) {
    this.id = input.id;
    this.amount = input.amount;
    this.due_date = input.due_date ? moment(input.due_date) : null;
    this.send_notification = input.send_notification;
  }

}