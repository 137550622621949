import { APIClient, IParams, IPayload } from "../../api/APIClient";
import { notify } from "../../utils";
import { CompanyMember } from "../../models/CompanyMember";
import Todo from "../../models/Todo";


export class TodoSerivce {
  companyId: string;

  constructor(companyId: string) {
    this.companyId = companyId;
  }

  fetch = (params: IParams) => {
    const url = `/${this.companyId}/todos/`;
    return APIClient.get(url, params).then((response) => {
      const todos = response.data.results.map((item: any) => {
        return new Todo(item)
      });
      return {todos: todos, count: response.data.count};
    }).catch((error) => {
      notify(`${error}`, 'error');
      throw error.response;
    })
  };

  create = (payload: IPayload) => {
    const url = `/${this.companyId}/todos/`;
    return APIClient.post(url, payload).then((response) => {
      notify("Pomyślnie dodano zadanie.", "success");
      return response.data;
    }).catch((error) => {
      notify(`${error}`, 'error');
      throw error.response;
    })
  };

  update = (id: string, payload: IPayload) => {
    const url = `/${this.companyId}/todos/${id}/`;
    return APIClient.patch(url, payload).then((response) => {
      notify("Pomyślnie edytowano zadanie.", "success");
      return response.data;
    }).catch((error) => {
      notify(`${error}`, 'error');
      throw error.response;
    })
  };

  delete = (id: string) => {
    const url = `/${this.companyId}/todos/${id}/`;
    return APIClient.delete(url).then((response) => {
      notify("Pomyślnie usunięto zadanie.", "success");
      return response.data;
    }).catch((error) => {
      throw error.response;
    })
  };

  getAssigneeList = (params: IParams) => {
    const url = `/${this.companyId}/todos/assignee-list/`;
    return APIClient.get(url, params).then((response) => {
      return response.data.map((item: any) => {
        return new CompanyMember(item);
      })
    }).catch((error) => {
      notify(`${error}`, 'error');
      throw error.response;
    })
  }

}