import queryString from "query-string";
import { unescapeQuery } from "../../utils";

export const getQueryString = (value: any, queryParam: string, location: any) => {

  const query = unescapeQuery(location.query);
  if (Boolean(value)) {
    query[queryParam] = value;
  }
  else {
    delete query[queryParam];
  }
  return queryString.stringify(query);
};