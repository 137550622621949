export default class Company {
  id: string;
  name: string;

  constructor(input: {[key: string]: any}) {
    this.id = input.id;
    this.name = input.name;
  }

};
